import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightPOEGSH4804M600() {
  const Environment = [
    {
        title: "Medio ambiente:",
        detail: "Temperatura de funcionamiento: -10 °C a +50 °C",
    },
    {
        detail: "Temperatura de almacenamiento: -40 °C a +85 °C",
    },
    {
        detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
        detail: "Humedad de almacenamiento: 5% ~ 90%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Dimensiones del switch: 44 x 28 x 4,4 cm (L * W * H)",
    },
    {
      detail: "Dimensiones del paquete: 50 x 35 x 9 cm (L * W * H)",
    },
  ];
  const PackageWeight = [
    {
      title: "Peso del paquete:",
      detail: "N.W: 4.9kgs; G.W: 5.8KGS",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail: "1 x Switch, 1 x código de alimentación",
    },
    {
      detail: "1 x soporte de montaje 1 par (seleccionable)",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Cantidad: 5 PC / 1 cartón ",
    },
    {
      detail: "Dimensiones: 52 x 44,5 x 38,5 cm",
    },
    {
      detail: "Peso del embalaje: 24KGS",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "POE-GSH4804M-600",
    },
    {
      detail:
        "Nombre: Switch Gigabit PoE+ 802.3at gestionado L2, 600 W 48 puertos incluyendo 4 puertos SFP)",
    },
  ];
  return (
    <Container>
      <ul>
        {Environment.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageWeight.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
