import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightSNMPGSH802M() {
  const OtherFunctions = [
    {
        title: "Otras Funcionalidades:",
        detail: "Soporte de DHCP Snooping, Option 82, DHCP Server",
    },
    {
        detail: "Admite detección dinámica de ARP",
    },
    {
        detail: "Admite certificación DNS",
    },
    {
        detail: "Soporte Configuración de seguridad del puerto",
    },
    {
        detail: "Admite protocolo MVR",
    },
    {
        detail: "Admite la función VCT de detección de cables",
    },
    {
        detail: "Admite protocolo UDLD",
    },
  ];
  const Environment = [
    {
        title: "Medio ambiente:",
        detail: "Temperatura de funcionamiento: -10 °C a +50 °C",
    },
    {
        detail: "Temperatura de almacenamiento: -40 °C a +70 °C",
    },
    {
        detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
        detail: "Humedad de almacenamiento: 5% ~ 90%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Dimensiones del switch: 26.8 x 18.1 x 4.4 cm (L * W * H)",
    },
    {
      detail: "Dimensiones del paquete: 31.2 x 26 x 8.4 cm(L * W * H)",
    },
  ];
  const PackageWeight = [
    {
      title: "Peso del paquete:",
      detail: "N.W: 1.01KGs; G.W: 1.54KGs",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail: "1 x Switch, 1 x código de alimentación",
    },
    {
      detail: "1 x soporte de montaje 1 par (seleccionable)",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Cantidad: 10 PC / 1 cartón ",
    },
    {
      detail: "Dimensiones: 54 x 43.5 x 33.2 cm",
    },
    {
      detail: "Peso del embalaje: 16.4KGs",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "SNMP-GSH802M",
    },
    {
      detail:
        "Nombre: L2+ Managed Gigabit switch, 10 Ports including 2-port SFP Fiber",
    },
  ];
  return (
    <Container>
      <ul>
        {OtherFunctions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageWeight.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
