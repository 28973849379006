import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductConverterMC1GSFP.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftMC1GSFP } from "./SpecificationsLeftMC1GSFP/SpecificationsLeftMC1GSFP";
import { SpecificationsRightMC1GSFP } from "./SpecificationsRightMC1GSFP/SpecificationsRightMC1GSFP";

export function FeatureProductConverterMC1GSFP() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive MC-1GSFP" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftMC1GSFP />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightMC1GSFP />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}
