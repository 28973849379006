import { Container, Row, Col } from "react-bootstrap";
import styles from "../SupportPrincipal/SupportPrincipal.module.scss";
import support_principal from "../../../../../assets/images/support_principal.png";
import { HomeSubtitle } from "../../../../HomeSubtitle";


export function SupportPrincipal() {
  return (
    <Container fluid className={`${styles.banner} px-0`}>
      <Container className="py-4">
        <Row className="align-items-center">
          <Col xs={12} className="order-1 mb-4">
            <HomeSubtitle title="Soporte"/>
          </Col>
          <Col xs={12} md={6} className="order-3 order-md-2">
            <p className={styles.text}>
              <strong>AirLive</strong> posee una red de distribuidores en cada
              país, por medio de los cuales se canaliza el proceso de ventas,
              soporte y garantía a nuestros clientes finales.
            </p>
            <p className={styles.text}>
              Los acuerdos y contratos de servicio entre AirLive Technology Corp.
              y cada uno de nuestros aliados locales en cada país latinoamericano
              estipula que son éstos quienes prestarán el servicio de mercadeo y
              ventas, atención, soporte postventa y garantía de los productos
              comercializados al cliente final. A tal efecto, los distribuidores y
              canales de venta cuentan con personal técnico para prestar soporte
              técnico de pre y post venta al usuario final.{" "}
            </p>
            <h4 className="my-2">Escalabilidad de la atención y soporte</h4>
            <p className={styles.text}>
              Cada distribuidor y/o canal de ventas local puede canalizar y/o
              escalar los casos que estime conveniente al gerente de Desarrollo y
              ventas en la región, quien prestará el apoyo necesario y/o canaliza
              con el centro de soporte central de AirLive en Taiwán, o gerente de
              producto.
            </p>
          </Col>
          <Col xs={12} md={6} className="order-2 order-md-3 mb-3 mb-md-0">
            <img
              src={support_principal}
              className={styles.img_support}
              alt="support_principal"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
