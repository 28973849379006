import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SurveillanceVLANsupport from "../../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceVLANsupport.png";
import VLANPolicyPic from "../../../../../../../../assets/imagesproduct/SwitchL2/VLANPolicyPic.png";
import DHCPsupport from "../../../../../../../../assets/imagesproduct/SwitchL2/DHCPsupport.png";

export function SurveillanceVLANSupport() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte VLAN de vigilancia automática" />
          <p>
            El switch SNMP-GSH802M admite la exclusiva función de "división
            VLAN" para configurar un grupo VLAN importante con alta prioridad
            para garantizar el rendimiento y la confiabilidad de todo el
            sistema. Su función de división VLAN incluye VLAN de vigilancia,
            VLAN de voz, red MED (concesión de video) y VLAN de datos. Por
            ejemplo, cuando se habilitan funciones VLAN de vigilancia que
            garantizan la calidad del vídeo en tiempo real para la
            monitorización y el control sin comprometer la transmisión de datos
            de red convencionales.
          </p>
        </Col>
        <Col xs={10} className="mb-5">
          <img className="w-100" src={SurveillanceVLANsupport} alt="image" />
        </Col>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Política VLAN para videoconferencia/voz/datos" />
        </Col>
        <Col md={6} className="mb-5">
          <p>
            Una directiva VLAN para videoconferencia, voz y datos separa el
            tráfico de red para optimizar el rendimiento y la seguridad. Esto
            permite una gestión eficiente de diferentes tipos de tráfico de red,
            como videoconferencias, comunicaciones de voz y transferencias de
            datos, para garantizar operaciones de red fluidas y seguras.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img className="w-100" src={VLANPolicyPic} alt="img" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte de DHCP Snooping" />
          <p>
            DHCP snooping es una característica de seguridad de red que ayuda a
            prevenir ataques maliciosos mediante la supervisión y el filtrado de
            mensajes DHCP que no son de confianza. Verifica los paquetes DHCP
            recibidos en una red y solo permite procesar los de fuentes
            confiables, como un servidor DHCP, protegiéndolos contra servidores
            DHCP no autorizados que potencialmente pueden asignar direcciones IP
            incorrectas a los dispositivos de la red.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img className="w-100" src={DHCPsupport} alt="image" />
        </Col>
      </Row>
    </Container>
  );
}
