import { Container, Row, Col } from "react-bootstrap";
import styles from "./GPON.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import AplicationFTTX from "../../../../../../../../assets/imagesproduct/AirLiveGPON/AplicationFTTX.png";

export function GPON() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="GPON" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            GPON (Gigabit Passive Optical Network) es una tecnología PON, basada
            en el estándar ITU-T G.984.x, utiliza el protocolo GEM (GPON
            Encapsulation Method) para encapsular los datos en tramas y
            transmitirlos a través de fibra óptica.
          </p>
          &nbsp;
          <p>
            GPON tiene una capacidad de tráfico de hasta 2,5 Gbps en sentido
            descendente y 1,25 Gbps en sentido ascendente, lo que permite
            ofrecer servicios de voz, datos y video a los usuarios. GPON utiliza
            una topología punto-multipunto, donde una única fibra óptica puede
            atender hasta 128 usuarios mediante divisores ópticos pasivos.
          </p>
          &nbsp;
          <p>
            La arquitectura básica de una red GPON consta de tres elementos
            principales:
            <ul>
              <li>
                OLT (Optical Line Terminal): es el equipo que se encuentra en el
                punto central del proveedor de acceso y que se encarga de
                generar, enviar y recibir las señales ópticas hacia y desde los
                usuarios.
              </li>
              <li>
                ONT (Optical Network Terminal): es el equipo que se encuentra en
                el domicilio o local del usuario y que se encarga de convertir
                las señales ópticas en eléctricas y viceversa, así como de
                proporcionar las interfaces para los servicios contratados.
              </li>
              <li>
                ODN (Optical Distribution Network): es el conjunto de elementos
                pasivos que conforman la red de distribución óptica entre el OLT
                y los ONT, como fibras ópticas, divisores ópticos, conectores,
                empalmes, etc.
              </li>
            </ul>
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={AplicationFTTX}
            alt="Aplication FTTX"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="XGPON" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <p>
            Es una evolución de GPON que ofrece un ancho de banda de 10 Gbps en
            la dirección descendente y 2.5 Gbps en la dirección ascendente.
            Utiliza las longitudes de onda de 1577 nm y 1270 nm para el
            downstream y el upstream respectivamente. Es compatible con GPON y
            puede coexistir en la misma fibra mediante un filtro WDM.
          </p>
          &nbsp;
          <p>
            Las unidades GPON OLT y GPON ONU cumplen el estandar ITU-T
            G.987(XG-PON).
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="XGSPON" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <p>
            XGSPON (10-Gigabit-capable Symmetric Passive Optical Network) es
            otra evolución de GPON que ofrece un ancho de banda simétrico de 10
            Gbps en ambas direcciones. Utiliza las mismas longitudes de onda que
            XGPON para el downstream y el upstream. También es compatible con
            GPON y XGPON y puede coexistir en la misma fibra mediante un filtro
            WDM
          </p>
          &nbsp;
          <p>
            XGSPON utiliza una topología punto-multipunto, donde una única fibra
            óptica puede atender hasta 256 usuarios mediante divisores ópticos
            pasivos.
          </p>
          &nbsp;
          <p>
            La arquitectura básica de una red XGSPON tambien consta de los tres
            elementos principales: OLT, ONT y ODN (Optical Distribution
            Network), con la salvedad de que ademas del estandar ITU-T
            G.987(XG-PON), soportan el estandar ITU-T G.9807, asi como las
            mismas longitudes de onda de XGPON de TX 1270nm y RX 1577nm. El
            método de encapsulación es XGEM (XGSPON Encapsulation Method).
          </p>
        </Col>
      </Row>
    </Container>
  );
}
