import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { TechnicalspecificationsPOEGSH4804M600 } from "./TechnicalspecificationsPOEGSH4804M600";
import { SpecificationsPOEGSH4804M600 } from "./SpecificationsPOEGSH4804M600";

export function FeatureProductPOEGSH4804M600() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Technicalspecifications"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Technicalspecifications" title="Especificaciones">
                        <TechnicalspecificationsPOEGSH4804M600 />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones">
                        <SpecificationsPOEGSH4804M600 />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}
