import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SNMPGSH2404M.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2SNMPGSH2404M } from "./TitleSectionL2SNMPGSH2404M";
import { CharacteristicsSNMPGSH2404M } from "./CharacteristicsSNMPGSH2404M";
import { FeatureProductSNMPGSH2404M } from "./FeatureProductSNMPGSH2404M";

export function SNMPGSH2404M() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2SNMPGSH2404M />
           <CharacteristicsSNMPGSH2404M />
           <FeatureProductSNMPGSH2404M />
        </Container>
    </div>
  );
}
