import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftPOEGSH42060() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "4 x 30W PoE 10/100/1000 RJ45" 
        },
        {
            detail: "2 x 10/100/1000 RJ-45 Uplink" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3at/af/bt PoE" 
        },
        {
            detail: "IEEE802.3x" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-5 (Surge)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación) Modo (DIP): VLAN, predeterminado, extender Enlace y datos " 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Metal" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 4" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 30W" 
        },
        {
            detail: "PoE Alimentación/Switch: 60W" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "4KV (sobretensión), 8KV (EDS), Contacto 6KV" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "Arquitectura de conmutación: Almacenar y reenviar" 
        },
        {
            detail: "Capacidad del switch: Back-plan de hasta 12 Gbps" 
        },
        {
            detail: "tasa de reenvío: 8.928Mpps" 
        },
        {
            detail: "Memoria cache: 1mb" 
        },
        {
            detail: "Dirección MAC: 2K" 
        },
        {
            detail: "Jumbo Frame: 9216bytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar" 
        },
        {
            detail: "MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
