import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftL32TX2406() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware Interfaz:",
      detail: "24 x 10/100/1000/2500M Ports",
    },
    {
      detail: "6 x 10G SFP+ Ports",
    },
    {
      detail: "1 x RJ45 Console Port",
    },
    {
      detail: "30 Port Totally",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.3:Protocolo MAC Ethernet ",
    },
    {
      detail: "IEEE 802.3i:10BASE-T Ethernet ",
    },
    {
      detail: "IEEE 802.3u:100BASE-TX Fast Ethernet ",
    },
    {
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3z:1000BASE-X Gigabit Ethernet (fibra óptica) ",
    },
    {
      detail: "IEEE 802.3ae: 10G Ethernet (fibra óptica)",
    },
    {
      detail: "IEEE 802.3az:Ethernet de eficiencia energética",
    },
    {
      detail:
        "IEEE 802.3ad:Método estándar para realizar la agregación de vínculos ",
    },
    {
      detail: "IEEE 802.3bz:2.5GBASE-T",
    },
    {
      detail: "IEEE 802.3x:Control de flujo",
    },
    {
      detail: "IEEE 802.1ab: LLDP/LLDP-MED (Link Layer Discovery Protocol)",
    },
    {
      detail:
        "IEEE 802.1p:Priorización de tráfico del protocolo QoS/CoS de capa LAN (función de filtrado de multidifusión)",
    },
    {
      detail: "IEEE 802.1q:Operación del puente VLAN",
    },
    {
      detail:
        "IEEE 802.1x:Protocolo de autenticación y control de acceso cliente/servidor ",
    },
    {
      detail: "IEEE 802.1d:STP",
    },
    {
      detail: "IEEE 802.1s:MSTP",
    },
    {
      detail: "IEEE 802.1w:RSTP",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail:
        "PWR (indicador de encendido), SYS (luces del sistema), luz de advertencia Temperatura / voltaje).",
    },
    {
      detail: "Puerto RJ-45 de enlace: verde 2.5G, amarillo 100 / 1000Mbps, puerto SFP + de enlace: azul 10G, verde 2.5G",
    },
  ];
  const SurgeProtection = [
    {
      title: "Protección contra sobretensiones de iluminación:",
      detail: "Soporte de protección contra sobretensiones del puerto: modo común 6KV, modo diferencial 2KV; ESD: Contacto 6KV, Aire 8KV",
    },
  ];
  const Mechanic = [
    {
      title: "Mecánico:",
      detail: "Metal sólido 19' 1U montable en rack",
    },
  ];
  const PowerInput = [
    {
      title: "Voltaje de entrada:",
      detail: "AC100~240VAC",
    },
  ];
  const SwitchArchitecture = [
    {
      title:
        "Arquitectura del switch | Rendimiento:",
      detail: "Ancho de banda: 240Gbps",
    },
    {
      detail: "Velocidad de reenvío de paquetes: 178.56Mpps",
    },
    {
      detail: "DDR SDRAM: 512 MB",
    },
    {
      detail: "Memoria flash: 32MB",
    },
    {
      detail: "Caché del paquete: 16Mbit",
    },
    {
      detail: "Dirección MAC: 32K",
    },
    {
      detail: "Fotograma gigante: 10Kbyte",
    },
    {
      detail: "VLAN:4096",
    },
    {
      detail: "MTBF: 100000 horas",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
