import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive26G.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive26G } from "./SpecificationsLeftLive26G";
import { SpecificationsRightLive26G } from "./SpecificationsRightLive26G/SpecificationsRightLive26G";

export function FeatureProductLive26G() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-26G" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive26G />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive26G />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}
