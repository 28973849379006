import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH411120BT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH411120BT } from "./TitleSectionL2POEGSH411120BT";
import { CharacteristicsPOEGSH411120BT } from "./CharacteristicsPOEGSH411120BT";
import { FeatureProductPOEGSH411120BT } from "./FeatureProductPOEGSH411120BT";
import { FiberBackbonePoETechnology } from "../FiberBackbonePoETechnology";
import SurveillanceDevices from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceDevices.png";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.png";

export function POEGSH411120BT() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH411120BT />
           <CharacteristicsPOEGSH411120BT />
           <FiberBackbonePoETechnology content={"POE-GSH411-120BT"} image={SurveillanceDevices} image2={FiberOpticPOE} />
           <FeatureProductPOEGSH411120BT />
        </Container>
    </div>
  );
}
