import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftMC10GSFP() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware:",
      detail: "Voltage de entrada: Fuente externa de 5V 2A DC",
    },
    {
      detail: "Voltage típico de la Fuente: 3.3V",
    },
    {
      detail: "Interfaces: 1 x 10G/5G/2.5G/1G/100BASE-T RJ45 Auto-MDI/MDI-X, auto-negociación",
    },
    {
      detail: "1 x 10GBASE-X R SFP+",
    },
    {
      detail: "Capacidad de conmutación: 20 Gbps",
    },
    {
      detail: "Capacidad de transferencia: 2.48@64Kbytes",
    },
  ];
  const SupportedNetworkCables  = [
    {
      title: "Cables de Red soportados:",
      detail: "Connector: RJ-45",
    },
    {
      detail: "10G/5G/2.5G/1G/100M BASE-T:",
    },
    {
      detail:
        "10G--Cat 6A/Cat 7",
    },
    {
      detail:
        "5G--Cat 6/Cat 6A/Cat 7",
    },
    {
      detail:
        "1G/2.5G--Cat 5e/Cat 6/Cat 6A/ Cat 7",
    },
    {
      detail:
        "100M--Cat 5/Cat 5e/Cat 6/Cat 6A/ Cat 7",
    },
    {
      detail:
        "Cat 5/5e/6/6A/7 UTP cable (100 meters, max.)",
    },
    {
      detail:
        "EIA/TIA-568 100-ohm STP (100 meters, max.)",
    },
    {
      detail:
        "10GBASE-LR/SR/:",
    },
    {
      detail:
        "SR: 50/125μm or 62.5/125μm multi-mode fiber optic cable, up to 300m",
    },
    {
      detail:
        "LR: 9/125μm single-mode fiber optic cable, up to 80km",
    },
  ];
  const Standards = [
    {
      title: "Estándares:",
      detail: "IEEE 802.3u 100BASE-TX",
    },
    {
      detail: "IEEE 802.3ab 1000BASE-T",
    },
    {
      detail: "IEEE 802.3bz 2.5G/5GBASE-T",
    },
    {
      detail: "IEEE 802.3an 10GBASE-T",
    },
    {
      detail: "IEEE 802.3ae 10Gbps Ethernet",
    },
    {
      detail: "IEEE 802.3x full-duplex flow control",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail: "Power, 100M, 1000M, LINK/ACT, Fiber",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SupportedNetworkCables.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
