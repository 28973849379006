import { Col, Container, Row } from "react-bootstrap";
import { MarketingSection, SliderSection, DriversSection, ProductSection, AboutUsSection } from "./components";
import { VideoSection } from "./components/VideoSection/VideoSection";

import styles from "./Home.module.scss";


export function Home() {
  return (
    <>
      <Container fluid className={styles.banner}>
        <SliderSection />
        <ProductSection />
        <AboutUsSection />
        {/* TODO:Se revisará en proximas fases */}
        {/* <MarketingSection />
        <Container>
          <Row>
            <Col lg={4}>
              <VideoSection />
            </Col>
            <Col lg={8}>
              <DriversSection />
            </Col>
          </Row>
        </Container> */}
      </Container>
    </>
  );
}
