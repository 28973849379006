import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./IndustrialSolutionSection.module.scss";

export function IndustrialSolutionSection() {
  return (
    <Container>
      <Row className={styles.description}>
        <Col xs={12} className="mb-4">
          <HomeSubtitle title="Nuestras líneas de productos han evolucionado con los avances tecnológicos década tras década, siendo para la presente fecha los más destacados los siguientes:" />
        </Col>
        <Col xs={12} className="mb-3">
          <p>
            <div className={styles.subTitle}>
              Switches de redes capa 2 y capa 3
            </div>{" "}
            Cumplen absolutamente todos los estándares del mercado,
            soportando variadas configuraciones en términos de número de
            puertos, velocidades de 1Gigabit, 10 Gigabit, interfaces UTP y fibra
            óptica, tecnología Power over Ethernet, VLANs, enrutamientos
            estáticos y dinámicos, así como mecanismos de administración vía
            plataformas SNMP, web y/o consolas CLI.
          </p>
          &nbsp;
          <p>
            <div className={styles.subTitle}>Redes Inalámbricas</div> las más
            avanzadas tecnologías y estándares, soportando wifi6, incluyendo
            compatibilidad con todos los estándares anteriores, operación en las
            bandas de frecuencia de 2,4 y 5 GHz, alimentación vía interfaz WAN
            PoE, capacidad para soportar centenares de clientes con uno o
            múltiples Access Ponts en forma mallada, con múltiples antenas y
            excelentes niveles de potencia.
          </p>
          &nbsp;
          <p>
            <div className={styles.subTitle}>Redes GPON</div> Comprobada
            calidad en términos de rendimiento y costo beneficio, con líneas de
            OLTs que soportan las tecnologías GPON, XPON y XSPON, así como
            unidades terminales ONUs con múltiples prestaciones para proveer los
            servicios al cliente final de acceso a internet,
            voz y Televisión. Adicionalmente, esta línea se complementa con
            equipos requeridos por nuestros clientes finales para posibilitar un
            servicio integral que incluye el Transmisor vía fibra óptica y
            amplificador de señal para la adaptación a la red óptica pasiva.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
