import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleGPONOLT121 } from "./TitleGPONOLT121";
import { AppFTTXGPONOLT121 } from "./AppFTTXGPONOLT121";
import { SpecificationsGPONOLT121 } from "./SpecificationsGPONOLT121";
import styles from "./GPONOLT121.module.scss";

export function GPONOLT121() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleGPONOLT121 />
           <AppFTTXGPONOLT121 />
           <SpecificationsGPONOLT121 />
        </Container>
    </div>
  );
}
