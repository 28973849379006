
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./TransceiverISFPSR10G300M.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionTransceiverISFPSR10G300M } from "./TitleSectionTransceiverISFPSR10G300M";
import { CharacteristicsISFPSR10G300M } from "./CharacteristicsISFPSR10G300M";
import { OperatingCharacteristicsIndustrialGradeFiber } from "../OperatingCharacteristicsIndustrialGradeFiber";
import { FeatureProductTransceiverISFPSR10G300M } from "./FeatureProductTransceiverISFPSR10G300M";
import ElectricalOpticalTransmitterISFPSR10G300M from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPSR10G300M/ElectricalOpticalTransmitterISFPSR10G300M.png";
import ElectricalOpticalReceiverISFPSR10G300M from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPSR10G300M/ElectricalOpticalReceiverISFPSR10G300M.png";

export function TransceiverISFPSR10G300M() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionTransceiverISFPSR10G300M />
        <CharacteristicsISFPSR10G300M />
        <OperatingCharacteristicsIndustrialGradeFiber image={ElectricalOpticalTransmitterISFPSR10G300M} image2={ElectricalOpticalReceiverISFPSR10G300M} />
        <FeatureProductTransceiverISFPSR10G300M />
      </Container>
    </div>
  );
}
