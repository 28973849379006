import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesAirMax5XII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import AirMaxSignalIPDisplay from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AirMaxSignalIPDisplay.png";
import AirMaxPOEPassIPCamera from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AirMaxPOEPassIPCamera.png";
import IndicatorLEDdisplay from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/IndicatorLEDdisplay.png";
import { SubTitleProduct } from "../../../../SubTitleProduct";

export function SectionFunctionalitiesAirMax5XII() {
  const LeftFunctionalities = [
    {
      detail: "Estándar 802.11 a/g/n/",
    },
    {
      detail: "Modo punto a punto Distancia de 3 km",
    },
    {
      detail: "Pantalla LED de estado",
    },
    {
      detail: "Carcasa IP 65",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "Antena incorporada de 14 dbi",
    },
    {
      detail: "1 x 10/100mbps WAN",
    },
    {
      detail: "1 x 10/100mbps LAN",
    },
    {
      detail: "Entrada de alimentación PoE (WAN) de 48 V",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <SubTitleProduct content="Fácil de configurar" />
          <p>
            Posee un interruptor deslizante para cambiar el modo de operación;
            luego mediante pantalla LED muestra la configuración y permite
            cambiar la dirección IP y el canal, sin necesidad de acceder a la
            interfaz GUI, evitando así tener que utilizar el programa de
            configuración vía, siendo más fácil de verificar su estado de
            funcionamiento.
          </p>
          &nbsp;
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <SubTitleProduct content="Soporte PoE Pass Through para cámara IP / CCTV" />
          <p>
            Este AP soporta alimentación a través del puerto Ethernet (PoE)
            integrada para una fácil instalación y menor costo. El puerto WAN
            admite 802.3at 48V PoE de entrada, lo que hace que el dispositivo se
            pueda instalar en áreas donde las tomas de corriente no están
            fácilmente disponibles, eliminando la necesidad de infraestructura
            de eléctrica; El puerto LAN soporta salida PoE 802.3af de 48 V, el
            cual funciona como un switch PoE, mediante lo cual se puede
            proporcionar alimentación PoE a cámaras IP / CCTV, garantizando
            ahorró en infraestructura y costo del proyecto. cost.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={AirMaxSignalIPDisplay}
            alt="Signal and IP display"
          />
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={AirMaxPOEPassIPCamera}
            alt="AirMax POE Pass through for IP Camera"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Pantalla Led con los modos de operación: Wireless Bridge(Host CPE/Client CPE/Signal Strength/IP address)" />
          <div className={styles.subTitle}></div>
          <p>
            Con pantalla LED y función de detección automática de intensidad de
            señal para construir rápidamente una conexión inalámbrica punto a
            punto y punto a multipunto. Fácil instalación y entonación, con
            reducción de costos de proyecto, instalación y mantenimiento.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={IndicatorLEDdisplay}
            alt="Indicator LED display"
          />
        </Col>
      </Row>
    </Container>
  );
}
