import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import FiberOpticPOE802120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH802120/FiberOpticPOE802120.png";

export function PoEBackbone() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="PoE y modo extendido" />
          <p>
            Puedes conectar dispositivos PoE al switch, tales coo puntos de
            acceso o cámaras IP a cualquiera de los puertos PoE del switch y
            alimentarse con hasta un máximo de 30 W (at). El switch
            POE-GSH802-120 se puede configurar en modo Extender para alcanzar
            una distancia de hasta 250 m, esto puede ser muy útil en lugares
            donde es difícil obtener energía para encender su dispositivo PD. El
            modo Extender lo hace más fácil. El modo Extender es solo para los
            puertos 7 y 8 en el switch y cuando está habilitado la velocidad de
            transmisión será de 10Mbps. PoE Watchdog también está habilitado
            cuando el modo Extender está activado.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Backbone de fibra para larga distancia e Interconexión entre pisos" />
          <p>
            Extienda el alcance de su red con la ranura SFP. Utilice el puerto
            de fibra para conectar diferentes pisos en un edificio grande o con
            la oficina principal a una distancia más larga. Dependiendo del
            módulo utilizado, puede alcanzar hasta 20 km de distancia con una
            velocidad de transmisión de datos de 1250Mbps.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img className="w-100" src={FiberOpticPOE802120} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}
