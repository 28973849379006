import { Container, Row, Col } from "react-bootstrap";
import styles from "./AircloudPlatformAccess.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import AirCloudApp from "../../../../../../../../assets/imagesproduct/AirCloud/AirCloudApp.png";
import OptionProjectAirCloudApp from "../../../../../../../../assets/imagesproduct/AirCloud/OptionProjectAirCloudApp.png";

export function AircloudPlatformAccess() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Acceso a la plataforma Aircloud vía web" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Características principales de AirCloud:
            <ul className={styles.listItem}>
              <li className="mt-3">
                Ingrese desde su PC o laptop, al siguiente sitio de la
                aplicación AirCloud:
              </li>
              <a href="http://aircloud.airlive.com">
                http://aircloud.airlive.com
              </a>
              <Col xs={12} md={8} className="mb-3 mt-3">
                <img
                  className={styles.image}
                  src={AirCloudApp}
                  alt="Acceso a la plataforma Aircloud vía web"
                />
              </Col>
              <li className="mb-3">
                Registre su cuenta, si ingresa por primera vez, y crea su cuenta
                de usuario y clave de seguridad.
              </li>
              <li className="mb-3">
                En la opción Project del menu puedes crear el nombre del
                Proyecto, en base al nombre de tu empresa, organización,
                localidad o edificio. Puedes planificar y estructurar nombre de
                configuraciones con sentido lógico para redes muy amplias, con
                muchos access points.
              </li>
              <Col xs={12} md={8} className="mb-5">
                <img
                  className={styles.image}
                  src={OptionProjectAirCloudApp}
                  alt="Devices Supported by AirCloud"
                />
              </Col>
              <li className="mb-3">
                Comienza a agregar access points a cada Proyecto creado, tal
                como se indica en las instrucciones del siguiente video.
              </li>
              <a href="https://www.youtube.com/watch?v=BhhDcdxFjOw">
                https://www.youtube.com/watch?v=BhhDcdxFjOw
              </a>
              <li className="mt-3">
                Configure las opciones de subtitulos del video idioma español.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
