import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import ExampleTriplePlayServices from "../../../../../../../../assets/imagesproduct/AirLiveGPON/ExampleTriplePlayServices.png";

export function TriplePlayServices() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="GPON ONU" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Una GPON ONU es una unidad de red óptica que se utiliza en una red
            de acceso de fibra óptica pasiva de gigabit (GPON). Se conecta a un
            terminal de línea óptica (OLT) mediante un divisor óptico pasivo y
            una fibra óptica, y convierte las señales ópticas en eléctricas para
            proporcionar servicios de voz, datos y video a los usuarios finales.
          </p>
          &nbsp;
          <p>
            Una GPON ONU puede tener una o varias interfaces para conectar
            diferentes dispositivos, como teléfonos, computadoras, televisores,
            etc. Una GPON ONU también puede tener funciones de enrutamiento,
            conmutación, seguridad y gestión.
          </p>
          &nbsp;
          <p>
            Una GPON ONU se diferencia de una GPON ONT (terminal de red óptica)
            en que una GPON ONT es un tipo específico de GPON ONU que se utiliza
            para el servicio FTTH (fibra hasta el hogar), mientras que una GPON
            ONU puede usarse para otros servicios FTTx, como FTTB (fibra hasta
            el edificio), FTTC (fibra hasta el gabinete), etc.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Servicios Triple play" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Son servicios que ofrecen, sobre una misma conexión de banda ancha,
            dos servicios que requieren un gran ancho de banda, como el acceso a
            Internet y la televisión, y un servicio que requiere una baja
            latencia, como el teléfono. Triple play se enfoca en la convergencia
            de proveedores más que en la solución de problemas técnicos o un
            estándar común.Triple play permite a los usuarios disfrutar de una
            mayor variedad de servicios con una sola factura y un solo
            proveedor.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={ExampleTriplePlayServices}
            alt="Example Triple Play Services"
          />
        </Col>
        <Col className="mb-3 mt-3">
          <p>
            Ejemplos de servicios triple play:
            <ul>
              <li>
                Telefónica: ofrece Movistar Fusión, que incluye telefonía fija,
                móvil, Internet y televisión.
              </li>
              <li>
                Vodafone: ofrece Vodafone One, que incluye telefonía fija,
                móvil, Internet y televisión.
              </li>
              <li>
                Orange: ofrece Love, que incluye telefonía fija, móvil, Internet
                y televisión.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
