import { Container } from "react-bootstrap";
import styles from "./AboutUs.module.scss";
import { CoreValueSection, IndustrialSolutionSection, ProfileSection, SidebarAboutUs, VideoSection } from "../../../components";

export function AboutUs() {
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <ProfileSection />
        <VideoSection />
        <IndustrialSolutionSection />
        <CoreValueSection />
      </Container>
    </div>
  );
}
