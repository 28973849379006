import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { SidebarAboutUs } from "../../SidebarAboutUs";
import styles from "./TradeMark.module.scss";
import LogoAirlive1 from "../../../../../../assets/images/LogoAirlive1.png";
import LogoAirlive2 from "../../../../../../assets/images/LogoAirlive2.png";

export function TradeMark() {
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <Row>
          <Col className="mt-4 mb-5">
            <HomeSubtitle title="Marca Comercial" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mb-5">
            <div className={styles.subTitle}>Marca AirLive</div>
            <p>
              AirLive® es una marca registrada por AirLive Technologies Corp. La
              misma está amparada por derechos de marca. Todos los acuerdos con
              nuestros aliados y clientes estipulan el respeto y uso del nombre
              AirLive en sitios web, nombres comerciales, etc. exclusivamente
              para el uso de AirLive Technology Corp. Comuníquese con AirLive si
              tiene alguna pregunta sobre el tema de la presente marca.
            </p>
          </Col>
          <Col xs={12} md={6} className="mb-5">
            <div className={styles.subTitle}>Marca actual</div>
            <p>AirLive®</p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-4 mb-5">
            <HomeSubtitle title="Diseños de logotipos actuales registrados" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mb-5">
            <img src={LogoAirlive1} alt="Airlive Logo" />
          </Col>
          <Col xs={12} md={6} className="mb-5">
            <img src={LogoAirlive2} alt="Airlive Mark" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
