import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsPOEGSH4804M600.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftPOEGSH4804M600 } from "./TechnicalLeftPOEGSH4804M600";
import { TechnicalRightPOEGSH4804M600 } from "./TechnicalRightPOEGSH4804M600";

export function TechnicalspecificationsPOEGSH4804M600() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH4804M-600" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalLeftPOEGSH4804M600 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalRightPOEGSH4804M600 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
