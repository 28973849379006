import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./L32TX2406.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL32TX2406 } from "./TitleSectionL32TX2406";
import { CharacteristicsL32TX2406 } from "./CharacteristicsL32TX2406";
import { ApplicationsLayer3Switches } from "../ApplicationsLayer3Switches";
import { PerformanceandScalability } from "../PerformanceandScalability";
import { RouteManagement } from "../RouteManagement/RouteManagement";
import { InterfaceManagement } from "../InterfaceManagement";
import { FeatureProductL32TX2406 } from "./FeatureProductL32TX2406";

export function L32TX2406() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL32TX2406 />
           <CharacteristicsL32TX2406 />
           <ApplicationsLayer3Switches content="L3-2TX2406" />
           <PerformanceandScalability content="L3-2TX2406" />
           <RouteManagement />
           <InterfaceManagement content="L3-2TX2406" />
           <FeatureProductL32TX2406 />
        </Container>
    </div>
  );
}
