import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./SolutionsSection.module.scss";
import AirLiveIndustrial from "../../../../../assets/images/AirLiveIndustrial.png";
import Hotel from "../../../../../assets/images/Hotel.png";
import Parking from "../../../../../assets/images/Parking.png";
import transport from "../../../../../assets/images/transport.png";
import surveillance from "../../../../../assets/images/surveillance.png";
import DataCenter from "../../../../../assets/images/DataCenter.png";

export function SolutionsSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className={styles.description}>
          <p>
            Los productos AirLive están diseñados para atender diferentes
            sectores del mercado que demanda soluciones particulares. Así pues,
            con las distintas gamas de productos, y su diseño particular AirLive
            provee las siguientes soluciones:
          </p>
          &nbsp;
          <div className={styles.subTitle}>Centros de cómputos y oficinas</div>
          <img className={styles.image} src={DataCenter} alt="Data center" />
          <p>
            La línea de switches L2 y L3 permiten crear la infraestructura
            necesaria para centros de cómputos mediante equipos con backplane de
            alta velocidad, alta de tasa de transferencia de paquetes y
            altísimos niveles de disponibilidad.
          </p>
          &nbsp;
          <p>
            A nivel de borde de red los switches capa 3 soportan protocolos OSPF
            para conectividad con otras redes, mientras que a nivel de oficina,
            los switches Switches capa 2 y capa 3, además de las estaciones de
            trabajo, soportan de puertos con PoE para alimentar Access Points y
            cámaras de vigilancia.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="AirLive Industrial" />
          <p className="mt-3">
            Existe una serie de productos que atraen la atención para uso en
            aplicaciones industriales, Medio ambiente verde, Energía
            inteligente, Ciudad Inteligente y la Fábrica Inteligente.
          </p>
          &nbsp;
          <img
            className={styles.image}
            src={AirLiveIndustrial}
            alt="Data center"
          />
          <p className="mt-3">
            Este campo es soportado por una gama de switches con un diseño de
            grado industrial para ser instalados en rack industrial, o cajas
            NEMA, con entrada redundante de alimentación, puertos UTP o fibra
            óptica, con tecnología de protección de anillo industrial para
            recuperación de red ultrarrápida, red gestionada SNMP y vía PoE
            Watchdog para vigilar sus dispositivos 24/7.
          </p>
          <p className="mt-3">
            Esta tecnología de uso industrial soporta encapsulamiento apropiado
            para el propósito y los rangos de temperatura requeridos para dichas
            operaciones. Así pues, con estas soluciones se pueden construir
            anillos para la industria, redes de video vigilancia en ciudades,
            entre otras.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Hoteles, campus, clínicas, hospitales, estacionamientos" />
          <p className="mt-3">
            Estas soluciones se apalancan en la infraestructura de redes GPON
            mediante las cuales es posible construir backbones de redes de fibra
            óptica y distribuir los servicios a todas las áreas comunes,
            habitaciones y/o áreas abiertas, en combinación con redes
            inalámbricas.
          </p>
          <img className={styles.image} src={Hotel} alt="Data center" />
          <img className={styles.image} src={Parking} alt="Data center" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Transporte Inteligente" />
          <p className="mt-3">
            Mediante dispositivos de conectividad basados en tecnología 4G/5G y
            LTE podemos extender la comunicación de datos confiable a unidades
            de transporte públicos o privados para el acceso internet, cámaras
            de vigilancia y/o sensores de alerta de estacionado o exceso de
            velocidad.
          </p>
          <img className={styles.image} src={transport} alt="Data center" />
          <img className={styles.image} src={surveillance} alt="Data center" />
        </Col>
      </Row>
    </Container>
  );
}
