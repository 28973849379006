import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SwitchL3POEXGS4804850BT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSection } from "./TitleSection";
import { FeatureProduct } from "./FeatureProduct";

export function SwitchL3POEXGS4804850BT
() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSection />
           <FeatureProduct />
        </Container>
    </div>
  );
}
