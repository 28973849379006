import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import styles from "./VLANExtendModePOEGSH42060.module.scss";
import VLANExtendMode from "../../../../../../../../assets/imagesproduct/SwitchL2/VLANExtendMode.png";

export function VLANExtendModePOEGSH42060() {
  return (
    <Container>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Modo VLAN" />
          <p>
            Modo VLAN: esta característica adicional le da al conmutador
            seguridad adicional para los usuarios conectados a él. El modo VLAN
            funciona como Puerto aislamiento, lo que significa que cuando está
            habilitado, los usuarios conectados al Los puertos LAN no pueden
            comunicarse entre sí, solo se comunican con los puertos UP-Link
            (puerto 5/6). De esta manera hay una capa extra. de seguridad y los
            datos no serán compartidos entre los usuarios. Esto puede Ser
            utilizado en una pequeña oficina para separar a los invitados de la
            empresa. red o en una vivienda para separar a los inquilinos.
          </p>
          &nbsp;
          <SubTitleProduct content="Modo extendido" />
          <p>
            Modo extendido: El conmutador POE-GSH420-60 se puede configurar en
            modo extendido para alcanzar una distancia de hasta 250 m, esto
            puede resultar muy útil en ubicaciones donde es difícil conseguir
            energía para encender su dispositivo PD. La extensión El modo hace
            esto más fácil. El modo Extender es sólo para los puertos 3 y 4 en
            el interruptor y cuando esté habilitado, la velocidad de transmisión
            será de 10 Mbps.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img className={styles.image} src={VLANExtendMode} alt="VLAN-Extend-Mode" />
        </Col>
      </Row>
    </Container>
  );
}
