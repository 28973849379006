import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./SmartHomeSection.module.scss";
import SmartHome from "../../../../../assets/images/SmartHome.png";

export function SmartHomeSection() {
  return (
    <Container>
      <Row className={`${styles.description} px-2`}>
        <Col xs={12} className="my-3">
          <HomeSubtitle title="AirLive Smart Home" />
        </Col>
        <Col xs={12}>
          <p>
            Los enrutadores WiFi6 de AirLive están desarrollados también para
            redes en el hogar, con el soporte de la última tecnología
            inalámbrica incluido el cifrado avanzado de transferencia de datos,
            excelente cobertura inalámbrica con potentes señales y redes
            malladas, mediante lo cual se garantiza la calidad y rendimiento
            óptimo. Por otra parte, las redes VPN posibilitan el trabajo remoto
            en casa. Equipos ONUs para conectividad GPON en combinación de
            switches y enrutadores que hacen posible el disfrute de Televisión
            IPTV con programas de entretenimiento, control parental y uso de
            tiempo gestionado.
          </p>
          <img
            className={styles.image}
            src={SmartHome}
            alt="Smart Home"
          />
        </Col>
      </Row>
    </Container>
  );
}
