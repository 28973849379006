import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import EfficientSurveillanceTechnology from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/EfficientSurveillanceTechnology.png";
import HighPowerOutput from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/HighPowerOutput.png";

export function EfficientTechnology() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Tecnología eficiente de vigilancia y gestión de PoE" />
          <p>
            El Switch IGM-642BT-1.25G soporta alta potencia y cada puerto a 90W
            de forma automática adaptativa. Con la función de administración
            PoE, puede controlar la configuración del puerto PoE, la
            configuración del temporizador y la configuración de reinicio del
            temporizador, y el perro guardián PoE. Todas las funciones de
            gestión PoE garantizan toda la potencia para el PD conectado y una
            gran fiabilidad para todo el sistema. PoE 90W admite dispositivos
            que necesitan más potencia, esto puede ser, por ejemplo, un sistema
            POS, señalización digital o iluminación LED.
          </p>
          &nbsp;
          <p>
            Después de habilitar la función PoE Watchdog, el conmutador
            IGM-642BT-1.25G funciona como un guardia de seguridad virtual.
            Cuando el puerto POE se alimenta continuamente pero no hay tráfico,
            se activará la función de vigilancia POE. Después de 2 minutos de
            detección, la fuente de alimentación se detendrá y luego se
            encenderá.
          </p>
          &nbsp;
          <p>
            La vigilancia de PoE es particularmente útil para las cámaras. Por
            ejemplo, cuando el switch está operando, reconocerá la cámara
            frontal las 24 horas del día, los 7 días de la semana. Si no hay
            tráfico de datos de la cámara, se considera que la cámara está
            congelada y se utiliza la función de vigilancia PoE para reiniciar
            la cámara frontal y resolver el problema.
          </p>
        </Col>
        <Col xs={8} className="mb-5 text-align-center">
          <img
            className="w-100"
            src={EfficientSurveillanceTechnology}
            alt="image"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Salida de alta Potencia 802.3BT 90W" />
          <p>
            Este switch industrial AirLive IGM-642BT-1.25G soporta salida de
            alta potencia PoE++ a 90 W por puerto. Esta más alta salida le
            permite alimentar más dispositivos que solo una cámara IP o un punto
            de acceso. El PoE de 90W se puede utilizar para alimentar sistemas
            PoE necesarios de alta potencia, señalización digital o incluso
            iluminación LED PoE y mucho más. Dándole mucha más flexibilidad en
            el uso, ya que ya no está restringido a solo 30W.
          </p>
        </Col>
        <Col xs={5} className="mb-5">
          <img className="w-100" src={HighPowerOutput} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}
