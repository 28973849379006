import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SwitchL3XGS2404.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionXGS2404 } from "./TitleSectionXGS2404";
import { FeatureProductXGS2404 } from "./FeatureProductXGS2404";
import { CharacteristicsXGS2404 } from "./CharacteristicsXGS2404";
import { ApplicationsLayer3Switches } from "../ApplicationsLayer3Switches";
import { PerformanceandScalability } from "../PerformanceandScalability";
import { RouteManagement } from "../RouteManagement";
import { InterfaceManagement } from "../InterfaceManagement";

export function SwitchL3XGS2404() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionXGS2404 />
           <CharacteristicsXGS2404 />
           <ApplicationsLayer3Switches content="L3-XGS2404" />
           <PerformanceandScalability content="L3-XGS2404" />
           <RouteManagement />
           <InterfaceManagement content="L3-XGS2404" />
           <FeatureProductXGS2404 />
        </Container>
    </div>
  );
}
