import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsPOEGSH2404M400() {
    const Feature = [
        {
          detail: "Todos los 28 puertos ofrecen Gigabit de alta velocidad",
        },
        {
          detail: "Amplía la red con 4 ranuras SFP para transceptores de fibra y extiende los límites de alcance de Ethernet",
        },
        {
          detail: "400 W de alta potencia PoE, garantizan la máxima potencia para el dispositivo conectado ",
        },
        {
          detail: "Detecte y ofrezca alimentación automáticamente para PD conectados",
        },
        {
          detail: "Admite la función de administración inteligente L2 + SNMP y WEB que incluye DHCP Snooping, VLAN, IGMP, QoS, Spanning Tree, MAC Binding y Bandwidth Control",
        },
        {
          detail: "Funcionalidad de VLAN de vigilancia y compatibilidad con VLAN de voz para transmisión de alta prioridad",
        },
        {
          detail: "Visualización de estatuas claras, incluido el tráfico, la CPU, la memoria, el consumo de POE, el estado por puerto",
        },
        {
          detail: "Web-UI para una fácil administración; CLI y script de comandos para la configuración avanzada; SNMP utilizado para la gestión de herramientas de red populares.",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "24 x 30W PoE 10/100/1000" 
        },
        {
            detail: "4 x 1000T/SFP (puerto combinado)" 
        },
        {
            detail: "Compatible con 802.3at/802.3af" 
        },
        {
            detail: "Cada puerto soporta hasta 30Watts; Potencia PoE máxima de 400W" 
        },
        {
            detail: "Protección ESD contra sobretensiones por descargas atmósféricas: 6KV / Puerto ESD Protección estática: 15KV / Switch " 
        },
        {
            detail: "Captura L2+ SNMP v1/2/3" 
        },
        {
            detail: "Gestión de PoE, PoE Watchdog" 
        },
        {
            detail: "Autenticación: 802.1x, AAA, TACACS+ " 
        },
        {
            detail: "SNMP, WEB, CLI, SSH, Telnet, RMON " 
        },
        {
            detail: "División VLAN (Vigilancia, Voz, Conferencia MED, Datos)" 
        },
        {
            detail: "DHCP Snooping evita la instalación de enrutadores no autorizados" 
        },
        {
            detail: "Soporte 802.3az Energy Efficient Ethernet" 
        },
    ];
  return (
    <Container>
        <Row>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Especificaciones resaltantes" />
            </Col>
        </Row>
        <Row>
            <Col md={5} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col md={5} className="mb-5">
                <ul>
                    {NotableSpecifications.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
