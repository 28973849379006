import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightL3XGF28() {
    const FiberMedium = [
        {
            title:"Medio de fibra:",
            detail: "Fibra multimodo: 850nm, 1310nm Distancia de transmisión: 550m / 2Km" 
        },
        {
            detail: "Fibra monomodo: 1310nm, 1550nm Distancia de transmisión: 20/40/60/80/100/120Km" 
        },
    ];
    const EthernetRingProtection = [
        {
            title:"Protección de anillo Ethernet ERPS:",
            detail: "Admite G.8032 (ERPS), admite 255 bucles como máximo y admite 1024 dispositivos por anillo." 
        },
        {
            detail: "Soporte STP / RSTP / MSTP (ERPS), admite detección de bucle y recuperación, admite monitoreo y control remoto de bucle invertido (802.3ah OAM)" 
        },
        {
            detail: "El tiempo de autorrecuperación del anillo de red es inferior a 20 ms" 
        },
    ];
    const BasicSoftware = [
        {
            title:"Función de software Función básica:",
            detail: "Perro guardián de hardware de soporte, restablecimiento de fábrica, LED del sistema y del puerto" 
        },
        {
            detail: "Soporte de información global, información estadística, información de registro" 
        },
        {
            detail: "Soporte Gestión de usuarios, gestión de alarmas, gestión de configuración" 
        },
        {
            detail: "Soporte de velocidad de puerto, dúplex, control de flujo, configuración de longitud máxima de cuadro" 
        },
        {
            detail: "Soporte de duplicación de puertos, límite de velocidad de puerto, ahorro de energía de puerto" 
        },
    ];
    const Layer3Interface = [
        {
            title:"Interfaz de capa 3:",
            detail: "Interfaz de capa 3 de soporte" 
        },
        {
            detail: "Soporta IPv4, configuración de direcciones IPv6" 
        },
        {
            detail: "Compatibilidad con la configuración de ARP" 
        },
        {
            detail: "Compatibilidad con la configuración ND" 
        },
    ];
    const Layer3Routing = [
        {
            title:"Enrutamiento de capa 3:",
            detail: "Soporte de enrutamiento estático IPv4, soporte de enrutamiento estático IPv6" 
        },
        {
            detail: "Soporte RIP V1 / V2" 
        },
        {
            detail: "Soporte OSPF" 
        },
    ];
    const DHCP = [
        {
            title:"DHCP:",
            detail: "Soporte de servidor DHCP, soporte de retransmisión DHCP" 
        },
        {
            detail: "Soporte DHCP Snooping" 
        },
    ];
    const VLAN = [
        {
            title:"VLAN:",
            detail: "Soporte 4K VLAN" 
        },
        {
            detail: "Soporte 802.1Q VLAN, basado en puerto VLAN" 
        },
        {
            detail: "Soporte de acceso, troncal, modo VLAN híbrido" 
        },
        {
            detail: "Soporte GVRP (protocolo de registro VLAN)" 
        },
        {
            detail: "Soporte MAC VLAN 、IP VLAN" 
        },
        {
            detail: "Soporte de voz VLAN" 
        },
    ];
    const MacAddress = [
        {
            title:"Dirección MAC:",
            detail: "Soporte de aprendizaje automático de direcciones MAC y envejecimiento" 
        },
        {
            detail: "Soporte de tabla de direcciones estática, dinámica y filtrada" 
        },
    ];
  return (
    <Container>
      <ul>
        {FiberMedium.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EthernetRingProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {BasicSoftware.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Layer3Interface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Layer3Routing.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DHCP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MacAddress.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
