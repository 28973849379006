import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH820120.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH820120 } from "./TitleSectionL2POEGSH820120";
import { CharacteristicsPOEGSH820120 } from "./CharacteristicsPOEGSH820120";
import { FeatureProductPOEGSH820120 } from "./FeatureProductPOEGSH820120";

export function POEGSH820120() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH820120 />
           <CharacteristicsPOEGSH820120 />
           <FeatureProductPOEGSH820120 />
        </Container>
    </div>
  );
}
