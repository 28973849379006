import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesAWOD12ACi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import OmniAntenna from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/OmniAntenna.png";

export function SectionFunctionalitiesAWOD12ACi() {
  const LeftFunctionalities = [
    {
      detail: "Cumple con 802.11 a/b/g/n/ac Wave 2",
    },
    {
      detail: "Carcasa IP 65",
    },
    {
      detail: "Antena omnidireccional de 5 dBi incorporada",
    },
    {
      detail: "Control de la nube en modo AP",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "2.4/5Ghz AC 1200mbps",
    },
    {
      detail: "1 x WAN de 10/100/1000 Mbps",
    },
    {
      detail: "1 x LAN de 10/100/1000Mbps",
    },
    {
      detail: "Entrada de alimentación PoE (WAN) IEEE 802.3at de 48 V",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Frecuencia de 5 GHz, más canales, menor latencia, mayor estabilidad" />
          <p>
            El AP AWOD-12ACi soporta una banda completa de 5 Ghz de 5150 ~ 5850
            MHz, más de 200 canales disponibles, evitando el problema de
            interferencia de frecuencia, asegurando la eficiente transmisión de
            datos con alta velocidad, bajo retardo y alta estabilidad. Es el
            equipo ideal para locaciones con difícil servicio wifi.
          </p>
        </Col>
        <Col xs={6} md={6} className="mb-3">
          <SubTitleProduct content="Omni Antenna de largo alcance." />
          <p>
            El Access Point AWOD-12ACi tiene un área de cobertura de entre 100 y
            200 metros, dependiendo del entorno. Puedes cubrir una amplia zona
            conla cobertura de la señal Wi-Fi en la banda que desees.signal.
          </p>
          &nbsp;
          <p>
            Puedes cubrir una amplia zona con la cobertura de la señal Wi-Fi en
            la banda que desees.signal.
          </p>
        </Col>
        <Col xs={6} md={6} className="mb-5">
          <img
            className={styles.image}
            src={OmniAntenna}
            alt="Omni Antenna de largo alcance"
          />
        </Col>
      </Row>
    </Container>
  );
}
