import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleU618AXSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopAirCloudinWallU618AX from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/TopAirCloudinWallU618AX.png";
import inWallU618AXDescription from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/inWallU618AXDescription.png";
import SeamlessWirelessRoamingU618AX from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/SeamlessWirelessRoamingU618AX.png";

export function TitleU618AXSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point Wi-Fi 6 AX 1800 Gigabit in-Wall PoE Gestión Centralizada & Cloud, 4 Puertos Gigabit" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirCloud inWall-U618AX" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopAirCloudinWallU618AX}
            alt="Access Point Wi-Fi 6 AX 1800 Gigabit in-Wall PoE"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="Punto de acceso en la nube Gigabit de alta velocidad multifunción en la pared" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            El Airlive inWall-U618AX es un punto de acceso multifunción
            compatible con Wi-Fi6 empotrable en la pared con 4 puertos LAN de 1
            Gbps y 1 puerto de entrada PoE WAN de 1 Gbps. Diseñado como AP de
            tipo pared, es el AP ideal para el hogar, el edificio inteligente
            moderno o el hotel. Puede funcionar como un punto de acceso
            independiente en el hogar o pequeñas aplicaciones WLAN cuando
            funciona como un punto de acceso FAT. En caso de que se instale un
            gran número de APs, se puede preferir la administración central o la
            administración en la nube, entonces se puede conectar al controlador
            AirLive WLAN o AirCloud para una administración eficiente y
            monitorear el estado en vivo en cualquier lugar y en cualquier
            momento.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={inWallU618AXDescription}
            alt="inWall-U618AX"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Itinerancia inalámbrica sin interrupciones" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Además, el InWall-18AX es compatible con la tecnología inalámbrica
            de roaming wifi 802.11r/802.11k/802.11v y la transición rápida BSS
            (FT) para que los usuarios puedan mantenerse conectados y
            productivos mientras están en movimiento.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={SeamlessWirelessRoamingU618AX}
            alt="Seamless Wireless Roaming"
          />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Estas tecnologías trabajan juntas para permitir que los dispositivos
            cambien rápida y sin problemas entre puntos de acceso sin
            interrumpir la conexión ni requerir que el usuario se vuelva a
            conectar manualmente. Esta tecnología es particularmente útil en
            entornos donde los usuarios necesitan moverse dentro ambientes como
            en grandes oficinas, hoteles, aeropuertos, centros comerciales,
            campus y el hogar.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
