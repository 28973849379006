import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CharacteristicsXGS2404400 } from "./CharacteristicsXGS2404400";
import { Specifications2404400 } from "./Specifications2404400";
import { TechnicalspecificationsXGS2404400 } from "./TechnicalspecificationsXGS2404400";

export function FeatureProductXGS2404400() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Characteristics"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Characteristics" title="Características">
                        <CharacteristicsXGS2404400 />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones técnicas">
                        <Specifications2404400 />
                    </Tab>
                    <Tab eventKey="Technicalspecifications" title="Especificaciones técnicas">
                        <TechnicalspecificationsXGS2404400 />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}
