import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH402BT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH402BT from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH402BT/GSH402BT.png";
import descriptionSwitchL2POEGSH402BT from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH402BT/descriptionSwitchL2POEGSH402BT.jpg";

export function TitleSectionL2POEGSH402BT() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit POE de 6 puertos, 802.3at/bt, 60W/120W 4 puertos Gigabit con 4x30W PoE (puerto1 60W), 2x1.25G SFP, 1x1000T" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH402BT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH402BT}
            alt="Switch Gigabit POE de 6 puertos, 802.3at/bt, 60W/120W 4 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Aspectos Generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE+ 802.3af/at/bt, con puerto #1 Hi PoE 60W" />
          <p>
            El switch Gigabit de la serie AirLive POE-GSH402BT es compatible con
            802.3af(15W),802.3at(30W), 802.3bt(60W). Admite cuatro puertos PoE y
            2 puertos de enlace ascendente SPF Fiber. La potencia total de PoE
            puede ser de 65W o 120W (dependiendo del modelo). El puerto 1 admite
            PoE de alta potencia de hasta 120 W que satisfacen equipos
            necesarios de alta potencia, como PC delgada, LED PoE, IPCAM de alta
            potencia, etc.
          </p>
          &nbsp;
          <SubTitleProduct content="Switch Gigabit PoE+ de 6 puertos con VLAN y puerto SFP/RJ-45" />
          <p>
            Los switches de la serie AirLive POE-GSH402BT son switches PoE plug
            and play no gestionados con VLAN y extensión de cableado de 250
            metros, que puede configurar fácilmente mediante su conmutador DIP.
            Los dos puertos Gigabit Uplink (2x SFP) ayudan a conectar el switch
            a una red más grande, lo que lo convierte en el switch cliente más
            rentable para diversas aplicaciones en el hogar o la oficina.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH402BT}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
