import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH820120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH820120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH820120/GSH820120.png";

export function TitleSectionL2POEGSH820120() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch Gigabit PoE de 10 puertos, 802.3af/at, 120W Incluye 8 puertos Gigabit 30W PoE + 2x1G RJ-45" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="POE-GSH820-120" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH820120}
            alt="Switch Gigabit PoE de 10 puertos, 802.3af/at, 120W"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE 802.3af/at, con 2 enlaces ascendentes RJ-45" />
          <p>
            El switch Gigabit AirLive POE-GSH820-120 es compatible con el
            estándar 802.3af(15W)/802.3at(30W). Posee puertos Gigabit PoE+ y 2
            puertos de enlace ascendente Gigabit RJ-45. La potencia PoE total es
            de 120W. El switch AirLive POE-GSH820-120 es un switch PoE no
            administrado plug and play con VLAN y extensión de cableado de 250
            metros, que se puede configurar fácilmente a través de su conmutador
            DIP. Los dos puertos Gigabit Uplink ayudan a conectar el switch a
            una red más grande, lo que lo convierte en el switch cliente más
            rentable para diversas aplicaciones en el hogar o la oficina.
            Conecte dispositivos PoE al switch, tales como puntos de acceso o
            las cámaras IP a cualquiera de los puertos PoE del switch con hasta
            un máximo de 30 W (at).
          </p>
        </Col>
      </Row>
    </Container>
  );
}
