import { Container, Row, Col } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./IndustrialSwitches.module.scss";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import SwitchIGM642BT125G from "../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/SwitchIGM642BT125G.jpg";
import SwitchIGM642POE125G from "../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642POE125G/SwitchIGM642POE125G.jpg";

export function IndustrialSwitches() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const IndustrialPoEL2Switches = [
    {
      img: { src: SwitchIGM642BT125G, alt: "switch IGM-642BT-1.25G" },
      title: "switch IGM-642BT-1.25G",
      description:
        "Switch Gigabit L2, Industrial, Administrable con POE, 6-Puertos PoE",
      button: {
        name: "VER MÁS",
        path: "../SwitchIGM642BT125G",
      },
    },
    {
      img: { src: SwitchIGM642POE125G, alt: "switch IGM-642POE-1.25G" },
      title: "switch IGM-642POE-1.25G",
      description: "Switch Gigabit Industrial L2+ Anillo Administrado 4 Puertos PoE",
      button: {
        name: "VER MÁS",
        path: "../SwitchIGM642POE125G",
      },
    },
  ];

  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <Products />
        <Row>
          <Col className="mb-5">
            <HomeSubtitle title="Switches Industriales:" />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches Industriales Administrables con PoE L2" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {IndustrialPoEL2Switches.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
