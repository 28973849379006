import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleAirDualAC12Section.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopAirDualAC12 from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/TopAirDualAC12.png";
import AirDualAC12Details from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/AirDualAC12Details.png";

export function TitleAirDualAC12Section() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point Wi-Fi CPE AC1200 Dual para exteriores" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirDual-AC12" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopAirDualAC12}
            alt="Access Point Wi-Fi CPE AC1200 Dual"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="AP/repetidor de doble banda para exteriores de alto rendimiento El AirDual-AC12" />
          <p>
            es un AP/repetidor/CPE para exteriores de 1200 Mbps de alto
            rendimiento construido con los más altos estándares. Cuenta con
            antena MIMO de doble polaridad para garantizar una recepción óptima.
            Se presenta PoE 802.at IEEE de 48 V para permitir una larga
            distancia de cableado.
          </p>
          &nbsp;
          <SubTitleProduct content="Diseño profesional para exteriores + protección interna contra rayos, adecuado para múltiples entornos hostiles" />
          <p>
            Encapsulado en una carcasa de plástico ABS para exteriores y
            componentes profesionales de protección contra rayos, con estándar
            IP65 a prueba de agua y polvo, adecuado para múltiples entornos
            hostiles. Incluso en ambientes extremadamente cálidos y fríos (-40
            °C ~ + 55 °C), puede funcionar de manera estable.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-40"
            src={AirDualAC12Details}
            alt="Access Point Wi-Fi "
          />
        </Col>
      </Row>
    </Container>
  );
}
