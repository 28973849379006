import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH802120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH802120 } from "./SpecificationsLeftPOEGSH802120";
import { SpecificationsRightPOEGSH802120 } from "./SpecificationsRightPOEGSH802120/SpecificationsRightPOEGSH802120";

export function FeatureProductPOEGSH802120() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH802-120" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH802120 content={"120w"} />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH802120 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
