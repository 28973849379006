import { Container } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import PlataformGPON from "../../../../../../assets/imagesproduct/AirLiveGPON/PlataformGPON.png";
import OLT121 from "../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121/OLT121.png";
import ImageXponOnu1GE from "../../../../../../assets/imagesproduct/AirLiveGPON/XPONONU1GE/ImageXponOnu1GE.png";
import styles from "./TelecomXGPONCloud.module.scss";

export function TelecomXGPONCloud() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const products = [
    {
      img: { src: PlataformGPON, alt: "PlataformAirLiveGPON" },
      title: "Plataforma AirLive GPON",
      description: "Conoce la Plataforma GPON AirLive",
      button: {
        name: "VER MÁS",
        path: "../PlataformAirLiveGPON",
      },
    },
    {
      img: { src: OLT121, alt: "GPON OLT-121" },
      title: "GPON OLT-121",
      description: "OLT GPON 1 Puerto PON de 10G SFP+",
      button: {
        name: "VER MÁS",
        path: "../GPONOLT121",
      },
    },
    {
      img: { src: ImageXponOnu1GE, alt: "XPON ONU-1GE" },
      title: "XponONU1GE",
      description: "ONU  de 1 Puerto XPON + 1*GE modo Dual",
      button: {
        name: "VER MÁS",
        path: "../XponONU1GE",
      },
    },
  ];

  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <Products />
        <HomeSubtitle title="Telecom XGPON Cloud" />
        <Carousel responsive={responsive}>
          {products.map((products) => (
            <ProductCard
              img={products.img}
              title={products.title}
              description={products.description}
              buttonCard={products.button}
            />
          ))}
        </Carousel>
      </Container>
    </div>
  );
}
