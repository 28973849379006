import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftLive2TX801() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "8 x 10/100/1000/2500 RJ45" 
        },
        {
            detail: "1x 1.25G/2.5/10G SFP +" 
        },
        {
            detail: "9 puertos en total" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3bz 2.5GBase-T" 
        },
        {
            detail: "IEEE 802.3z 1000BASE-X Gigabit Ethernet (fibra óptica)" 
        },
        {
            detail: "IEEE 802.3ae 10G Ethernet (fibra óptica)" 
        },
        {
            detail: "IEEE802.3x Control de flujo y contrapresión" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-5 (Sobretensión)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (power supply),Link/Act (Indicator), 2.5G (Indicator)." 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Carcasa de metal sólido" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones y ESD:",
            detail: "6KV" 
        },
    ];
    const InputPower = [
        {
            title:"Alimentación de entrada:",
            detail: "12V/2A Adaptador de corriente" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "almacenar y reenviar" 
        },
        {
            detail: "Capacidad del switch: Plan de seguimiento de hasta 60 Gbps" 
        },
        {
            detail: "Tasa de avance de paquete: 44,65Mpps" 
        },
        {
            detail: "VLAN: 4096" 
        },
        {
            detail: "Dirección MAC: 4K" 
        },
        {
            detail: "Jumbo Frame: 12Kbytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InputPower.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
