import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionSwitchIGM642POE125G.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import IGM642POE125G from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642POE125G/IGM642POE125G.png";
import descriptionSwitchIGM642POE125G from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642POE125G/descriptionSwitchIGM642POE125G.png";

export function TitleSectionSwitchIGM642POE125G() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit Industrial L2+ Anillo Administrado 4 Puertos Gigabit PoE*30W más 2 SFP" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="IGM-642POE-1.25G" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={IGM642POE125G}
            alt="Switch Gigabit Industrial L2+ Anillo Administrado 4-Puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Resumen" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Solución Gigabit PoE avanzada administrable para ambientes industriales" />
          <p>
            El switch AirLive IGM-642POE-1.25G L2+ PoE+ gestionado
            industrialmente está equipado con 4 puertos PoE+ a 10/100/1000Mbps,
            cumple con el estándar 802.3at para hasta 30 vatios de potencia en
            cada puerto PoE, y 2 puertos Gigabit de fibra óptica
            100/1000/1250Mbps en una carcasa compacta robusta IP40 para ser
            instalada en cualquier entorno hostil sin restricciones de espacio.
            El switch industrial ofrece interfaces de gestión IPv6/IPv4
            ampliadas y fáciles de usar, funciones de conmutación L2/L3, función
            de enrutamiento estático de capa 3 y tecnología ampliada ITU G.8032
            ERPS Ring para mejorar las capacidades de autor reparación y las
            funciones PoE inteligentes de AirLive para controlar la supervisión
            IP exterior y las aplicaciones de red inalámbrica. PoE puede
            funcionar de manera confiable, estable y silenciosa en el rango de
            temperatura de -40 ° C a 85 ° C.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchIGM642POE125G}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
