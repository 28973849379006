import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsSNMPGSH2404M.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftSNMPGSH2404M } from "./TechnicalLeftSNMPGSH2404M";
import { TechnicalRightSNMPGSH2404M } from "./TechnicalRightSNMPGSH2404M";

export function TechnicalspecificationsSNMPGSH2404M() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive SNMP-GSH802M" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalLeftSNMPGSH2404M />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalRightSNMPGSH2404M />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
