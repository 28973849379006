import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesU618AX.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function SectionFunctionalitiesU618AX() {
  const LeftFunctionalities = [
    {
      detail: "Compatible con Wi-Fi 6 11a/b/g/n/ac/ax",
    },
    {
      detail: "OFDMA, MU-MIMO",
    },
    {
      detail: "2T2R 1800Mbps inalámbrico de alta velocidad (600Mbps + 1200Mbps)",
    },
    {
      detail: "Canal 20/40/80Mhz",
    },
    {
      detail: "Bandas duales simultáneas",
    },
    {
      detail: "Itinerancia sin interrupciones",
    },
    {
      detail: "1 x 10/100/1000mbps WAN",
    },
    {
      detail: "1 x 10/100/1000mbps LAN",
    },
    {
      detail: "Entrada de alimentación PoE (WAN) 802.3af",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "Modo de operación FIT/FAT",
    },
    {
      detail: "Tipo In-Wall, para colocación dentro de un enchufe de pared.",
    },
    {
      detail: "Modo de operación de AP y AP Gateway",
    },
    {
      detail: "Soporte de gestión en la nube mediante AirCloud",
    },
    {
      detail: "Soporte de controlador de CA con AirLive WLAN-128GM/64GM",
    },
    {
      detail: "Hasta 8 SSID múltiples",
    },
    {
      detail: "Soporte VLAN en modo AP",
    },
    {
      detail: "QoS en modo de puerta de enlace de AP",
    },
    {
      detail: "Filtrado de URL/MAC/IP",
    },
    {
      detail: "Temporizador Wi-Fi ",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
