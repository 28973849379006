import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionXGS2404400.module.scss";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import SwitchXGS2404400 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3POEXGS2404400/SwitchXGS2404400.png";
import descriptionSwitchL32404400 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3POEXGS2404400/descriptionSwitchL32404400.png";

export function TitleSectionXGS2404400() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch L3 400W Administrado de 24 puertos Gigabit PoE+ con 4 puertos 10G SFP" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3POE-XGS2404-400 BT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchXGS2404400}
            alt="Switch L3 400W Administrado de 24 puertos Gigabit PoE+ con 4 puertos 10G SFP"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Descripción" />
        </Col>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Funcionalidad capa 3" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El L3POE-XGS2404-400 BT es un switch capa 3 que ofrece hardware de
            alto rendimiento con alimentación PoE 802.3bt de 90W en los puertos
            1~8 y 30W en los puertos restantes, hasta un total de 400 W. Soporta
            enrutamiento IPV4/IPV6 estático y enrutamiento dinámico OSPF y RIP
            mediante el intercambio de información de enrutamiento con otros
            switches y enrutadores de capa 3. Con la serie L3POE-XGS2404-400 BT
            , los clientes podrían lograr fácilmente una ruta basada en
            políticas (PBR), que es importante cuando necesitan un switch para
            cambiar de aplicación y un tiempo de recuperación de red corto.
          </p>
          &nbsp;
          <p>
            La potencia de enrutamiento del L3POE-XGS2404-400 BT le permite
            realizar funcionalidades de capa 3, inspeccionar los paquetes
            entrantes y tomar decisiones de enrutamiento dinámico OPF, así como
            rutas estáticas IPV4/IPB6 con una latencia de red mucho más baja que
            un enrutador típico, ya que los paquetes no tienen que realizar
            pasos adicionales a través de un enrutador.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL32404400}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
