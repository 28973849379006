import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionXGS4804.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SwitchXGS4804 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS4804/SwitchXGS4804.png";
import descriptionSwitchL34804 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS4804/descriptionSwitchL34804.png";

export function TitleSectionXGS4804() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch Gigabit L3 Administrable con 10G uplink Incluye 48 Puertos Gigabit + 4 Puertos 10G SFP+" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3-XGS4804" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchXGS4804}
            alt="Switch L3 Administrado de 48 puertos Gigabit+ con 4 puertos 10G SFP"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Capa 3" />
          <p>
            El L3POE-XGS4804 es un switch capa 3 que ofrece hardware de alta
            densidad y rendimiento. Soporta enrutamiento IPV4/IPV6 estático y
            enrutamiento dinámico OSPF y RIP mediante el intercambio de
            información de enrutamiento con otros switches y enrutadores de capa
            3. Con la serie L3POE-XGS4804, los clientes podrían lograr
            fácilmente una ruta basada en políticas (PBR), que es importante
            cuando necesitan un switch para aplicaciones exigentes y un tiempo
            de recuperación de red corto.
          </p>
          &nbsp;
          <SubTitleProduct content="Qué es un Switch Capa 3?" />
          <p>
            Es un dispositivo especializado para enrutamiento de red, similar a
            un enrutador. Ambos pueden admitir los mismos protocolos de
            enrutamiento, inspeccionar los paquetes entrantes y tomar decisiones
            de enrutamiento dinámico basadas en las direcciones de origen y
            destino internas. Una de las principales ventajas de un switch de
            capa 3 sobre un router es la forma en que se realizan las decisiones
            de enrutamiento. Los switches capa 3 tienen latencia de red mucho
            más baja, ya que los paquetes no tienen que realizar pasos
            adicionales a través de un enrutador.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL34804}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
