import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function TechnicalspecificationsRightWLAN128GM() {
  const DeviceUpdate = [
    {
      title: "Actualización del dispositivo",
      detail: "Actualización en línea, Actualización local, Actualización del firmware",
    },
  ];
  const DeviceService = [
    {
        title: "Servicio de dispositivos",
      detail: "Activar/desactivar AC, Habilitar/Deshabilitar DHCP, Dirección IP del servidor, Recuento de direcciones, Tiempo de arrendamiento, IP asignada, Lista de IP, Enlace estático",
    },
  ];
  const IPTVSettings = [
    {
      title: "Configuración de IPTV:",
      detail: "Habilitar el modo IPTV, Etiquetas IPTV, Gráfico de topología",
    },
  ];
  const AutomaticDeviceRoaming = [
    {
      title: "Itinerancia automática del dispositivo:",
      detail: "Itinerancia automática, Prioridad 5G",
    },
  ];
  const NetworkParameters = [
    {
      title: "Parámetros de Red:",
      detail: "Configuración de LAN, Dirección IP, Subred, Servicio DHCP, IP de inicio/fin, DNS primario/secundario, Tiempo de concesión DHCP, Número de asignación DHCP",
    },
  ];
  const WANconfiguration = [
    {
      title: "Configuración de WAN:",
      detail: "Acceso a Internet: DHCP / IP estática / PPPoE / ByPass, MTU, Detección de línea",
    },
  ];
  const Cloud = [
    {
      title: "Nube:",
      detail: "Habilitar/deshabilitar la nube, Servidor en la nube, Latitud/Longitud, Estado vinculante",
    },
  ];
  const User = [
    {
      title: "Usuario:",
      detail: "Lista de usuarios, Enlace, Lista negra",
    },
  ];
  return (
    <Container>
      <ul>
        {DeviceUpdate.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DeviceService.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {IPTVSettings.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AutomaticDeviceRoaming.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {NetworkParameters.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WANconfiguration.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Cloud.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {User.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
