import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function TechnicalspecificationsLeftWLAN128GM() {
  const MainChip = [
    {
      title: "Chip principal:",
      detail: "MTK MT7621",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "16MB",
    },
  ];
  const SSD = [
    {
        title:"SSD:",
      detail: "256MB",
    },
  ];
  const Ethernet = [
    {
      title: "Ethernet (LAN):",
      detail: "4 x RJ45 10/100/1000mbps",
    },
  ];
  const WAN = [
    {
        title: "WAN:",
      detail: "1x RJ-45 10/100/1000mbps",
    },
  ];
  const NetworkProtocol = [
    {
        title: "Protocolo de red:",
      detail: "DHCP, ICMP, NAT, PPPoE, SNTP, HTIEEE 802.3, 802.3u, 802.3ab, TCP/IP, TP, DDNS, IPsec, PPTP, L2TP, CAPWAP Protocol",
    },
  ];
  const HeatDissipation = [
    {
        title: "Disipación de calor:",
      detail: "ventilador de bola súper silencioso",
    },
  ];
  const PowerInput = [
    {
        title: "Entrada de energía:",
      detail: "1 x 12VDC",
    },
  ];
  const Energy = [
    {
        title: "Energía:",
      detail: "AC: 100-240V, 50HZ",
    },
  ];
  const WANWAN = [
    {
        title: "WAN-WAN:",
      detail: "PPPoE, DHCP, IP estática, por modo de paso",
    },
  ];
  const FirmwareFeatures = [
    {
        title: "Características del firmware - Gestión de AP:",
      detail: "Max para administrar 128 PCS Wireless AP de forma centralizada y remota para administrar/configurar AP inalámbrico.",
    },
  ];
  const PAmanagement = [
    {
      detail: "Implementación de plantillas AP, Ver el estado del usuario, Código de país inalámbrico",
    },
  ];
  const PAmanagementContinue = [
    {
      detail: "Reiniciar/Restablecer, Contraseña Web, Eliminar AP, Lista de usuarios en línea, Configurar AP",
    },
  ];
  const APSquad = [
    {
        title: "Plantilla AP:",
      detail: "Agregar dispositivo, Configurar dispositivo, Dispositivo inalámbrico, Frecuencia inalámbrica: 2.4G / 5.8G, Encriptación",
    },
  ];
  const APSquadContinue1 = [
    {
      detail: "VLAN ID, Virtual AP1~4, Código de país, Estación Max, Aislamiento del usuario, IG corto, Intervalo de baliza",
    },
  ];
  const APSquadContinue2 = [
    {
      detail: "Umbral de cobertura, Umbral de fragmento, Umbral RTS, Reinicie regularmente, Contraseña web del dispositivo",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Interfaz del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SSD.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Ethernet.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {NetworkProtocol.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {HeatDissipation.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Energy.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WANWAN.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FirmwareFeatures.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PAmanagement.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PAmanagementContinue.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {APSquad.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {APSquadContinue1.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {APSquadContinue2.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
