import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live26G.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live26G } from "./TitleSectionL2Live26G";
import { ModeVlanQoS } from "../ModeVlanQoS";
import { FeatureProductLive26G } from "./FeatureProductLive26G";
import VLANExtendMode from "../../../../../../../assets/imagesproduct/SwitchL2/VLANExtendMode.png";

export function Live26G() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live26G />
           <ModeVlanQoS image={VLANExtendMode} uplinkports={"7/8"} numberports={"1~8"} extendedmodenumberports={"Puerto 17~24"} numberports2={"25/26"} />
           <FeatureProductLive26G />
        </Container>
    </div>
  );
}
