import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesAirDualAC12.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import Frequency from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/Frequency.png";

export function SectionFunctionalitiesAirDualAC12() {
  const LeftFunctionalities = [
    {
      detail: "Cumple con 802.11 a/b/g/n/ac Wave 2",
    },
    {
      detail: "Encapsulamientpo IP 65",
    },
    {
      detail: "Antena incorporada de 15 dbi",
    },
    {
      detail: "Control en la nube en modo AP",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "2.4/5Ghz AC 1200mbps",
    },
    {
      detail: "1 x 10/100/1000mbps WAN",
    },
    {
      detail: "1 x 10/100/1000mbps LAN",
    },
    {
      detail: "Alimentación 48V IEEE 802.3at PoE (WAN)",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Frecuencia de 5 GHz, más canales, menor latencia, mayor estabilidad" />
          <p>
            Con una frecuencia completa de 5 Ghz de 5020 ~ 6100 MHz, más de 200
            canales disponibles, el AirDual-AC12 evita el problema de
            interferencia de frecuencia, alta transmisión de datos con alta
            velocidad, bajo retardo y alta estabilidad, ideal para ubicarlo en
            sitios difíciles que lo requieren.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={Frequency}
            alt="Frequency"
          />
        </Col>
      </Row>
    </Container>
  );
}
