import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH802120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH802120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH802120/GSH802120.png";

export function TitleSectionL2POEGSH802120() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch Gigabit POE de 10 puertos, 802.3af/at, 120W Incluye 8 puertos Gigabit PoE 30W + 2x1.25G SFP" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="POE-GSH802-120" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH802120}
            alt="Switch Gigabit POE de 10 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE 802.3af/at, con 2 enlaces ascendentes SFP" />
          <p>
            El switch AirLive POE-GSH802-120 Gigabit es compatible con el
            estándar 802.3af(15W)/802.3at(30W). Admite 8 puertos Gigabit PoE+ y
            2 puertos de enlace ascendente de fibra SPF Gigabit. La potencia PoE
            total es de 120W. El switch AirLive POE-GSH802-120 es un switch PoE
            no administrado plug and play con extensión de cableado de 250
            metros, que se puede configurar fácilmente a través de su
            interruptor DIP. Los dos puertos SFP Gigabit Uplink ayudan a
            conectar el switch a una red más grande, lo que lo convierte en el
            switch cliente más rentable para diversas aplicaciones en el hogar o
            la oficina.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
