import { Container, Row, Col } from "react-bootstrap";
import styles from "./WarningSection.module.scss";

export function WarningSection() {
  return (
    <Container>
      <Row className="mb-5">
        <Col xs={12} className={styles.description}>
          <p>
            <span className={styles.subTitle}>
              Si el artículo RMA ya no está disponible dentro del período de
              garantía,
            </span>
              AirLive ® Technology Corp. se reserva el derecho de
              sustitulirlo por un producto similar o emitir un crédito basado en
              el precio de venta real.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
