import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsTOP12AC.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftTOP12AC } from "./SpecificationsLeftTOP12AC";
import { SpecificationsRightTOP12AC } from "./SpecificationsRightTOP12AC";

export function SpecificationsTOP12AC() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="TOP-12AC AC1200 Access Point" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftTOP12AC />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightTOP12AC />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
