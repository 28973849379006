import { Col, Container, Row } from "react-bootstrap";
import styles from "./Technicalspecifications10XGF12.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftL310XGF12 } from "./SpecificationsLeftL310XGF12";
import { SpecificationsRightL310XGF12 } from "./SpecificationsRightL310XGF12";

export function Technicalspecifications10XGF12() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3-10XGF12" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
            <SpecificationsLeftL310XGF12 />
        </Col>
        <Col md={5} className={styles.RightList}>
            <SpecificationsRightL310XGF12 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
