import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live16GTMini.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live16GTMini } from "./TitleSectionL2Live16GTMini";
import { ModeVlanQoS } from "../ModeVlanQoS";
import { FeatureProductLive16GTMini } from "./FeatureProductLive16GTMini";
import VLANExtendMode from "../../../../../../../assets/imagesproduct/SwitchL2/VLANExtendMode.png";

export function Live16GTMini() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live16GTMini />
           <ModeVlanQoS image={VLANExtendMode} uplinkports={"15/16"} numberports={"1~8"} extendedmodenumberports={"Puerto 1~14"} numberports2={"15/16"} />
           <FeatureProductLive16GTMini />
        </Container>
    </div>
  );
}
