import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationAirMax5XII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AdvancedModeOperation from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AdvancedModeOperation.png";

export function AdvancedAdministrationAirMax5XII() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Modo de operación avanzado de AP / controlador / repetidor o administración de la nube" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="El AirLive AirMax5 II soporta varias formas diferentes de ser controlado y administrado" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo controlador:" />
          <p>
            En este modo, el punto de acceso (AirMax5 II) funciona con un
            controlador de acceso AirLive WLAN (WLAN-128GM), siendo el
            controlador el "cerebro" del punto de acceso AirMax5 II y controla
            todas las funciones, como la configuración inalámbrica, el cifrado y
            más. Esta función también proporciona al instalador una
            configuración basada en plantillas.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Control en la nube:" />
          <p>
            El AirLive AirMax5 II se puede controlar a través de la nube, una
            vez que el AP se ha agregado a ésta. Se puede controlar desde
            cualquier lugar. Esto ofrece beneficios adicionales cuando se trata
            de una gran red de puntos de acceso, ya que no se necesita acudir al
            sitio y el soporte se puede dar desde una oficina remota. Además, se
            pueden otorgar múltiples niveles de control en la nube desde el
            administrador hasta solo los derechos de espectador. Los AP se
            pueden agregar y eliminar en cualquier momento.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo AP y Repetidor:" />
          <p>
            El AirMax5 II soporta los modos AP o repetidor para aplicaciones
            inalámbricas que requieren conectividad punto a punto, o
            punto-multipunto.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AdvancedModeOperation}
            alt="Advanced Mode of Operation"
          />
        </Col>
      </Row>
    </Container>
  );
}
