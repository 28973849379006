import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationAWOD12ACi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AdvancedModeOperation from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AdvancedModeOperation.png";
import APrepetidor from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/APrepetidor.png";

export function AdvancedAdministrationAWOD12ACi() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Encapsulamiento ABS resistente a la intemperie" />
          <p>
            El AWOD-12ACi está diseñado con carcasa ABS impermeable, a prueba de
            polvo y protección solar, evita el daño por partículas nocivas,
            clima lluvioso, etc. Por lo tanto, se adapta a varios entornos,
            siendo su temperatura de operación soportada de -40 °C a 55 °C, lo
            cual lo hace viable para múltiples climas.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img className="w-40" src={APrepetidor} alt="AP / repetidor" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Modo avanzado de AP/repetidor o gestión en la nube" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo AP y repetidor:" />
          <p>
            Este versátil Access Point AirLive AWOD-12ACi puede operar en modo
            AP o repetidor, lo que permite al instalador configurar una red que
            cubre un área de 100 a 200 m (dependiendo del entorno). O construir
            una conexión de red a una distancia extendida entre varios
            edificios, o cubrir un área abierta más grande con varias unidades
            en modo repetidor.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Control en la nube:" />
          <p>
            El AirLive AWOD-12ACi se puede controlar a través de la nube (SOLO
            en modo AP), una vez que el AP se ha agregado a la plataforma
            AirCloud. Se puede controlar desde cualquier lugar. Esto ofrece
            beneficios adicionales cuando se trata de una gran red de muchos
            puntos de acceso, ya que no se necesita acudir al sitio de
            instalación, y el soporte se puede dar desde una oficina remota.
            Además, se pueden otorgar múltiples niveles de control en la nube
            desde el administrador hasta solo los derechos de visualizador. Los
            AP se pueden agregar en forma individual o múltiple utilizando
            plantillas, habilitar, inhabilitar, o hasta eliminar en cualquier
            momento.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AdvancedModeOperation}
            alt="Advanced Mode of Operation"
          />
        </Col>
      </Row>
    </Container>
  );
}
