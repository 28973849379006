import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH820120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH820120 } from "./SpecificationsLeftPOEGSH820120";
import { SpecificationsRightPOEGSH820120 } from "./SpecificationsRightPOEGSH820120/SpecificationsRightPOEGSH820120";

export function FeatureProductPOEGSH820120() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH802-120" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH820120 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH820120 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
