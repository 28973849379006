import { Container, Row, Col } from "react-bootstrap";
import { SmartHomeSection, SolutionsSection } from "./components";
import { HomeSubtitle } from "../../HomeSubtitle";

export function Solutions() {
  return (
    <Container fluid className="px-0 pb-5">
      <Container>
        <Row>
          <Col className="my-4">
          <HomeSubtitle title="Soluciones AirLive" />
          </Col>
            <SolutionsSection />
            <SmartHomeSection />
        </Row>
      </Container>
    </Container>
  );
}
