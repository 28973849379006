import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSection10XGF12.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SwitchDetail10XGF12 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL310XGF12/SwitchDetail10XGF12.png";
import descriptionSwitchL310XGF12 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL310XGF12/descriptionSwitchL310XGF12.png";

export function TitleSection10XGF12() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch L3 Administrado de 12 puertos de 10 Gigabit SFP+" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3-10XGF12" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchDetail10XGF12}
            alt="Switch L3 Administrado de 12 puertos de 10 Gigabit SFP+"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El L3-10XGF12 es un switch de alto rendimiento con funcionalidades
            de enrutamiento, ruta estática IP, rutas dinámicas OSPF y RIP,
            proporcionando enrutamiento dinámico mediante el intercambio de
            información de paquetes con otros switches y enrutadores de capa 3.
            Con esta funcionalidad los clientes podrían lograr fácilmente una
            ruta basada en políticas (PBR), importante cuando necesitan un
            switch de fibra para aplicaciones exigentes en términos de
            velocidad, rendimiento y tiempo de recuperación de red.
          </p>
          &nbsp;
          <p>
            El switch capa 3 L3-10XGF12 posee un robusto hardware y multi
            diversidad de conectividad mediante 12 puertos de 10 Gigabit SFP+, y
            un puerto de consola. Los puertos SFP+ soportan transceptores de
            fibra SX/LX de 1 Gigabit para fibra multimodo o monomodo.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL310XGF12}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
