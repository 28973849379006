import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionTransceiverISFPSR10G300M.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ISFPSR10G300M from "../../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPSR10G300M/ISFPSR10G300M.png";

export function TitleSectionTransceiverISFPSR10G300M() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Transceptor grado Industrial 10G Multi Giga SFP+ MiniGBIC Hasta 300M, conectable en caliente, Auto-fall back" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="ISFP-SR-10G-300M" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={ISFPSR10G300M}
            alt="Transceptor grado Industrial 10G Multi Giga SFP+ MiniGBIC"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive ISFP-SR-10G-300M es un transceptor de fibra SFP+ de nivel
            industrial de nivel industrial de bajo consumo, alto rendimiento y
            rentable para aplicaciones de comunicación de datos ópticos en serie
            de hasta 10 Gbps y 300 M en entornos industriales. El módulo de baja
            fluctuación y alta sensibilidad está diseñado para fibra dual
            multimodo y funciona a una longitud de onda nominal de 850nm, la
            temperatura de funcionamiento más alta de -40 ° C ~ + 85 ° C hace
            que el módulo sea ideal para áreas industriales y de mayor
            temperatura, el módulo es conectable en caliente.
          </p>
          &nbsp;
          <p>
            El transceptor incorpora funciones de control TX Disabled, TX-Fault
            y monitor RX_LOS. Es un transceptor de conector LC dúplex diseñado
            para su uso en aplicaciones Gigabit Ethernet y para proporcionar un
            enlace compatible con IEEE802.3ae y un enlace compatible con la
            seguridad láser de clase I.
          </p>
          &nbsp;
          <p>
            Las aplicaciones adecuadas son tales como redes de área de
            almacenamiento de alta velocidad, conexión cruzada de clústeres de
            computadoras, tuberías de datos de alta velocidad personalizadas y
            más.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
