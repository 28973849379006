import { Container, Row, Col } from "react-bootstrap";
import styles from "./WirelessOptimization.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import CampusImplementation from "../../../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/CampusImplementation.png";
import WirelesOptimization from "../../../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/WirelessOptimization.png";

export function WirelessOptimization() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Implementación a gran escala de la aplicación en campus" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            La solución provista por el controlador inalámbrico Airlive
            proporciona un control total de los puntos de acceso, los
            controladores de acceso inalámbricos y el punto de acceso de doble
            banda 11ac / ax para interiores. La solución se puede aplicar para
            ciudades inalámbricas, campus a gran escala, hoteles y centros
            comerciales. Adicionalmente, con Airlive Cloud Service, los
            controladores de acceso pueden administrar Access Points de forma
            independiente.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={CampusImplementation}
            alt="Campus Implementation"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Optimización inalámbrica" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            La optimización inalámbrica automática del WLAN-128GM se refiere al
            uso de herramientas de software y algoritmos que ajustan y optimizan
            automáticamente la configuración de una red inalámbrica. El objetivo
            de la optimización inalámbrica automática es simplificar el proceso
            de ajustes de una red inalámbrica y garantizar que la red siempre
            funcione al máximo rendimiento.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            La optimización inalámbrica automática generalmente utiliza
            algoritmos de aprendizaje automático e inteligencia artificial para
            analizar el rendimiento de la red en tiempo real y realizar ajustes
            en su configuración según sea necesario. El software puede tener en
            cuenta varios factores, como el tráfico de red, la intensidad de la
            señal, los niveles de interferencia y la presencia de nuevos
            dispositivos o puntos de acceso, para tomar decisiones sobre cómo
            optimizar la red.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Al automatizar el proceso de optimización, la optimización
            inalámbrica automática puede ayudar a garantizar que una red
            inalámbrica siempre funcione al máximo rendimiento, sin necesidad de
            intervención manual. Esto puede mejorar la experiencia del usuario y
            aumentar la productividad, particularmente en redes grandes y
            complejas con muchos dispositivos y puntos de acceso.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={WirelesOptimization}
            alt="Wireless Optimization"
          />
        </Col>
      </Row>
    </Container>
  );
}
