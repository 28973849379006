import { Container, Row, Col } from "react-bootstrap";
import styles from "./TableGuide.module.scss";
import { HomeSubtitle } from "../../../../HomeSubtitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export function TableGuide() {
  const columns = [
    { title: "Descargar" },
    { title: "Guías de configuración" },
  ];
  const data = [
    {
      Descargar: [{ path: "https://www.hiottech.com/assets/Guia_configuracion_OLT_ONU.pdf", label: "Descargar" }],
      Dispositivos: "Guía de configuración para OLT y ONU en la configuración predeterminada",
    },
    {
      Descargar: [{ path: "https://www.hiottech.com/assets/guia_configuracion_VLAN_Voz.pdf", label: "Descargar" }],
      Dispositivos: "Guía ejemplo de configuración Voz-VLAN switch capa 2",
    },
    /*******  TODO: en espera de Guía *****/
   /*  {
      Descargar: [{ path: "https://www.hiottech.com/assets/guia_configuracion_VLAN_Voz.pdf", label: "Descargar" }],
      Dispositivos: "Guía ejemplo de Configuración Redes VLAN Switch capa 3",
    }, */
    {
      Descargar: [{ path: "https://www.hiottech.com/assets/airCloud_platform_es02.mp4", label: "Descargar" }],
      Dispositivos: "Guía de configuración Wireless AirCloud (Video)",
    },
  ];
  return (
    <Container className="mb-5">
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Guías de configuración" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.table}>
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    {row.Descargar.map((downloadLink, linkIndex) => (
                      <div key={linkIndex} className="d-flex align-items-center justify-content-center">
                        <a href={downloadLink.path} className={styles.btn_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDownload} className={styles.icon}/>{downloadLink.label}</a>
                      </div>
                    ))}
                  </td>
                  <td>{row.Dispositivos}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
}
