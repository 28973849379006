import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightLive10TX800() {
  const TransmissionDistance = [
    {
      title: "Distancia de transmisión:",
      detail: "10Base-T: Cat3,4,5 UTP (≤250 metros) ",
    },
    {
      detail: "100Base-TX: Cat5 o posterior UTP (150 metros)",
    },
    {
      detail: "1000Base-TX: Cat5e o posterior UTP (150 metros)",
    },
    {
      detail: "10000Base-TX: Cat6a o 6e, Cat7 o posterior UTP(100 metros)",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 32 x 20,7 x 4,5 cm (L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 37 x 29 x 8,5 cm (L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: N.W: 1.72Kgs; G.W: 2.12Kgs",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x cable de alimentación, 1 x par de orejas de rack.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 5 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 44,5 x 31 x 39cm (L*W*H)",
    },
    {
      detail: "Peso: 11.6Kgs (G.W)",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo:",
    },
    {
      detail: "Live-10TX800, Conmutador Multi Gigabit de 10 Gbps, Auto-Adaptativo",
    },
    {
      detail: "100/1000M/2.5G",
    },
    {
      detail: "5G/10G RJ45",
    },
  ];
  return (
    <Container>
      <ul>
        {TransmissionDistance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
