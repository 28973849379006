import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live24GT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L24GT from "../../../../../../../../assets/imagesproduct/SwitchL2/Live24GT/L24GT.png";
import descriptionSwitchL2Live24GT from "../../../../../../../../assets/imagesproduct/SwitchL2/Live24GT/descriptionSwitchL2Live24GT.png";

export function TitleSectionL2Live24GT() {
  const Feature = [
    {
      detail: "24 puertos de alta velocidad Gigabit",
    },
    {
      detail: "Alarma de bucle",
    },
    {
      detail: "Prioridad de datos de QoS (puerto 1~8)",
    },
    {
      detail: "Modo VLAN: Seguridad mejorada",
    },
    {
      detail: "Modo extendido: hasta 250 metros",
    },
    {
      detail: "Ajuste sencillo mediante interruptor DIP",
    },
    {
      detail: "Protección contra sobretensiones/ESD",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch SOHO de 24 puertos Gigabit, VLAN, QoS, Plug and Play" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-24GT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L24GT}
            alt="Switch SOHO de 24 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Más que un switch Plug-and-Play de 24 Puertos Gigabit es un Switch VLAN con QoS y Extensión de Cable UTP hasta 250 metros" />
          <p>
            Diseñado como un switch Gigabit no administrado plug-and-play, cuyo
            precio es muy asequible. Además, proporciona funciones adicionales y
            útiles, como la seguridad de VLAN y la extensión de cable, que puede
            configurar fácilmente mediante su interruptor DIP. También soporta
            prioridad de datos de QoS y tiene una compilación de alarma de bucle
            en su interior. Es fácil de utilizar y mantener con su diseño sin
            ventilador y pequeño tamaño. Recomendado ampliamente para uso en el
            hogar, la oficina SOHO o como switch para PYMES.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live24GT}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
