import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductISFPSR10G300M.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftISFPSR10G300M } from "./SpecificationsLeftISFPSR10G300M/SpecificationsLeftISFPSR10G300M";
import { SpecificationsRightISFPSR10G300M } from "./SpecificationsRightISFPSR10G300M/SpecificationsRightISFPSR10G300M";

export function FeatureProductTransceiverISFPSR10G300M() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive ISFP-LX-1.25G-20KM" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftISFPSR10G300M />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightISFPSR10G300M />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}