import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { SidebarAboutUs } from "../../SidebarAboutUs";
import styles from "./History.module.scss";

export function History() {
  const historyDate = [
    {
      title: "2023",
      description:
        "AirLive lanza al mercado nueva línea de productos AirCloud inalámbricos para oficinas, switches de acapa 2 y capa de nueva generación  e introduce los primeros productos de su nueva línea GPON.",
    },
    {
      title: "2019",
      description: "Operación de AirLive Smart IoT Cloud",
    },
    {
      title: "2018",
      description:
        "AirLive presenta Alexa y Google Home | Asistente de soporte de voz. AirLive mejoró su Aplicación Smart Life Plus para admitir numerosas puertas de enlace AirLive Z-wave Plus, hasta 99 unidades. Eso puede importar Smart Family y Smart Building. AirLive lanzó su Smart Home IoT con funciones de operación de voz durante Computex aipei 2018. AirLive fue recomendado como Mejor hogar inteligente de IoT por Computex Taipei 2018.",
    },
    {
      title: "2017",
      description:
        "AirLive lanzó su Smart Home IoT, compatible con Z-wave Plus, durante Computex 2017. AirLive combinó adecuadamente los productos de red e IoT para el hogar inteligente para hacer la vida más fácil e inteligente",
    },
    {
      title: "2015 - 2016",
      description:
        "AirLive lanzó una serie de productos de IoT industrial que incluyen M2M con tecnología 4G LTE, conmutadores de topología automática para una gestión de redes visualmente sencilla y conmutadores industriales gestionados por anillo de recuperación rápida de redes. La serie de productos AirLive IIoT es adecuada para construir una ciudad inteligente, una fábrica inteligente y un transporte inteligente.",
    },
    {
      title: "2013 - 2014",
      description:
        "AirLive amplió su solución SMB para incluir Vigilancia de Seguridad con Rostro Tecnología avanzada de Detección o Conteo de Personas. Airlive siguió participando activamente en eventos con Global Partners.",
    },
    {
      title: "2009 - 2012",
      description:
        "AirLive siguió apoyando el programa de marketing activo con Global Partners. AirLive realizó eventos activos en América Latina que incluyeron Training days en Argentina, Road Show en varios países de América Latina, Showcase en Brasil y la participación en DISTREE Latin America 2011 en San Paulo, Brasil. AirLive Roadshow realizado en República Checa, Polonia con cientos de asistentes. AirLive Summer Training 2009 (junio de 2009) AirLive realiza capacitación en seguridad en abril para presentar nuevos productos y servicios (abril de 2009)",
    },
    {
      title: "2008",
      description:
        "AirLive obtuvo el certificado ISO-9001-2000 certificado por TUV Nord. AirLive celebró 15 años Aniversario",
    },
    {
      title: "2006 - 2007",
      description:
        "AirLive realizó un exitoso Roadshow Europeo en Europa. Programa de Relajación y Certificación para Distribuidores de AirLive. AirLive mantuvo activos los eventos de marketing incluyendo Road show en América Latina (Paraguay, Uruguay, Colombia), seminarios, exhibiciones y asistencia a DISTREE Mónaco.",
    },
    {
      title: "2005",
      description:
        "Lanzamiento de la marca registrada AirLive en todas las líneas de productos. Establecimiento del sitio web AirLive.com",
    },
    {
      title: "2004",
      description:
        "OvisLink Corp. ganó el premio Best of Computex 2004. Ovislink Corp. ingresó al mercado inalámbrico para exteriores.",
    },
    {
      title: "2002 - 2003",
      description:
        "El primer punto de acceso multimodo Wireless-G del mundo WL-1120AP I WL-5460AP se instaló y funcionó como dispositivo de comunicación a través de Internet para usuarios domésticos. La “Comunicación Inalámbrica” a través de Internet reemplazando al Módem o Teléfono se convierte en una aplicación general a partir de estos significativos años. OvisLink se siente orgulloso de ser uno de los pioneros que contribuyeron en esta importante dinastía histórica de TI.",
    },
    {
      title: "1998 - 2001",
      description: "Establecimiento de la serie AirLive",
    },
    {
      title: "1997",
      description:
        "Introducción de conmutadores Gigabit, uno de los primeros proveedores de redes en Taiwán",
    },
    {
      title: "1996",
      description:
        "Introducción del primer producto LAN Fast Ethernet en Taiwán.",
    },
    {
      title: "1993",
      description:
        "Establecimiento de la sede de OvisLink Corporation en Taiwán.",
    },
  ];
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <Row>
          <Col className="mt-4 mb-5">
            <HomeSubtitle title="Historia" />
          </Col>
        </Row>
        <Row>
          <Col className="mb-5">
            <div className={styles.maintimeline}>
              {historyDate.map((items, index) => (
                <div className={styles.timeline}>
                  <div className={index % 2 === 0 ? styles.right : styles.left}>
                    <Row className={styles.Card}>
                      <Col xs={12}>
                        <div className={styles.subTitle}>{items.title}</div>
                        <p>{items.description}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
