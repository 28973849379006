import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsAirMax5XII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftAirMax5XII } from "./SpecificationsLeftAirMax5XII";
import { SpecificationsRightAirMax5XII } from "./SpecificationsRightAirMax5XII";

export function SpecificationsAirMax5XII() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AirMax5X II" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftAirMax5XII />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightAirMax5XII />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
