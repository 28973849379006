import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function LinkstoOtherSitesSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Enlaces a otros sitios" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Nuestro Servicio puede contener enlaces a otros sitios que no son
            operados por nosotros. Si hace clic en el enlace de un tercero, será
            dirigido al sitio de ese tercero. Le recomendamos encarecidamente
            que revise la Política de privacidad de cada sitio que visite.
          </p>
          <p>
            No tenemos control ni asumimos ninguna responsabilidad por el
            contenido, las políticas de privacidad o las prácticas de los sitios
            o servicios de terceros.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
