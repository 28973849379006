import React from "react";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import { Container, Nav, Navbar } from "react-bootstrap";

export interface SidebarProps {
  title: string;
  links: LinkItem[];
}

export interface LinkItem {
  id?: string;
  name: string;
  path: string;
}

export function Sidebar(props: SidebarProps) {
  return (
    <Navbar variant="dark" expand="lg" className={styles.sidebar}>
      <Container>
        <div>
          <h2 className={styles.sidebar_title}>{props.title}</h2>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link className={styles.sidebar_links}>
                {props.links.map((link, index) => (
                  <Link
                    key={index}
                    to={link.path}
                    className={styles.sidebar_links_item}
                  >
                    {link.name}
                  </Link>
                ))}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
}
