import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH402BT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH402BT } from "./SpecificationsLeftPOEGSH402BT";
import { SpecificationsRightPOEGSH402BT } from "./SpecificationsRightPOEGSH402BT";

export function FeatureProductPOEGSH402BT() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Serie AirLive POE-GSH402BT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH402BT />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH402BT />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
