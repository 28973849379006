import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftXGS2404400() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware Interfaz del dispositivo:",
      detail: "24 puertos PoE 10/100/1000M 30W",
    },
    {
      detail: "4 puertos SFP+ 10G",
    },
    {
      detail: "1 x puerto de consola RJ45 ",
    },
    {
      detail: "1 x puerto USB",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.3:Protocolo MAC Ethernet ",
    },
    {
      detail: "IEEE 802.3i:10BASE-T Ethernet ",
    },
    {
      detail: "IEEE 802.3u:100BASE-TX Fast Ethernet ",
    },
    {
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3z:1000BASE-X Gigabit Ethernet (fibra óptica) ",
    },
    {
      detail: "IEEE 802.3ae: 10G Ethernet (fibra óptica)",
    },
    {
      detail: "IEEE 802.3az:Ethernet de eficiencia energética",
    },
    {
      detail:
        "IEEE 802.3ad:Método estándar para realizar la agregación de vínculos ",
    },
    {
      detail: "IEEE 802.3x:Control de flujo",
    },
    {
      detail: "IEEE 802.1ab: LLDP/LLDP-MED (Link Layer Discovery Protocol)",
    },
    {
      detail:
        "IEEE 802.1p:Priorización de tráfico del protocolo QoS/CoS de capa LAN (función de filtrado de multidifusión)",
    },
    {
      detail: "IEEE 802.1q:Operación del puente VLAN",
    },
    {
      detail:
        "IEEE 802.1x:Protocolo de autenticación y control de acceso cliente/servidor ",
    },
    {
      detail: "IEEE 802.1d:STP",
    },
    {
      detail: "IEEE 802.1s:MSTP",
    },
    {
      detail: "IEEE 802.3ah:OAM",
    },
    {
      detail: "IEEE 802.1w:RSTP",
    },
    {
      detail: "IEEE 802.3af(15.4W)",
    },
    {
      detail: "IEEE 802.3at (30W)",
    },
    {
      detail: "IEEE 802.3bt (90W)",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail:
        "PWR (indicador de encendido), SYS (luces del sistema), enlace (luz de enlace).",
    },
    {
      detail: "PoE(luz),Act(luz de datos).",
    },
  ];
  const SurgeProtection = [
    {
      title: "Protección contra sobretensiones de iluminación:",
      detail: "Modo común≥6KV, ESD: Contacto≥6KV.  Aire≥8KV.",
    },
  ];
  const Mechanic = [
    {
      title: "Mecánico:",
      detail: "Metal sólido 19 1U montable en rack, IP30",
    },
  ];
  const PowerPoEProtocol = [
    {
      title: "Alimentación y PoE Protocolo:",
      detail: "IEEE802.3bt (90W, IEEE802.3at (30W), IEEE802.3af (15.4W)",
    },
  ];
  const PoEPowerOutput = [
    {
      title: "Salida de potencia PoE / Puerto PoE:",
      detail: "máx. 30 vatios",
    },
  ];
  const PoEPowerOutputSwitch = [
    {
      title: "Potencia de salida PoE / PoE del Switch:",
      detail: "máx. 400 vatios",
    },
  ];
  const PowerInput = [
    {
      title: "Entrada de energía:",
      detail: "AC100 ~ 240VAC",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <SubTitleProduct content="Puerto PoE: 24" />
      <ul>
        {PowerPoEProtocol.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoEPowerOutput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoEPowerOutputSwitch.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
