import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./CoreValueSection.module.scss";
import AirLiveValues from "../../../../../assets/images/AirLiveValues.png";

export function CoreValueSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5 mt-5">
          <HomeSubtitle title="Valores AirLive" />
        </Col>
        <Col xs={12}>
          <p>
            AirLive se sustenta en fuertes y duraderos valores que hacen posible
            la creación de valor más que como equipo, como una familia,
            partiendo de su lema inicial “Ideas para la Vida” que hacen posible
            la producción de tecnología para llevarla a nuestros clientes a
            través de una red de alianzas que se sustentan en la absoluta
            confianza. Mediante estos valores primordiales se hace exitoso los
            procesos subsiguientes de creación de productos prácticos y potentes
            para cada solución, su mercadeo y el soporte al cliente final,
            constituyéndose así una asociación a largo plazo.
          </p>
          <img className={styles.image} src={AirLiveValues} alt="Smart Home" />
        </Col>
      </Row>
    </Container>
  );
}
