import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductConverterMC10GSFP.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftMC10GSFP } from "./SpecificationsLeftMC10GSFP/SpecificationsLeftMC10GSFP";
import { SpecificationsRightMC10GSFP } from "./SpecificationsRightMC10GSFP/SpecificationsRightMC10GSFP";

export function FeatureProductConverterMC10GSFP() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive MC-10GSFP+" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftMC10GSFP />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightMC10GSFP />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}
