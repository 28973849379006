import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsIGM642POE125G.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftIGM642POE125G } from "./TechnicalLeftIGM642POE125G";
import { TechnicalRightIGM642POE125G } from "./TechnicalRightIGM642POE125G/TechnicalRightIGM642POE125G";

export function TechnicalspecificationsIGM642POE125G() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AirLive IGM-642POE-1.25G" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <TechnicalLeftIGM642POE125G />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <TechnicalRightIGM642POE125G />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
