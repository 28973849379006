import { LinkItem, Sidebar } from "../../../../Sidebar";

export function SidebarAboutUs() {
    const links: LinkItem[] = [
      {
        name: "EMPRESA",
        path: "/AboutUs",
      },
      {
        name: "HISTORIA",
        path: "/AboutUs/History",
      },
      {
        name: "MARCA COMERCIAL",
        path: "/AboutUs/TradeMark",
      },
      {
        name: "GARANTÍA & RMA",
        path: "/AboutUs/WarrantyRma",
      },
      // TODO: pendiente para proximas fases 
      // {
      //   name: "DONDE COMPRAR",
      //   path: "#",
      // },
      {
        name: "POLÍTICA DE PRIVACIDAD",
        path: "/AboutUs/PrivacyPolicy",
      },
      // TODO: pendiente para proximas fases
      // {
      //   name: "CONTÁCTENOS",
      //   path: "#",
      // },
    ];
  
    return (
        <Sidebar title="Nosotros" links={links} />
    );
  }
  