import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsSNMPGSH802M.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftSNMPGSH802M } from "./TechnicalLeftSNMPGSH802M";
import { TechnicalRightSNMPGSH802M } from "./TechnicalRightSNMPGSH802M";

export function TechnicalspecificationsSNMPGSH802M() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive SNMP-GSH802M" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalLeftSNMPGSH802M />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalRightSNMPGSH802M />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
