import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./ProfileSection.module.scss";
import bannerAirlive from "../../../../../assets/images/bannerAirlive.png";
import dunsCertified from "../../../../../assets/images/dunsCertified.png";

export function ProfileSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-5 mt-4">
          <HomeSubtitle title="Empresa" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.imageBanner}
            src={bannerAirlive}
            alt="Banner Company"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-5">
          <HomeSubtitle title="Corporación de tecnología AirLive®" />
        </Col>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>Perfil Corporativo:</div>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Con más de dos décadas de acumulada experiencia en el desarrollo,
            fabricación y mercadeo de productos de tecnología de redes y
            comunicaciones, desde sus inicios como Ovislink Corp., AirLive
            Technology Corporation Corp. continúa pisando firme con su presencia
            en el mercado tecnológico.
          </p>
          &nbsp;
          <p>
            El amplio portafolio de soluciones de marca AirLive abarca una serie
            de productos orientados a satisfacer las necesidades de nuestros
            clientes con productos de calidad, operatividad de alto rendimiento
            y excelentes costos de venta al cliente final.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <p>
            AirLive®Technology proporciona Innovación, Soluciones, Confiabilidad
            y Tecnología.
          </p>
          <img
            className={styles.imageCertified}
            src={dunsCertified}
            alt="Duns Certified"
          />
        </Col>
      </Row>
    </Container>
  );
}
