import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsXGS4804.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalspecificationsLeftXGS4804 } from "./TechnicalspecificationsLeftXGS4804";
import { TechnicalspecificationsRightXGS4804 } from "./TechnicalspecificationsRightXGS4804";

export function TechnicalspecificationsXGS4804() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3-XGS4804" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalspecificationsLeftXGS4804 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalspecificationsRightXGS4804 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
