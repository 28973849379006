import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftAirMax5XII() {
  const MainChip = [
    {
      title: "Chip principal:",
      detail: "AR9344",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "16MB",
    },
  ];
  const RAM = [
    {
      title: "RAM:",
      detail: " 64MB",
    },
  ];
  const Standard = [
    {
      title: "Estándares:",
      detail: "IEEE802.3, IEEE 802.11n, IEEE 802.11a",
    },
  ];
  const Frequency = [
    {
      title: "Frecuencia:",
      detail: "5.150GHz-5.825GHz",
    },
  ];
  const Channel = [
    {
      title: "Canal:",
      detail: "CH36 ~ CH165",
    },
  ];
  const Modulation = [
    {
      title: "Modulación:",
      detail: "OFDM = BPSK, QPSK,16-QAM,64-QAM; DSSS = DBPSK, DQPSK, CCK",
    },
  ];
  const DataSpeed = [
    {
      title: "Velocidad de datos:",
      detail: "300Mbps",
    },
  ];
  const RFOutputPower = [
    {
      title: "Potencia de salida de RF: ",
      detail: "≤ 26dBm",
    },
  ];
  const Antenna = [
    {
      title: "Antena:",
      detail: "14dBi antena de panel de doble polarización",
    },
  ];
  const WAN = [
    {
      title: "WAN:",
      detail: "1 * 10 / 100Mbps RJ-45 puerto WAN, soporte 802.3at 48V PoE en",
    },
  ];
  const LAN = [
    {
      title: "LAN:",
      detail:
        "1 * 10 / 100Mbps RJ-45 Puerto LAN, soporte 802.3af 48V PoE salida",
    },
  ];
  const LEDDisplay = [
    {
      title: "Pantalla LED:",
      detail:
        "1 * pantalla LED con botones F (función) y S (selección), mostrar y configurar el modo de operación, canal, dirección IP, intensidad de la señal.",
    },
  ];
  const SlideSwitch = [
    {
      title: "Interruptor deslizado:",
      detail:
        "interruptor de modo de operación, host H medio, modo AP; C significa cliente, modo repetido.",
    },
  ];
  const ResetButton = [
    {
      title: "Botón de reinicio:",
      detail:
        "1 * botón de reinicio, presione 1 segundo, deslice el interruptor para cambiar el modo de operación; Presione 10 segundos, el dispositivo volverá a la configuración predeterminada de fábrica.",
    },
  ];
  const LedLight = [
    {
      title: "Luz LED:",
      detail: "Alimentación, Wi-Fi, WAN, LAN",
    },
  ];
  const EnergyConsumption = [
    {
      title: "Consumo de energía:",
      detail: "< 15W",
    },
  ];
  const WaterproofLevel = [
    {
      title: "Nivel a prueba de agua:",
      detail: "IP65",
    },
  ];
  const WirelessFirmwareFunction = [
    {
      title: "Función de firmware Inalámbrico:",
      detail: "Difusión SSID, SSID múltiple de hasta 4, Ocultar SSID",
    },
  ];
  const FiltroMAC = [
    {
      title: "Filtro MAC:",
      detail:
        "soporte while/lista negra, WiFi activado / apagado, Aceleración de multidifusión, Aislamiento del usuario, Paso de VLAN a través",
    },
  ];
  const Security = [
    {
      title: "Seguridad:",
      detail:
        "OPEN, WPA, WPA2, WPA-PSK, WPA2-PSK, Soporte de potencia de RF ajustable según el entorno, Número de acceso del usuario final limitado a 64",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Interfaces del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RAM.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Frequency.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Channel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Modulation.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DataSpeed.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RFOutputPower.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Antenna.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {LAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {LEDDisplay.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SlideSwitch.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ResetButton.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {LedLight.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {EnergyConsumption.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WaterproofLevel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessFirmwareFunction.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FiltroMAC.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Security.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
