import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftTOP12AC() {
  const MainChip = [
    {
      title: "Chip principal:",
      detail: "MTK, Dual Core MT7621AT",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "8MB",
    },
  ];
  const RAM = [
    {
      title: "RAM:",
      detail: " 128MB",
    },
  ];
  const Ethernet = [
    {
      title: "Ethernet (LAN):",
      detail: "1 x RJ45 10/100/1000mbps",
    },
  ];
  const WANPoE = [
    {
      title: "WAN (PoE):",
      detail: "1x RJ-45 10/100/1000mbps",
    },
  ];
  const WiFi = [
    {
      title: "WiFi:",
      detail: "802.11b / g / n 2T2R (2.4Ghz), 802.11ac / a / n 2T2R (5Ghz)",
    },
  ];
  const Button = [
    {
      title: "Botón:",
      detail: "uno para restablecer",
    },
  ];
  const PowerInput = [
    {
      title: "Entrada de alimentación:",
      detail: "1 x 12VDC o 1 x 48V (802.3af PoE)",
    },
  ];
  const connector = [
    {
      title: "Conector de antena:",
      detail:
        "Antena inalámbrica interna 4dBi (5Ghz), Antena inalámbrica interna 5dBi (2.4Ghz)",
    },
  ];
  const WAN = [
    {
      title: "WAN:",
      detail: "PPPoE, DHCP, IP estática, (puente (modo AP))",
    },
  ];
  const StandardWifi = [
    {
      title: "Wi-Fi Estándar:",
      detail: "IEEE 802.11a/b/g/n/ac, canales 2x2, 20/40/80 MHz",
    },
  ];
  const functions = [
    {
      title: "Funciones:",
      detail:
        "Multi-SSID 8 (4 por radio), SSID oculto, aislamiento SSID, dirección de banda, potencia de RF ajustable, tiempo de encendido / apagado de Wi-Fi para ahorrar energía",
    },
  ];
  const Security = [
    {
      title: "Seguridad:",
      detail: "WEP, WPA, WPA2, WPA-PSK, WPA2-PSK",
    },
  ];
  const Modulation = [
    {
      title: "Modulación:",
      detail: "ODFM, DSSS",
    },
  ];
  const ContinuousRoaming = [
    {
      title: "Itinerancia continua:",
      detail: "802.11k, 802.11v, 802.11r",
    },
  ];
  const AdvancedWifi = [
    {
      title: "Wi-Fi Avanzado:",
      detail: "aislamiento de clientes inalámbricos, Canal inalámbrico",
    },
  ];
  const WirelessChannel = [
    {
      title: "Canal inalámbrico",
      detail:
        "2.4G: 1 ~ 13 (canal CE) (depende de la región), 5.8G: 36 ~ 48, 36 ~ 64,100 ~ 140,149 ~ 165 (depende de la región), 5.8G: 36 ~ 48, 36 ~ 64,100 ~ 140,149 ~ 165 (depende de la región)",
    },
  ];
  const WirelessSpeed = [
    {
      title: "Velocidad inalámbrica:",
      detail: "1200Mbps Wireless High Speed (300Mbps + 900Mbps)",
    },
  ];
  const WirelessTXPower2_4G = [
    {
      title: "Alimentación TX inalámbrica - 2.4G:",
      detail: "<20dbm, 5.8G: <23dbm",
    },
  ];
  const SeamlessWirelessRoamingTechnology = [
    {
      title: "Tecnología de itinerancia inalámbrica sin interrupciones",
      detail:
        "una combinación de tecnologías, incluyendo Fast BSS Transition (FT), 802.11r y 802.11k. Estas tecnologías trabajan juntas para permitir que los dispositivos cambien rápida y sin problemas entre puntos de acceso sin interrumpir la conexión ni requerir que el usuario se vuelva a conectar manualmente.",
    },
  ];
  const StandardWifi802_11r = [
    {
      title: "802.11r:",
      detail:
        "facilita los traspasos rápidos mediante la autenticación previa de dispositivos con el siguiente punto de acceso. ",
    },
  ];
  const StandardWifi802_11k = [
    {
      title: "802.11k:",
      detail:
        "proporciona una mejor información de señal para las decisiones del dispositivo en qué punto de acceso conectarse.",
    },
  ];
  const StandardWifi802_11v = [
    {
      title: "802.11v:",
      detail:
        "ofrece información de QoS para la selección de dispositivos de los mejores punto de acceso en función de sus necesidades.",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Interfaces del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RAM.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Ethernet.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WANPoE.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WiFi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Button.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {connector.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {functions.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Security.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Modulation.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ContinuousRoaming.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AdvancedWifi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessChannel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessSpeed.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessTXPower2_4G.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SeamlessWirelessRoamingTechnology.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11r.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11k.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11v.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
