import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftISFPLX125G20KM() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware:",
      detail: "Voltage de la Fuente de Alimentación: 3.15V ~ 3.45V",
    },
    {
      detail: "Voltage típico de la Fuente: 3.3V",
    },
  ];
  const Standards = [
    {
      title: "Estándares:",
      detail: "Compatible con IEEE 802.3Z",
    },
    {
      detail: "Cumple con el diagnóstico digital SFF-8472",
    },
    {
      detail: "Cumple con IEC-60825",
    },
    {
      detail: "Soporte DDM",
    },
    {
      detail: "Protección ESD",
    },
    {
      detail: "Corriente de suministro total: 300mA",
    },
  ];
  const DataSpeed = [
    {
      title: "Velocidad de datos:",
      detail: "1,25 Gbps",
    },
  ];
  const TotalSupplyCurrent  = [
    {
      title: "Disipación de potencia:",
      detail: "Máx. 1,0 W",
    },
    {
      title: "Transmisor (Eléctrico - Óptico) Longitud de onda central:",
      detail: "- Mín.: 1260nm, Típico: 1310nm, Máx.: 1360nm",
    },
    {
      title: "Potencia de salida media:",
      detail: "-9, Max -3dBm",
    },
    {
      title: "Relación de extinción:",
      detail: "Min: 8dB",
    },
    {
      title: "Velocidad de datos operativos:",
      detail: " 1.25Gbps",
    },
    {
      title: "Diferencial de entrada Tx. Voltaje:",
      detail: "Mín. 500 Mv, Máx. 2400 mV",
    },
    {
      title: "Voltaje de salida de falla Tx - Afirmar:",
      detail: "Mín. 2.0V, Vcc máx.",
    },
    {
      title: "Voltaje de salida de falla Tx - De-Assert:",
      detail: "Mín. 0V, Máx. 0.8V",
    },
    {
      title: "Receptor -óptico, eléctrico, Longitud de onda central:",
      detail: "Mín. 1260nm, Máx. 1620nm",
    },
    {
      title: "Sensibilidad de recepción en potencia media:",
      detail: "Máx. -22dBm, Los Assert: -36dBm, Los De-Assert: -23dBm, Los histéresis: Mín. 0.5 Máx. 5",
    },
    {
      title: "Sobrecarga del receptor: ",
      detail: "-3dBm",
    },
    {
      title: "Voltaje diferencial de oscilación de entrada de datos: ",
      detail: "Mín. 370mV, Máx. 2000mV",
    },
  ];
  
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DataSpeed.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {TotalSupplyCurrent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      
    </Container>
  );
}
