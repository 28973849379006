import { LinkItem, Sidebar } from "../../../../Sidebar";

export function SidebarProducts() {
    const links: LinkItem[] = [
      {
        id: '1',
        name: "Redes Inalámbricas AirCloud",
        path: "/Products/WirelessNetworksAirLiveCloud",
      },
      {
        id: '2',
        name: "Switches Capa 3",
        path: "/Products/Layer3ManagedSwitches",
      },
      {
        id: '3',
        name: "Switches Capa 2",
        path: "/Products/Layer2ManagedSwitches",
      },
      {
        id: '4',
        name: "Switches Industriales",
        path: "/Products/IndustrialSwitches",
      },
      {
        id: '5',
        name: "Telecom XGPON Cloud",
        path: "/Products/TelecomXGPONCloud",
      },
      {
        id: '6',
        name: "Adaptadores y Accesorios",
        path: "/Products/FiberAdapters",
      },
    ];
  
    return (
        <Sidebar title="Productos" links={links} />
    );
  }
  