import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live5GTII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L5GTII from "../../../../../../../../assets/imagesproduct/SwitchL2/Live5GTII/L5GTII.png";
import descriptionSwitchL2Live5GTII from "../../../../../../../../assets/imagesproduct/SwitchL2/Live5GTII/descriptionSwitchL2Live5GTII.png";

export function TitleSectionL2Live5GTII() {
  const Feature = [
    {
      detail: "5 puertos, cada puerto ofrece alta velocidad Gigabit",
    },
    {
      detail: "Peso ligero",
    },
    {
      detail: "Plug & Play",
    },
    {
      detail: "Control de flujo",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit de 5 puertos SOHO, Plug and Play" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-5GT-II" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L5GTII}
            alt="Switch SOHO de 5 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit de 5 puertos Plug-and-Play" />
          <p>
            Diseñado como un switch Gigabit no administrado plug-and-play, con
            precio muy asequible. Además, el este switch Gigabit es fácil de
            utilizar y mantener con su diseño sin ventilador y tamaño pequeño;
            el producto se puede usar ampliamente en el hogar, la oficina SOHO o
            como switch de cliente para PYMES.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live5GTII}
            alt="Description Switch"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
