import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftPOEGSH800120BT({content}: {content: string | undefined;}) {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "8 x 30W PoE 10/100/1000 RJ45, Puerto # 1 ~ 2 hasta 60W" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3at/af/bt PoE" 
        },
        {
            detail: "IEEE802.3x" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-4 (EFT), EN61000-4-5 (Surge)" 
        },
        {
            detail: "Choque: IEC 60068-2-27" 
        },
        {
            detail: "Caída libre: IEC 60068-2-32" 
        },
        {
            detail: "Vibración: IEC 60068-2-6 " 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación) Modo (DIP): VLAN, predeterminado, extender Enlace y datos " 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Metal" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3bt (60W), IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 8" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 60 vatios" 
        },
        {
            detail: "(puerto # 1 ~ 2) y máx. 30 W (puerto 3 ~ 8)" 
        },
    ];
    const PoESwitchPower = [
        {
            title:"Potencia PoE / switch:",
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "4KV (sobretensión), 8KV (EDS)" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "Medios de fibra: Fibra multimodo: 850nm, 1310nm" 
        },
        {
            detail: "Distancia de transmisión: 550m / 2km" 
        },
        {
            detail: "Fibra monomodo: 1310nm, 1550nm " 
        },
        {
            detail: "Distancia de transmisión 20/40/60/80/km" 
        },
        {
            detail: "Arquitectura de conmutación: Almacenar y reenviar" 
        },
        {
            detail: "Capacidad del switch: Back-plan de hasta 16 Gbps" 
        },
        {
            detail: "Rendimiento: 11,9 Mpps" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Búfer de paquetes: 1Mb, Jumbo Frame: 9216bytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoESwitchPower.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{content}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
