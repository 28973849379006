import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";

export function FiberBackbonePoETechnology({
  content,
  image,
  image2,
}: {
  content: string | undefined;
  image: string | undefined;
  image2: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Tecnología PoE eficiente BT Hi Power y AT PoE. " />
          <p>
            Conecte varios dispositivos PoE al switch, el puerto 1 del switch
            puede alimentar dispositivos de hasta 60W. 60W puede ser utilizado
            por cámaras IP de alta potencia o por PoE Led, clientes ligeros.
            Dándole mucha más flexibilidad en el uso, ya que ya no está
            restringido a solo 30W. Otros dispositivos, como puntos de acceso o
            cámaras IP normales, pueden conectarse a cualquiera de los puertos
            PoE del switch y encender hasta un máximo de 30 W (at).
          </p>
        </Col>
        <Col xs={8} className="mb-5 text-align-center">
          <img className="w-100" src={image} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="VLAN y modo extendido" />
          <p>
            El interruptor {content} se puede configurar en modo Extender
            para alcanzar una distancia de hasta 250 m, esto puede ser muy útil
            en lugares donde es difícil obtener energía para encender su
            dispositivo PD. El modo Extender lo hace más fácil. El modo Extender
            es solo para los puertos 3 y 4 del switch y, cuando esté habilitado,
            la velocidad de transmisión será de 10 Mbps. Modo VLAN: Cuando el
            modo de aislamiento de puertos está habilitado, los puertos PoE (1 ~
            4) no pueden comunicarse entre sí y solo pueden comunicarse con el
            puerto UP-Link.
          </p>
          &nbsp;
          <SubTitleProduct content="Backbone de fibra para larga distancia e interconexión entre pisos" />
          <p>
            Amplíe aún más la red con la ranura SFP. Utilice el puerto de fibra
            {content} para conectar diferentes pisos en un edificio grande o
            con la oficina principal a una distancia más larga. Dependiendo del
            módulo utilizado, puede alcanzar hasta 20KM de velocidad de
            transmisión de datos de 1250Mbps.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img className="w-100" src={image2} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}
