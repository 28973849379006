import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionConverterMC10GSFP.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import MC10GSFP from "../../../../../../../../assets/imagesproduct/Accessories/ConverterMC10GSFP/MC10GSFP.png";
import TypicalApplication from "../../../../../../../../assets/imagesproduct/Accessories/TypicalApplication.png";

export function TitleSectionConverterMC10GSFP() {
  const Feature = [
    {
      detail: "1 x 10G/5G/2.5G/1G/100BASE-T RJ45 Auto-fallback",
    },
    {
      detail: "Auto-MDI/MDI-X, auto-negociación",
    },
    {
      detail: "Puerto de ranura de fibra SFP+ 10GBASE-X R",
    },
    {
      detail: "Admite fibra dúplex completo monomodo o multimodo",
    },
    {
      detail: "10GBASE-LR/SR",
    },
    {
      detail: "Soporte SR Multi-modo 850nm",
    },
    {
      detail: "Soporte LR monomodo 1310nm",
    },
    {
      detail: "Soporte ITUT prescrito DWDM / CWDM longitud de onda",
    },
    {
      detail: "Función DMI de soporte para el módulo SFP +",
    },
    {
      detail: "Bajo consumo de energía < 9.0W",
    },
    {
      detail: "Puerto FX intercambiable en caliente",
    },
    {
      detail: "Función de prueba de bucle invertido de soporte",
    },
  ];
  const NotableSpecifications = [
    {
      detail: "Entorno de aplicaciones típicas",
    },
    {
      detail: "Infraestructura de redes para:",
    },
    {
      detail: "Banca",
    },
    {
      detail: "Aeropuerto",
    },
    {
      detail: "Hotel",
    },
    {
      detail: "Autopista",
    },
    {
      detail: "Industria petroquímica",
    },
    {
      detail: "Metro",
    },
    {
      detail: "Fábrica",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="MC-10GSFP+: Multi Giga Network Fiber Media Converter" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="MC-10GSFP+" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={MC10GSFP}
            alt="MC-10GSFP+: Multi Giga Network Fiber Media Converter"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Información General" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive MC-10GSFP+ Multi Giga Network Fiber Media Converter
            proporciona conversión de medios Ethernet de 10 Gbps entre SFP+ a
            UTP. Le ofrece la mejor y más sencilla solución para la conversión
            de Ethernet entre cable de cobre y fibra, con la velocidad de 10G,
            auto-fallback. Permite a los usuarios ampliar la distancia de
            comunicación. Conecte diferentes oficinas o fábricas a través de una
            distancia más larga mediante una conexión de fibra de alta
            velocidad. El MC-10GSFP+ de tamaño compacto tiene un diseño
            independiente con una ranura intercambiable en caliente FX
            plug-and-play para una fácil instalación y ahorro de espacio.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Aplicaciones" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={5} className="mb-5">
          <ul>
            {NotableSpecifications.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={8} className="mb-3">
          <img
            className={styles.image}
            src={TypicalApplication}
            alt="Aplicación Típica"
          />
        </Col>
        <SubTitleProduct content="Aplicación Típica: Interconexión de dos complejos industriales vía fibra óptica" />
      </Row>
    </Container>
  );
}
