import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import { useRef } from "react";
import styles from "./VideoSection.module.scss";

export function VideoSection() {
    const videoRef = useRef(null);
    return (
        <Container>
            <Row>
                <Col xs={12} className="mb-5">
                    <HomeSubtitle title="Vídeo de la empresa AirLive®" />
                </Col>
                <Col xs={12} className="mb-5">
                    <div>
                    <video
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        className={styles.video}
                        controls
                    >
                        <source
                        src="https://www.hiottech.com/assets/computex2023.mp4"
                        type="video/mp4"
                        />
                    </video>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
