import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightTOP12AC() {
  const FilterRule = [
    {
      title: "Regla de filtro:",
      detail: "filtro de URL / filtro de Mac / filtro IP",
    },
  ];
  const FilterURLMACIP = [
    {
      title: "Filtrado URL/MAC/IP:",
      detail: "Lista blanca/Lista negra.",
    },
  ];
  const DMZ = [
    {
      title: "DMZ",
      detail: "DMZ",
    },
  ];
  const Cloud = [
    {
      title: "Cloud:",
      detail: "Soporte de acceso a AirCloud en modo Gateway/AP, Modo de operación FIT/FAT",
    },
  ];
  const PortForwarding = [
    {
      title: "Port Forwarding /Regla de reenvío: ",
      detail: "TCP/UDP",
    },
  ];
  const VLAN = [
    {
      title: "VLAN: ",
      detail: "soporta VLAN SSID Max 4 por Banda",
    },
  ];
  const FitMode = [
    {
      title: "Modo FIT:",
      detail: "AP funciona con controlador de CA (Enterprise)",
    },
  ];
  const FatMode = [
    {
      title: "Modo FAT:",
      detail: "AP funciona de forma independiente (uso doméstico)",
    },
  ];
  const Administration = [
    {
      title: "Administración",
      detail: "Web-UI, gestión remota, controlador WLAN, sistema de gestión en la nube AirCloud",
    },
  ];
  const DDNSRelay = [
    {
      title: "DDNS relay - Máx. Usuarios simultáneos:",
      detail: "80",
    },
  ];
  const ParentalControl = [
    {
      title: "Control parental (modo puerta de enlace):",
      detail: "Filtrado de direcciones Mac, filtrado de URL, filtrado de IP",
    },
  ];
  const AP = [
    {
      title: "Modo de puerta de enlace AP/AP - AP:",
      detail: "En este modo, la interfaz inalámbrica AP y la interfaz de cable se están uniendo. Sin NAT, Firewall y todas las funciones relacionadas con la red.",
    },
  ];
  const APGateway = [
    {
      title: "AP Gateway:",
      detail: "En este modo, la página WAN está habilitada y se puede seleccionar PPPoE, DHCP o IP estática. NAT está habilitado y las PC en los puertos LAN comparten la IP con el ISP a través del puerto WAN.",
    },
  ];
  const VPNPassThrough = [
    {
      title: "VPN Pass Through (modo de puerta de enlace):",
      detail: "IPsec, PPTP, L2TP",
    },
  ];
  const DataStatistics = [
    {
      title: "Estadísticas de datos:",
      detail: "WAN Down Stream, WAN UP Stream, Analizador Wi-Fi (2.4 y 5Ghz)",
    },
  ];
  const Environment = [
    {
      title: "Multilingüe Inglés/Español - Protección ESD ±6KV - Medio ambiente ",
      detail: "Temperatura de funcionamiento: -20 °C ~ +45 °C - Temperatura de almacenamiento: -40 °C ~ +70 °C - Humedad de almacenamiento: 5% ~ 95% (sin condensación)",
    },
  ];
  const Case = [
    {
      title: "Carcasa:",
      detail: "material ABS resistente al calentamiento",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar Tamaño del producto:",
      detail: "16.8 x 16.8 x 3.2 cm (L * W * H), 0.65kgs",
    },
  ];
  const PackageSize = [
    {
      title: "Tamaño del paquete:",
      detail: "24.5 x 23.5 x 7.5 cm (L * W * H)",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail: "1 dispositivo",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "AirLive AirCloud TOP12AC Punto de acceso PoE Gigabit 11ac 1200Mbps, Gestión central y AirCloud, roaming sin interrupciones.",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Seguridad" />
      </Col>
      <ul>
        {FilterRule.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FilterURLMACIP.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DMZ.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Cloud.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PortForwarding.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FitMode.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FatMode.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Administration.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DDNSRelay.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ParentalControl.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AP.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {APGateway.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {VPNPassThrough.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DataStatistics.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Case.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageSize.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
