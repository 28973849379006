import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./ConverterMC1GSFP.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionConverterMC1GSFP } from "./TitleSectionConverterMC1GSFP";
import { FeatureProductConverterMC1GSFP } from "./FeatureProductConverterMC1GSFP";

export function ConverterMC1GSFP() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionConverterMC1GSFP />
        <FeatureProductConverterMC1GSFP />
      </Container>
    </div>
  );
}
