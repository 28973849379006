import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import logo_airlive from "../../assets/images/logo-airlive.svg";
import logo_airlive_3 from "../../assets/images/logo-airlive-3.svg";
import ic_twitter_x from "../../assets/images/ic_twitter_x.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWhatsapp,
  faLinkedin,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <Container fluid className={styles.footer}>
      <Container>
        <Row>
          <Col xs={12} md className="d-flex justify-content-center">
            <img
              src={logo_airlive}
              className={styles.logo_airline}
              alt="logo_airlive"
            />
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>AirLive map</h2>
            <Navbar>
              <ul className={styles.list_link}>
                <li>
                  <Nav.Link href="/" className={styles.link_custom}>
                    Inicio
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Products/WirelessNetworksAirLiveCloud" className={styles.link_custom}>
                    Productos
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Soluciones
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Marketing" className={styles.link_custom}>
                    Marketing
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/AboutUs" className={styles.link_custom}>
                    Nosotros
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Drivers" className={styles.link_custom}>
                    Soporte
                  </Nav.Link>
                </li>
              </ul>
            </Navbar>
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>Soluciones</h2>
            <Navbar>
              <ul className={styles.list_link}>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Switches industriales
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Soluciones inalámbricas
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Administración inteligente
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Red de distribuidores
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Soluciones GPON <br /> Conectividad FTTX
                  </Nav.Link>
                </li>
              </ul>
            </Navbar>
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>Marketing</h2>
            <Navbar>
              <ul className={styles.list_link}>
                <li>
                  <Nav.Link href="/Marketing" className={styles.link_custom}>
                    Listado de productos
                  </Nav.Link>
                </li>
              </ul>
            </Navbar>
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>Social</h2>
            <div className={styles.social_group}>
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/5491123902158"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} />
              </a>
              <a
                href="https://www.linkedin.com/in/airlive-technology-corp-89071725a/recent-activity/all/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/airlivelat/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100087758243229"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
              </a>

              <a
                href="https://twitter.com/AirtLiveLatam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ic_twitter_x}
                  className={styles.social_logo}
                  alt="ic_twitter_x"
                />
              </a>
            </div>
            <img
              src={logo_airlive_3}
              className={styles.logo_airline_3}
              alt="logo_airlive_3"
            />
            <div className={styles.info_contacts}>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                airlivetalat@gmail.com
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={styles.copyRight}>
        <Row>
          <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <span>Copyright © 2022 </span>
            <span>AirLive Technology Corporation. </span>
            <span>Todos los derechos reservados.</span>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
