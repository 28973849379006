import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleWWLAN64GMlSection } from "./TitleWWLAN64GMlSection";
import { WirelessOptimizationWLAN64GMl } from "./WirelessOptimizationWLAN64GMl";
import { RoamingWifiWLAN128GM } from "../WLAN128GM/RoamingWifiWLAN128GM";
import { SpecificationsWLAN64GMl } from "./SpecificationsWLAN64GMl";
import { TechnicalspecificationsWLAN64GMl } from "./TechnicalspecificationsWLAN64GMl";
import styles from "./WLAN64GMl.module.scss";

export function WLAN64GMl() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleWWLAN64GMlSection />
           <WirelessOptimizationWLAN64GMl />
           <RoamingWifiWLAN128GM />
           <SpecificationsWLAN64GMl />
           <TechnicalspecificationsWLAN64GMl />
        </Container>
    </div>
  );
}
