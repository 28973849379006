import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleWLAN128GMSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopWLAN128GM from "../../../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/TopWLAN128GM.png";
import CentralizedAdministrationWLAN128GM from "../../../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/CentralizedAdministrationWLAN128GM.png";

export function TitleWLAN128GMSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Controlador Inalámbrico & Gateway" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="WLAN-128GM" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopWLAN128GM}
            alt="Controlador Inalámbrico & Gateway"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Administración centralizada de APs y gestión de LAN" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El WLAN-128GM está diseñado para proporcionar a los ISPs
            inalámbricos y empresas una solución que funciona como una solución
            de planificación, implementación, monitoreo y mantenimiento de
            puntos de acceso al ofrecer administración, autenticación y acceso
            de invitados dentro de un dispositivo alineado. Proporciona alta
            eficiencia y conectividad administrada de red profesional para
            hoteles, empresas, bares de Internet, centros comerciales. Además,
            puede administrar de forma rápida y estable el punto de acceso de
            forma centralizada y remota, proporcionar una solución completa de
            cobertura inalámbrica, conveniente y práctica.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={CentralizedAdministrationWLAN128GM}
            alt="Centralized Administration"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades resaltantes" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <SubTitleProduct content="Gestión a nivel L2/L3 de APs" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <SubTitleProduct content="Alta escalabilidad:" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Configuración por lote o única: WLAN-128GM detecta automáticamente
            el punto de acceso inalámbrico, el grupo de soporte o la
            configuración única, central para administrar la contraseña del
            SSID, el modo, el canal, la alimentación de RF, el umbral de
            cobertura; Puede reiniciar, restablecer o actualizar el firmware de
            forma remota de os Aps para completar el mantenimiento.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <p>
            El WLAN-128GM admite la gestión de los puntos de acceso para
            proporcionar funciones LAN inalámbricas completas para el negocio.
            Proporciona escalabilidad hasta el total máximo admitido de hasta
            128 AP. El objeto del del WLAN-128GM es brindar tranquilidad y
            soportar el crecimiento para que las PYMES, hoteles e institutos
            educativos crezcan en la implementación de redes LAN inalámbricas
            seguras y centralizadas.
          </p>
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="Optimizador inalámbrico automático:" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El WLAN-128GM es compatible con Wireless Optimizer para simplificar
            la planificación, implementación y gestión de WiFi empresarial sin
            costosas herramientas de inspección in situ y sensores adicionales o
            servidores de ubicación.
          </p>
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="Client Steering: " />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Client Steering establece 5 GHz como prioridad para que los
            dispositivos de doble banda superen la carga pesada en 2.4 GHz,
            mientras que el umbral de señal del cliente transfiere dispositivos
            a AP con señal más fuerte. Con estas dos funciones, el WLAN-128GM
            garantiza un mejor uso de los recursos de radio para proporcionar el
            máximo rendimiento de la red inalámbrica para los usuarios.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
