import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH4804M600.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH4804M600 } from "./TitleSectionL2POEGSH4804M600";
import { CharacteristicsPOEGSH4804M600 } from "./CharacteristicsPOEGSH4804M600";
import { TechnologyPoEManagement } from "../TechnologyPoEManagement";
import { VLANPolicy } from "../VLANPolicy";
import { RMONStatisticsSupport } from "../RMONStatisticsSupport";
import { FeatureProductPOEGSH4804M600 } from "./FeatureProductPOEGSH4804M600";
import Watchdog from "../../../../../../../assets/imagesproduct/SwitchL2/Watchdog.png";
import SurveillanceVLANsupport from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceVLANsupport.png";
import Longdistancefiber from "../../../../../../../assets/imagesproduct/SwitchL2/Longdistancefiber.png";

export function POEGSH4804M600() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH4804M600 />
           <CharacteristicsPOEGSH4804M600 />
           <TechnologyPoEManagement content={"POE-GSH4804M-600"} image={Watchdog} image2={SurveillanceVLANsupport} />
           <VLANPolicy />
           <RMONStatisticsSupport image={Longdistancefiber} />
           <FeatureProductPOEGSH4804M600 />
        </Container>
    </div>
  );
}
