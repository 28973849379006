import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH802120.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH802120 } from "./TitleSectionL2POEGSH802120";
import { CharacteristicsPOEGSH802120 } from "./CharacteristicsPOEGSH802120";
import { PoEBackbone } from "./PoEBackbone";
import { FeatureProductPOEGSH802120 } from "./FeatureProductPOEGSH802120";

export function POEGSH802120() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH802120 />
           <CharacteristicsPOEGSH802120 />
           <PoEBackbone/>
           <FeatureProductPOEGSH802120 />
        </Container>
    </div>
  );
}
