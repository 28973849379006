import { Container, Row, Col } from "react-bootstrap";
import styles from "./TypesApplications.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ElevatorMonitoring from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/ElevatorMonitoring.png";
import PointtomultipointInterconnections from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/PointtomultipointInterconnections.png";
import PointToPointInterconnection from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/PointToPointInterconnection.png";

export function TypesApplications() {
  const images = [
    {
      src: ElevatorMonitoring,
      alt: "Elevator Monitoring",
      title: "1. Monitor de ascensor",
    },
    {
      src: PointtomultipointInterconnections,
      alt: "Point-to-multipoint interconnections Point",
      title: "2. Aplicación de conexión punto a multipunto",
    },
    {
      src: PointToPointInterconnection,
      alt: "Point To Point Interconnection",
      title: "3. Aplicación de conexión punto a punto",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Entorno de aplicaciones múltiples" />
        </Col>
      </Row>
      <Row>
        {images.map((item) => (
          <>
            <Col xs={12} md={6}>
              <SubTitleProduct content={item.title} />
            </Col>
            <Col xs={12} md={6}>
              <img src={item.src} alt={item.alt} className={styles.img} />
            </Col>
          </>
        ))}
      </Row>
    </Container>
  );
}
