import { Container, Row, Col } from "react-bootstrap";
import styles from "./ONUsGPON.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import CATV from "../../../../../../../../assets/imagesproduct/AirLiveGPON/CATV.png";
import GPONONUCATV from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONONUCATV.png";
import XPONONU25GE from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONU25GE.png";
import XPONONU1GE from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONU1GE.png";
import XPONONUAX184P from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONUAX184P.png";
import XPONONUAC122PC from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONUAC122PC.png";
import XPONONUAC124PC from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONUAC124PC.png";

export function ONUsGPON() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8} className="mb-5">
          <img className="w-100" src={CATV} alt="Description" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="ONUs GPON" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="GPON ONU-CATV" />
          <p>
            Unpuerto PON (1490/1310nm Data interface SC/PC) Puerto OPT 1x
            (1550/1490/1310nm Optical input SC/APC) Puerto RF RG6
          </p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img className={styles.image} src={GPONONUCATV} alt="GPON ONU-CATV" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="XPON ONU-2.5GE, 1 puerto XPON +1 x 2.5GbE+ 1xGE ONT" />
          <p>Ofrece un puerto PON. 1 puerto 2.5 Gbps LAN + 1 puerto 1Ggbps</p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img
            className={styles.image}
            src={XPONONU25GE}
            alt="XPON ONU-2.5GE"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="XPON ONU-1GE" />
          <p>
            Ofrece un puerto PON doble modo Access to EPON/GPON. 1 puerto LAN 1
            GE RJ45
          </p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img className={styles.image} src={XPONONU1GE} alt="XPON ONU-1GE" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="XPON ONU-AX184P" />
          <p>
            1 puerto PON + 4GE + 1 POTS +1 USB3.0 + WiFi6 AX1800 ONT
            <ul className={styles.listItem}>
              <li>✓ 1XPON port(EPON PX20+ and GPON Class B+)</li>
              <li>✓ Puerto USB3.0 para Storage/Printer</li>
              <li>✓ SC single mode, SC/UPC connector</li>
            </ul>
          </p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img
            className={styles.image}
            src={XPONONUAX184P}
            alt="XPON ONU-AX184P"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="XPON ONU-AC122PC" />
          <p>Soporta Wifi AC 1200, 1 puerto POTS, 1 puerto CATV</p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img
            className={styles.image}
            src={XPONONUAC122PC}
            alt="XPON ONU-AC122PC"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="XPON ONU-AC124PC" />
          <p>Posee 1 puerto XPON +4 x GE +1 POTS + WiFi 5 + USB 3.0 + CATV y Wireless AC 1200 2T2R </p>
        </Col>
        <Col xs={12} md={4} className="mb-5">
          <img
            className={styles.image}
            src={XPONONUAC124PC}
            alt="XPON ONU-AC124PC"
          />
        </Col>
      </Row>
    </Container>
  );
}
