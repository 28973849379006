import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsWLAN64GMl.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftWLAN128GM } from "../../WLAN128GM/SpecificationsWLAN128GM/SpecificationsLeftWLAN128GM";
import { SpecificationsRightWLAN64GMl } from "./SpecificationsRightWLAN64GMl";

export function SpecificationsWLAN64GMl() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="WLAN-64GM  Enterprise Gateway & Wireless Controller" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftWLAN128GM />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightWLAN64GMl />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
