import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftAWOD30AXi() {
  const MainChip = [
    {
      title: "Chip principal:",
      detail: "Qualcomm IPQ5018",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "NOR 8MB + NAND 128MB",
    },
  ];
  const RAM = [
    {
      title: "RAM:",
      detail: " 512 MB",
    },
  ];
  const Standard = [
    {
      title: "Estándares:",
      detail: "IEEE802.11ax, IEEE802.11ac, IEEE802.3, IEEE 802.11n, IEEE 802.11a, 802.11b, 802.3at, 802.3az",
    },
  ];
  const Frequency = [
    {
      title: "Frecuencia:",
      detail: "5,150 GHz-5850 GHz/2,4 GHz a 2,484 GHz",
    },
  ];
  const Channel = [
    {
      title: "Canal:",
      detail: "CH36 ~ CH165 / CH1-13 (depende del país)",
    },
  ];
  const Modulation = [
    {
      title: "Modulación:",
      detail: "modulación DL/UL-OFDMA, Roaming sin interrupciones",
    },
  ];
  const DataSpeed = [
    {
      title: "Velocidad de datos:",
      detail: "3000 Mbps (2,4 Ghz 600 Mbps, 5,8 Ghz 2400 Mbps)",
    },
  ];
  const RFOutputPower = [
    {
      title: "Potencia de salida de RF: ",
      detail: "≤ 27 + 2 dBm (500 mW)",
    },
  ];
  const Antenna = [
    {
      title: "Antena:",
      detail: "Antena omnidireccional de 8dBi",
    },
  ];
  const WAN = [
    {
      title: "WAN:",
      detail: "1 * puerto WAN RJ-45 de 10/100/1000 Mbps, compatible con IEEE 802.3af 48V PoE in",
    },
  ];
  const LAN = [
    {
      title: "LAN:",
      detail:
        "1 * puerto de consola RJ-45 de 10/100/1000 Mbps",
    },
  ];
  const ResetButton = [
    {
      title: "Botón de reinicio:",
      detail:
        "1 * Botón de reinicio, presione 10 segundos, el dispositivo volverá a la configuración predeterminada de fábrica.",
    },
  ];
  const LedLight = [
    {
      title: "Luz LED:",
      detail: "SYS, WAN, 2.4G, 5.8G",
    },
  ];
  const EnergyConsumption = [
    {
      title: "Consumo de energía:",
      detail: "<24W",
    },
  ];
  const WaterproofLevel = [
    {
      title: "Nivel a prueba de agua:",
      detail: "IP67"
    },
  ];
  const FirmwareFunction = [
    {
      title: "Función de firmware:",
      detail: "Control Modo de operación: AP inalámbrico, puerta de enlace, repetidor WiFi",
    },
  ];
  const ProtocolIP = [
    {
      title: "Protocolo IP:",
      detail:
        "IP dinámico / IP estático / PPPoE / L2TP (acceso dual) / PPTP (acceso dual)",
    },
  ];
  const WirelessFeatures = [
    {
      title: "Funciones Inalámbricas:",
      detail:
        "Difusión de SSID, ocultación de SSID, Múltiples SSID hasta 8",
    },
  ];
  const Firewall = [
    {
      title: "Cortafuegos:",
      detail:
        "DoS, Cortafuegos SPI, IP, MAC, filtro URL, enlace de direcciones IP y MAC",
    },
  ];
  const WiFiTiming = [
    {
      title: "temporizado de WiFi:",
      detail:
        "Encendido/apagado, temporizado de WiFi",
    },
  ];
  const Acceleration = [
    {
      title: "Aceleración:",
      detail:
        "Aceleración de multidifusión",
    },
  ];
  const VPNPassthrough = [
    {
      title: "Paso de VPN",
      detail:
        "DDNS, reenvío de puertos, host DMZ",
    },
  ];
  const Security = [
    {
      title: "Seguridad:",
      detail:
        "OPEN, WPA, WPA2, WPA-PSK, WPA2-PSK, WPA3",
    },
  ];
  const RFsettings = [
    {
      title: "Ajustes RF:",
      detail:
        "Admite potencia de RF ajustable según el entorno",
    },
  ];
  const NumberOfUsers = [
    {
      title: "Número máximo de usuarios:",
      detail:
        "160",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Interfaces del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RAM.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Frequency.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Channel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Modulation.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DataSpeed.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RFOutputPower.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Antenna.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {LAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ResetButton.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {LedLight.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {EnergyConsumption.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WaterproofLevel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FirmwareFunction.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ProtocolIP.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessFeatures.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Firewall.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WiFiTiming.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Acceleration.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {VPNPassthrough.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Security.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RFsettings.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {NumberOfUsers.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
