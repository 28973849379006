import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightWLAN128GM() {
  const Keep = [
    {
      title: "Mantener:",
      detail: "Inicio de sesión remoto, Telnet remoto, Reinicie regularmente, Detección de conflictos DHCP",
    },
  ];
  const KeepContinue = [
    {
      detail: "Capturar paquete, Red, Protocolo, Dirección de origen, Dirección de destino, Puerto de destino, Número, Señal",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales: ",
      detail: "Temperatura de trabajo: -20 °C ~ 55 °C, Temperatura de almacenamiento: -40 °C ~ 70 °C, Humedad de trabajo: 5% ~ 97% RH (sin condensación)",
    },
  ];
  const ProductDimension = [
    {
      title: "Dimensión - Tamaño del producto:",
      detail: "Dimensiones del producto: 44x24x4.4 cm",
    },
  ];
  const ProductSize = [
    {
      title: "Tamaño del paquete:",
      detail: "50 x 28.5 x 8 cm",
    },
  ];
  const CartonPackage = [
    {
      title: "Paquete de cartón:",
      detail: "AP funciona de forma independiente",
    },
  ];
  const CartonWeight = [
    {
      title: "Peso del cartón:",
      detail: "G.W=9KGS",
    },
  ];
  const CartonSize = [
    {
      title: "Tamaño del cartón:",
      detail: "50.5 x 43.5 x 34.5 cm ",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido - Nombre del modelo:",
      detail: "AirLive WLAN-128GM Descripción: Enterprise Gateway & Wireless Controller",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Seguridad" />
      </Col>
      <ul>
        {Keep.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {KeepContinue.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ProductDimension.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ProductSize.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {CartonPackage.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {CartonWeight.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {CartonSize.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
