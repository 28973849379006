import { Container, Row, Col } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./Layer3ManagedSwitches.module.scss";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import switchL3POEXGS4804850BT from "../../../../../../assets/imagesproduct/SwitchL3/switchL3POEXGS4804850BT/switchL3POEXGS4804850BT.png";
import L3XGS2404 from "../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS2404/L3XGS2404.png";
import L3XGF28 from "../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGF28/L3XGF28.png";
import SwitchL3XGS4804 from "../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS4804/SwitchL3XGS4804.png";
import SwitchL310XGF12 from "../../../../../../assets/imagesproduct/SwitchL3/SwitchL310XGF12/SwitchL310XGF12.png";
import SwitchL3POEXGS2404400 from "../../../../../../assets/imagesproduct/SwitchL3/SwitchL3POEXGS2404400/SwitchL3POEXGS2404400.png";
import L32TX2406 from "../../../../../../assets/imagesproduct/SwitchL3/L32TX2406/L32TX2406.png";

export function Layer3ManagedSwitches() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const switchL3 = [
    {
      img: { src: L32TX2406, alt: "switch L3-2TX2406" },
      title: "switch L3-2TX2406",
      description:
        "Switch Multi Giga L3 Adminsitrable con uplink 10G con 6 Puerto 10G SFP",
      button: {
        name: "VER MÁS",
        path: "../L32TX2406",
      },
    },
    {
      img: { src: L3XGS2404, alt: "switch L3-XGS2404" },
      title: "switch L3-XGS2404",
      description:
        "Switch L3 Administrado de 24 puertos Gigabit+ con 4 puertos 10G SFP",
      button: {
        name: "VER MÁS",
        path: "../SwitchL3XGS2404",
      },
    },
    {
      img: { src: SwitchL3XGS4804, alt: "switch L3-XGS4804" },
      title: "switch L3-XGS4804",
      description:
        "Switch L3 Administrado de 48 puertos Gigabit+ con 4 puertos 10G SFP",
      button: {
        name: "VER MÁS",
        path: "../SwitchL3XGS4804",
      },
    },
  ];
  const switchL3Fibra = [
    {
      img: { src: L3XGF28, alt: "switch L3-XGF28" },
      title: "switch L3-XGF28",
      description: "Switch L3 Administrado de 28 puertos Gigabit",
      button: {
        name: "VER MÁS",
        path: "../SwitchL3XGF28",
      },
    },
    {
      img: { src: SwitchL310XGF12, alt: "switch L3-10XGF12" },
      title: "switch L3-10XGF12",
      description: "Switch L3 Administrado de 12 puertos de 10 Gigabit SFP+",
      button: {
        name: "VER MÁS",
        path: "../SwitchL310XGF12",
      },
    },
  ];
  const switchL3POE = [
    {
      img: { src: SwitchL3POEXGS2404400, alt: "switch L3POE-XGS2404-400 BT" },
      title: "switch L3POE-XGS2404-400 BT",
      description:
        "Switch L3 400W Administrado de 24 puertos Gigabit PoE+ con 4 puertos 10G SFP",
      button: {
        name: "VER MÁS",
        path: "../SwitchL3POEXGS2404400",
      },
    },
    {
      img: { src: switchL3POEXGS4804850BT, alt: "switch L3POE-XGS4804-850 BT" },
      title: "switch L3POE-XGS4804-850 BT",
      description:
        "Switch L3 850W Administrable de 48 puertos Gigabit PoE+ con 4 puertos 10G SFP",
      button: {
        name: "VER MÁS",
        path: "../SwitchL3POEXGS4804850BT",
      },
    },
  ];

  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
      <Products />
        <Row>
          <Col className="mb-5">
            <HomeSubtitle title="Switches Administrables capa 3:" />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches multigigabit L3- 10G" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {switchL3.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches de fibra -10G" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {switchL3Fibra.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches multigibagit -PoE" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {switchL3POE.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
