import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./CharacteristicsPOEGSH42060.module.scss";
import descriptionSwitchL2POEGSH42060 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH42060/descriptionSwitchL2POEGSH42060.png";

export function CharacteristicsPOEGSH42060() {
    const Feature = [
        {
          detail: "Todos los 6 puertos, cada puerto ofrece Gigabit de alta velocidad",
        },
        {
          detail: "4 x puerto PoE 10/100/1000Mbps (up to 30W) ",
        },
        {
          detail: "Amplíe la red con 2 puertos de enlace ascendente RJ-45 de 10/100/1000 Mbps",
        },
        {
          detail: "Alimentación PoE, soporta 802.3af/at PoE",
        },
        {
          detail: "Potencia interna 65W",
        },
        {
          detail: "PoE Watchdog",
        },
        {
          detail: "Modo VLAN, modo extendido: transmisión de hasta 250M en configuración de modo extendido por conmutador DIP de hardware",
        },
        {
          detail: "Protección contra sobretensiones; Protección ESD",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={7} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col xs={5} className="mb-5">
                <img
                    className={styles.image}
                    src={descriptionSwitchL2POEGSH42060}
                    alt="Description Switch"
                />
            </Col>
        </Row>
    </Container>
  );
}
