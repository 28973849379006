import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsPOEGSH800120BT() {
    const Feature = [
        {
          detail: "Todos los 8 puertos, y cada uno ofrece Gigabit de alta velocidad ",
        },
        {
          detail: "8 x puerto PoE (hasta 30W, puerto #1~2 hasta 60W)",
        },
        {
          detail: "Alimentación PoE, soporta 802.3at/802.3bt PoE+ / PoE++ ",
        },
        {
          detail: "Potencia interna 120W",
        },
        {
          detail: "Modo VLAN, modo extendido: transmisión de hasta 250M en modo extendido ",
        },
        {
          detail: "Configuración por switch DIP de hardware",
        },
        {
          detail: "Protección contra sobretensiones; Protección ESD",
        },
        {
          detail: "PoE Watchdog en modo VLAN y Extender.",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
