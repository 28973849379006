import { Col, Container, Row } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import WorkSwitch from "../../../../../../../../assets/imagesproduct/SwitchL2/WorkSwitch.png";

export function CharacteristicsLive2TX801() {
  const Feature = [
    {
      detail: "Switch AirLive Live-2TX801 de 2,5 Gbps",
    },
    {
      detail: "8 x 10/100/1000/2500Mbps RJ45",
    },
    {
      detail: "1x 10G SFP+",
    },
    {
      detail: "Todos los puertos a alta velocidad Multi Gigabit, negociación automática",
    },
    {
      detail: "Incluye 8 puertos Multi Gigabit RJ-45",
    },
    {
      detail: "Redes súper rápidas RJ-45 de 2,5 Gbps y SFP+ de 10 Gbps",
    },
    {
      detail: "Conexión extremadamente estable a velocidad Super Hyper Wire para evitar problemas de red débil",
    },
    {
      detail: "Configuración Zero",
    },
    {
      detail: "Tamaño compacto para conectividad de red doméstica o PYME",
    },
  ];
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Multi Gigabit para interconexión" />
          <p>
            Con el switch AirLive Live-2TX801 de 2,5 Gbps, es posible conectar
            varios dispositivos de alta velocidad a través de conexiones RJ-45
            utilizando un cable de red Cat.5e normal. Un mayor ancho de banda
            mejora la calidad de reproducción de videos 4K / 8K, evita el
            retraso para los juegos, suaviza la velocidad al descargar o cargar
            datos grandes, aumenta la velocidad de transferencia de datos al
            realizar copias de seguridad en una unidad NAS. Utilice el SPF+ 10G
            como enlace ascendente a una red más grande con mayor velocidad.
            Producto relacionado: AirLive USB-25G USB3.1/3.0 to 2.5Gbps Base-T
            adapter
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Características y especificaciones" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <img
          className="W-100"
          src={WorkSwitch}
          alt="Switch Multi Gigabit 2.5Gbps Base-T, plug & play Incluye 5 puertos RJ45"
        />
      </Row>
    </Container>
  );
}
