import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftGPONOLT121() {
  const PONPort = [
    {
      title: "Interfaces del dispositivo Puerto PON:",
      detail: "1x 1.25Gbps / 2.5Gbps",
    },
  ];
  const RJ45Uplink = [
    {
      title: "Enlace ascendente RJ45:",
      detail: "2x 1 GE",
    },
  ];
  const SFPUplink = [
    {
      title: "Enlace ascendente SFP+:",
      detail: "1x SFP+ (10GE)",
    },
  ];
  const ConsoleRJ45 = [
    {
      title: "Consola:",
      detail: "1 x RJ45",
    },
  ];
  const FiberType = [
    {
      title: "Tipo de fibra:",
      detail: "9/125μm SM",
    },
  ];
  const Connector = [
    {
      title: "Conector:",
      detail: "SC/UPC, Clase C++, C+++",
    },
  ];
  const NumberONUs = [
    {
      title: "Cantidad de ONUs:",
      detail: "Soportadas:  128",
    },
  ];
  const GPONPortSpeed = [
    {
      title: "Velocidad del puerto GPON:",
      detail: "Upstream 1.244Gbps, Downstream 2.488Gbps",
    },
  ];
  const Wavelength = [
    {
      title: "Longitud de onda:",
      detail: "TX 1490nm, RX 1310nm",
    },
  ];
  const MaxSplitRatio = [
    {
      title: "Relación máxima de división:",
      detail: "1:128",
    },
  ];
  const TransmissionDistance = [
    {
      title: "Distancia de transmisión:",
      detail: "20KM",
    },
  ];
  const BackplaneBandwidth = [
    {
      title: "Ancho de banda de la placa posterior (Gbps):",
      detail: "16",
    },
  ];
  const PortForwardingRate = [
    {
      title: "Velocidad de reenvío de puertos (Mpps):",
      detail: "23.808",
    },
  ];
  const ModeAdministration = [
    {
      title: "Modo de administración:",
      detail: "Console/WEB/Telnet/CLI",
    },
  ];
  const LightningProtectionLevel = [
    {
      title: "Nivel de protección contra rayos:",
      detail: "fuente de alimentación 4KV / interfaz de dispositivo 1KV",
    },
  ];
  const FirmwareFeatures = [
    {
      title: "Características del firmware Función de gestión:",
    },
    {
      detail: "Telnet, CLI, WEB",
    },
    {
      detail:
        "Supervisión del estado de los puertos y gestión de la configuración",
    },
    {
      detail: "Configuración y gestión de ONU en línea",
    },
    {
      detail: "Gestión de usuarios",
    },
    {
      detail: "Gestión de alarmas",
    },
  ];
  const Layer2Functions = [
    {
      title: "Funciones de capa 2:",
    },
    {
      detail: "1K Dirección Mac",
    },
    {
      detail: "Soporte 4096 VLAN",
    },
    {
      detail: "Soporte de puerto VLAN",
    },
    {
      detail: "Soporte VLAN tag / Un-tag, VLAN transmisión transparente",
    },
    {
      detail: "Soporte de control de tormentas basado en el puerto",
    },
    {
      detail: "Soporte de aislamiento de puertos",
    },
    {
      detail: "Admite la limitación de la velocidad del puerto",
    },
    {
      detail: "Soporte 802.1D y 802.1W",
    },
    {
      detail: "Lista de control de acceso",
    },
    {
      detail: "Control de flujo IEEE802.x",
    },
    {
      detail: "Estadísticas y seguimiento de la estabilidad portuaria",
    },
  ];
  return (
    <Container>
      <ul>
        {PONPort.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {RJ45Uplink.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SFPUplink.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {ConsoleRJ45.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {FiberType.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Connector.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {NumberONUs.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {GPONPortSpeed.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Wavelength.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MaxSplitRatio.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {TransmissionDistance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {BackplaneBandwidth.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortForwardingRate.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {ModeAdministration.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LightningProtectionLevel.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {FirmwareFeatures.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Layer2Functions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
