import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministration.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import ManagementwithAirCloud from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/ManagementwithAirCloud.png";
import ConnectionMonitoring from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/ConnectionMonitoring.png";

export function AdvancedAdministration() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Administración Avanzada" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <div className={styles.subTitle}>
            Flexible funcionalidad- Gerencia Centralizada, Gestión AirCloud o AP
            independiente
          </div>
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive TOP-18AX puede ser un AP FIT AP o FAT. Puede funcionar
            como un punto de acceso independiente o puerta de enlace para
            aplicaciones domésticas o WLAN pequeñas. Para grandes empresas u
            organizaciones, el TOP-18AX es compatible con el controlador
            inalámbrico AirLive y AirCloud con una gestión central y/o en la
            nube avanzada y eficiente.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <HomeSubtitle title="Gestión central y en la nube 24/7 365 para puntos de acceso ilimitados a través de AirCloud para grandes empresas o acceso público" />
          <div className={styles.subTitle}>Gestión con AirCloud</div>
          <p>
            El AirLive TOP-18AX se puede administrar a través de AirCloud una
            vez que se haya agregado a la nube. Esto proporciona control remoto
            desde cualquier ubicación, lo que lo hace ventajoso para grandes
            redes de puntos de acceso, ya que el acceso local no es necesario y
            el soporte se puede proporcionar desde una oficina remota. Además,
            se pueden asignar múltiples niveles de control en la nube, que van
            desde los derechos de administrador hasta los derechos de
            espectador. Los puntos de acceso se pueden agregar o eliminar
            fácilmente en cualquier momento.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={ManagementwithAirCloud}
            alt="Management with AirCloud"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>
            Administración Centralizada via Controlador inalámbrico AirLive
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El modo de ajuste del TOP-18AX permite que sea controlado por un
            controlador de acceso AirLive WLAN. El controlador WLAN administra
            todas las configuraciones inalámbricas, cifrado y otras funciones,
            sirviendo como el centro de control central para el punto de acceso
            TOP-18AX. El modo de ajuste es ideal para configuraciones de
            empresas donde se deben controlar varios puntos de acceso a la vez.
            Mediante el uso del modo FIT y un controlador de acceso WLAN, puede
            administrar todos los puntos de acceso conectados en una sola
            ubicación; El tiempo de configuración y la complejidad se pueden
            reducir considerablemente.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>
            Operación como AP standalone para el hogar y la oficina SOHO
          </div>
        </Col>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>Modo FAT</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El punto de acceso TOP-18AX funciona como un dispositivo
            independiente con todas las funciones inalámbricas y de cifrado
            controladas por si mismo. Este modo es más adecuado para
            configuraciones pequeñas como residenciales u oficinas con menos
            puntos de acceso. El modo FAT admite dos modos de operación: modo AP
            y modo AP Gateway. En el modo AP Gateway, el TOP-18AX funciona como
            una puerta de enlace y utiliza su puerto WAN que se conecta a un
            módem / enrutador a través de PPPoE, DHCP o IP estática. En el modo
            AP, el TOP-18AX se puede conectar a una red existente y trabajar sin
            las funciones del puerto WAN.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>
            Monitoreo del estado de la conexión en vivo
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Al monitorear el uso de las conexiones mediante un gráfico de
            transmisión en vivo, puede obtener información en tiempo real sobre
            la actividad de su red y tomar decisiones oportunas sobre la
            administración de sus recursos de Internet.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={ConnectionMonitoring}
            alt="Live connection status monitoring"
          />
        </Col>
      </Row>
    </Container>
  );
}
