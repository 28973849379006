import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";

export function ModeVlanQoS({
  image,
  uplinkports,
  numberports,
  numberports2,
  extendedmodenumberports,
}: {
  image: string | undefined;
  uplinkports: string | undefined;
  numberports: string | undefined;
  numberports2: string | undefined;
  extendedmodenumberports: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Modo VLAN:" />
          <p>
            Esta característica adicional le da seguridad para los usuarios
            conectados. El modo VLAN funciona como aislamiento de puertos, lo
            que significa que cuando está habilitado, los usuarios conectados a
            los puertos LAN no pueden comunicarse entre sí, sino que solo se
            comunican con los puertos UP-Link (Puerto {uplinkports}). De esta manera, hay
            una capa adicional de seguridad y los datos no se compartirán entre
            los usuarios. Esto se puede utilizar en una oficina pequeña para
            separar a los invitados de la red de la empresa o en una casa para
            separar a los inquilinos.
          </p>
          &nbsp;
          <SubTitleProduct content="Prioridad QoS:" />
          <p>
            Puertos {numberports}: El switch soporta QoS de datos, útil para una
            IPCAM o un dispositivo especial de alta prioridad.
          </p>
          &nbsp;
          <SubTitleProduct content="Modo Extendido:" />
          <p>
            La función de modo extendido permite que los datos se transmitan a
            una distancia más larga, hasta 250 metros, utilizando un cable
            RJ-45, con velocidad de 10 Mbps para el {extendedmodenumberports} configurados en
            "Modo extendido". Los puertos de enlace ascendente {numberports2} siguen
            ofreciendo velocidad Gigabit.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img className="w-100" src={image} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}
