import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsPOEGSH41160BT() {
    const Feature = [
        {
          detail: "Todos los 6 puertos, cada puerto ofrece Gigabit de alta velocidad",
        },
        {
          detail: "4 x puerto PoE (hasta 30W, puerto #1 hasta 60W), 2 x puertos de enlace ascendentes",
        },
        {
          detail: "Amplíe aún más la red con 1 ranura SFP que acepta transceptores de fibra y va más allá de los límites de Ethernet",
        },
        {
          detail: "Alimentación PoE, soporta 802.3at/802.3bt PoE+ / PoE++ ",
        },
        {
          detail: "Potencia interna 60W PoE",
        },
        {
          detail: "Modo VLAN, modo extendido: transmisión de hasta 250M en configuración de modo extendido por conmutador DIP de hardware",
        },
        {
          detail: "Protección contra sobretensiones; Protección ESD",
        },
        {
          detail: "Modelo: POE-GSH411-60-BT: 4 x PoE 10/100/1000 (Puerto # 1 hasta 60W), 1 x 1000T, 1 x 1G ranura SFP, 65W",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Funciones" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
