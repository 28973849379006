import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightPOEGSH1602M200() {
  const AdministrationSystem = [
    {
        title: "CLI:",
        detail: "Admite la administración de línea de comandos del puerto serie",
    },
    {
        title: "SSH:",
        detail: " Soporta la gestión remota SSHv1/2",
    },
    {
        title: "Telnet:",
        detail: "Admite la administración remota de Telnet",
    },
    {
        title: "WEB:",
        detail: "Soporta la configuración de capa 2, monitor de capa 2 y capa 3",
    },
    {
        title: "SNMP:",
        detail: "SNMP V1/V2/V3; trampa de soporte (ColdStart, Warmstart, LinkDown, LinkUp)",
    },
    {
        title: "RMON (gestión remota):",
        detail: "Compatible con RMON V2",
    },
    {
        title: "PoE:",
        detail: "Soporta alimentación PoE",
    },
  ];
  const OtherFunctions = [
    {
        title: "Otras funciones:",
        detail: "Admite indagación DHCP, opción 82, servidor DHCP",
    },
    {
        detail: "Soporta detección dinámica de ARP",
    },
    {
        detail: "Compatibilidad con la certificación DNS",
    },
    {
        detail: "Compatibilidad con la configuración de seguridad del puerto",
    },
    {
        detail: "Soporta el protocolo MVR",
    },
    {
        detail: "Soporta la función VCT de detección de cables",
    },
    {
        detail: "Soporta el protocolo UDLD",
    },
  ];
  const Environment = [
    {
        title: "Medio ambiente:",
        detail: "Temperatura de funcionamiento: -10 °C a +50 °C",
    },
    {
        detail: "Temperatura de almacenamiento: -40 °C a +70 °C",
    },
    {
        detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
        detail: "Humedad de almacenamiento: 5% ~ 90%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Dimensiones del switch: 44x20,4 x 4,4 cm  (L * W * H)",
    },
    {
      detail: "Dimensiones del paquete: 50 x 29 x 8,5 cm  (L * W * H)",
    },
  ];
  const PackageWeight = [
    {
      title: "Peso del paquete:",
      detail: "N.W: 2.78Kgs; G.W: 3.44Kgs",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail: "1 x Interruptor, 1 x Cable de alimentación, 1 x Cable serie",
    },
    {
      detail: "1 x Soporte de montaje 1 par",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Cantidad: 5 piezas / 1 caja de cartón ",
    },
    {
      detail: "Dimensiones: 52 x 44,5 x 31 cm",
    },
    {
      detail: "Peso del embalaje: 18,2Kgs",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "POE-GSH1602M-200",
    },
    {
      detail:
        "Nombre: L2+ Managed Gigabit PoE+ 802.3at switch, 200W 20 Ports including 2-port SFP Fiber and 2-port RJ45",
    },
  ];
  return (
    <Container>
      <ul>
        {AdministrationSystem.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OtherFunctions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageWeight.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
