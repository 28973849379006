import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionConverterMC1GSFP.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import MC1GSFP from "../../../../../../../../assets/imagesproduct/Accessories/ConverterMC1GSFP/MC1GSFP.png";
import TypicalApplication from "../../../../../../../../assets/imagesproduct/Accessories/TypicalApplication.png";

export function TitleSectionConverterMC1GSFP() {
    const Feature = [
        {
          detail: "Puerto 1000BASE-T RJ45",
        },
        {
          detail: "Puerto SFP de ranura de fibra multimode/monomodo",
        },
        {
          detail: "Conector dúplex LC",
        },
        {
          detail: "10GBASE-LR/SR",
        },
        {
          detail: "Soporte SR Multi-modo 850nm",
        },
        {
          detail: "Soporte LR monomodo 1310nm",
        },
        {
          detail: "Distancia hasta 20Km (con el minigibic SFP-LX-1.25G-20Km)",
        },
        {
          detail: "Bajo consumo de energía < 9.0W",
        },
        {
          detail: "Puerto FX intercambiable en caliente",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "Entorno de aplicaciones típicas" 
        },
        {
            detail: "Infraestructura de redes para:" 
        },
        {
            detail: "Banca" 
        },
        {
            detail: "Aeropuerto" 
        },
        {
            detail: "Hotel" 
        },
        {
            detail: "Autopista" 
        },
        {
            detail: "Industria petroquímica" 
        },
        {
            detail: "Metro" 
        },
        {
            detail: "Entorno industrial" 
        },
    ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="MC-1GSFP: Multi Giga Network Fiber Media Converter" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="MC-1GSFP" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={MC1GSFP}
            alt="MC-1GSFP: Multi Giga Network Fiber Media Converter"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Información General" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive MC-1GSFP es un convertidor de medios Gigabit full-duplex
            negociable automáticamente/multimodo para el puerto TX y el puerto
            FX. Permite a los usuarios ampliar la distancia de comunicación con
            rendimiento Gigabit a través de fibra óptica. El MC-1GSFP
            proporciona conversión de medios entre 100/1000Base-T y Gigabit
            Fiber a través de varios transceptores SFP, por ejemplo, como el
            módulo AirLive SPF-LX-1.25G-20KM. Conecte diferentes oficinas o
            fábricas a una distancia más larga utilizando fibra. El MC-1GSFP
            compacto tiene un diseño independiente con plug-and-play para una
            fácil instalación y ahorro de espacio.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Aplicaciones" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={5} className="mb-5">
          <ul>
            {NotableSpecifications.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={8} className="mb-3">
          <img
            className={styles.image}
            src={TypicalApplication}
            alt="Aplicación Típica"
          />
        </Col>
        <SubTitleProduct content="Aplicación Típica: Interconexión de 1 Gpbs de dos complejos industriales vía fibra óptica" />
      </Row>
    </Container>
  );
}
