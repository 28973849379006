import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightPOEGSH41160BT() {
  const DIPSwitchControl = [
    {
      title: "Control del interruptor DIP:",
      detail:
        "VLAN: Modo de aislamiento de puertos. En este modo, los puertos PoE (1 ~ 4) no pueden comunicarse entre sí y solo pueden comunicarse con el puerto UP-Link",
    },
    {
      detail:
        "Predeterminado: modo normal, todos los puertos pueden comunicarse entre sí, la distancia de transmisión está dentro de 100m, la velocidad de transmisión es 10M / 100/1000M adaptativa",
    },
    {
      detail:
        "Extender: El modo de extensión, la fuente de alimentación PoE y la distancia de transmisión de datos se pueden extender a 250 metros a la velocidad de transmisión 10Mbps Puerto 3 ~ 4 solamente.",
    },
  ];
  const PowerSupply = [
    {
      title: "Fuente de alimentación: ",
      detail: "Voltaje de entrada: AC100 ~ 240V",
    },
    {
      detail: "Fuente de alimentación: 52V / 2.3A (120W) o 52V / 1.25A 65W",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales de Operación:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 85 °C",
    },
    {
      detail: "Humedad: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 20 x 11,8 x 4,4 cm (L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 24.5 x 19 x 6 cm (L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: NW: 0.6kg; G.W: 0.9kg",
    },
    {
      detail: "Contenido del paquete: 1 x interruptor, 1 x cable de alimentación.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 20 equipos / cada caja cartón",
    },
    {
      detail: "Dimensione:   50,5 x 32 x 40,2 cm",
    },
    {
      detail: "Peso de la caja de  cartón: 19Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo:",
    },
    {
      detail: "POE-GSH411 serie BT:",
    },
    {
      detail: "POE-GSH411-60 BT: 4 x PoE 10/100/1000 (Puerto # 1 hasta 60W), 1 x 1000T, 1 x ranura SFP 1G, 65W",
    },
    {
      detail: "POE-GSH411-120 BT: 4 x PoE 10/100/1000 (Puerto # 1 hasta 60W)",
    },
  ];
  return (
    <Container>
      <ul>
        {DIPSwitchControl.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
