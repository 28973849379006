import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import styles from "./AirDualAC12.module.scss";
import { TitleAirDualAC12Section } from "./TitleAirDualAC12Section";
import { SectionFunctionalitiesAirDualAC12 } from "./SectionFunctionalitiesAirDualAC12";
import { AdvancedAdministrationAirDualAC12 } from "./AdvancedAdministrationAirDualAC12";
import { TypesApplications } from "../AirCloudAirMax5XII/TypesApplications";
import { SpecificationsAirDualAC12 } from "./SpecificationsAirDualAC12";

export function AirDualAC12() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleAirDualAC12Section />
           <SectionFunctionalitiesAirDualAC12 />
           <AdvancedAdministrationAirDualAC12 />
           <TypesApplications />
           <SpecificationsAirDualAC12 />
        </Container>
    </div>
  );
}
