import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function DataUsageSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Uso de datos" />
        </Col>
        <Col xs={12} className="mb-5">
            <p><strong>AirLive utiliza los datos recopilados para diversos fines:</strong></p>
            <ul>
                <li>
                    <p>Para proporcionar y mantener el Servicio</p>
                </li>
                <li>
                    <p>Para notificarle sobre cambios en nuestro Servicio</p>
                </li>
                <li>
                    <p>Para permitirle participar en funciones interactivas de nuestro Servicio cuando elija hacerlo</p>
                </li>
                <li>
                    <p>Para brindar atención y soporte al cliente</p>
                </li>
                <li>
                    <p>Para proporcionar análisis o información valiosa para que podamos mejorar el Servicio</p>
                </li>
                <li>
                    <p>Para monitorear el uso del Servicio</p>
                </li>
                <li>
                    <p>Para detectar, prevenir y abordar problemas técnicos</p>
                </li>
            </ul>
        </Col>
      </Row>
    </Container>
  );
}
