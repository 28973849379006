import { Container, Row, Col } from "react-bootstrap";
import styles from "./AirLiveFTTXSolutions.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GPONOLT121 from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121.png";
import GPONOLT422 from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT422.png";
import GPONOLT824 from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT824.png";

export function AirLiveFTTXSolutions() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Soluciones FTTX AirLive" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            AirLive provee todos los productos necesarios para una solución FTTX
            integral, lo cual incluye unidades OLT y ONU de diferentes
            capacidades y configuraciones, el transmisor óptico para CATV,
            amplificador se señal óptica, y accesorios tales como los
            transceivers ópticos SFP de diferentes velocidades y alcance hasta
            20km.
          </p>
          &nbsp;
          <p>
            Todos este equipamiento garantiza comenzar con una solución inicial
            y avanzar con el crecimiento de la plataforma gradual y
            escaladamente, en la medida en que el número de usuarios servidos y
            localidades van en aumento, asegurando así el fortalecimiento del
            prestador de servicio.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="OLTs GPON" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="GPON OLT-121, 1 Puerto GPON + 10G SFP+, 2 x RJ45 1G" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Es la unidad GPON OLT punta de lanza de la línea de OLTs AirLive con
            un robusto backplane de 16 Gbps. Soporta los estándares ITU-T
            G.984/G.988. Posee un puerto PON con Upstream de 1.244Gbps y
            Downstream de 2.488Gbps, y medio de fibra TX 1490nm, RX 1310nm;
            Adicionalmente, posee un puerto SFP/SFP+ 10G y otros dos RJ45
            1Gigabit. Soporta hasta 128 unidades ONU y posee un alcance de hasta
            20Km con el transceiver
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={GPONOLT121}
            alt="GPON OLT-121, 1 Puerto GPON + 10G SFP+, 2 x RJ45 1G"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="GPON OLT-422, 4 puertos PON, 2xSFP+ 10G, 2 x RJ45 1G" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Esta OLT permite la ampliación de servicio a pequeños entornos de
            negocio. Soporta los estándares ITU-T G.984/G.988, y mediante un
            backplane de 104Gbps soporta cuatro puertos PON con Uplink de
            1.25Gbps y Downlink de 2.5Gbps, y medio de fibra TX 1490nm, RX
            1310nm; Adicionalmente, posee dos puertos SFP/SFP+ 10G (uno más que
            la OLT-121) y dos RJ45 1Gigabit. Soporta hasta 128 unidades ONU por
            cada puerto PON para un total de 512 usuarios servidos a una
            distancia máxima de 20Km con los transceivers. Un detalle importante
            es que a partir de este modelo todas las OLT soportan fuentes de
            alimentación AC or DC dual.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={GPONOLT422}
            alt="GPON OLT-422, 4 puertos PON, 2xSFP+ 10G, 2 x RJ45 1G"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="GPON OLT-824 8 puertos PON, 2 x SFP+ 10G, 2 x SFP 1G, 4 x RJ45 1G" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El OLT-828 es un equipo desarrollado para duplicar la capacidad de
            OLT-422, con una configuración muy similar, pero con un backplane de
            104 Gbps y dos puertos SFP 1 Giga adicional a los dos de 10G.
            Permite servir un total de 1024 ONUs hasta una distancia de 20 km de
            distancia. Aporta funcionalidades L3 y fuente AC o DC dual.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={GPONOLT824}
            alt="GPON OLT-824 8 puertos PON, 2 x SFP+ 10G, 2 x SFP 1G, 4 x RJ45 1G"
          />
        </Col>
      </Row>
    </Container>
  );
}
