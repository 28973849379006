import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsWLAN64GMl.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { TechnicalspecificationsLeftWLAN64GMl } from "./TechnicalspecificationsLeftWLAN64GMl";
import { TechnicalspecificationsRightWLAN64GMl } from "./TechnicalspecificationsRightWLAN64GMl";

export function TechnicalspecificationsWLAN64GMl() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="WLAN-128GM  Enterprise Gateway & Wireless Controller" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <TechnicalspecificationsLeftWLAN64GMl />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <TechnicalspecificationsRightWLAN64GMl />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
