import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import styles from "./TitleSectionL2POEGSH42060.module.scss";
import GSH42060 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH42060/GSH42060.png";

export function TitleSectionL2POEGSH42060() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="6-port Gigabit POE Switch, 802.3af/at, 65W 6-port Gigabit incl 4x30W PoE, 2x1G RJ-45" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH420-60" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH42060}
            alt="6-port Gigabit POE Switch, 802.3af/at, 65W"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Descripción general" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="802.3af/at PoE Gigabit Switch, with 2x RJ-45 Uplink" />
          <p>
            The AirLive POE-GSH420-60 Gigabit switch is compliant with
            802.3af(15W)/802.3at(30W). It supports 4 Gigabit PoE+ ports and 2
            Gigabit RJ-45 uplink ports. The total PoE power is 60W. The AirLive
            POE-GSH420-60 switch is a plug and play unmanaged PoE switch with
            VLAN and 250meter cabling extension, which can be easily set up via
            its DIP switch. The two Gigabit Uplink ports help with connecting
            the switch to a larger network making the it the most cost-efficient
            client switch for various applications at Home or Office. Connect
            several PoE devices to the switch, Access Points or normal IP
            cameras can connect to any of the PoE ports on the switch and be
            powered on up to max 30W (at).
          </p>
        </Col>
      </Row>
    </Container>
  );
}
