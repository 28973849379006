import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightW6184QAXCE() {
  const VPN = [
    {
      title: "VPN:",
      detail: "Servidor VPN PPTP L2TP, Cliente L2TP/PPTP",
    },
  ];
  const UPnP = [
    {
        title: "UPnP: ",
      detail: "UPnP",
    },
  ];
  const DDNS = [
    {
      title: "DDNS:",
      detail: "3322.org, dyndns.org, no-ip.com, easydns.com, dnsexit.com, Dnsomatic.com, he.net, dnsdynamic.org",
    },
  ];
  const ParentalControl = [
    {
      title: "Control Parental:",
      detail: "Filtrado de direcciones Mac, Filtrado de URL",
    },
  ];
  const MESH = [
    {
      title: "MESH:",
      detail: "Malla fácil, Controlador, Agente, Estado",
    },
  ];
  const SNMP = [
    {
      title: "SNMP",
      detail: "Agente V1/2c",
    },
  ];
  const DataStatistics = [
    {
      title: "Estadísticas de datos:",
      detail: "LAN, WAN, WiFi 2.4G, WiFi 5.8G",
    },
  ];
  const MultipleLanguages = [
    {
      title: "Múltiples idiomas:",
      detail: "Inglés / Árabe / Turco",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: 0 °C ~ +50 °C, Temperatura de almacenamiento: -10 °C ~ +70 °C, Humedad: 10% ~ 90% (sin condensación)",
    },
  ];
  const Case = [
    {
      title: "Carcasa:",
      detail: "Plástico",
    },
  ];
  const AccessoryPack = [
    {
      title: "Paquete Accesorio:",
      detail: "1 x dispositivo, 1 x adaptador de corriente",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "AirLive W6 184QAX CE, Wi-Fi 6 AX 1800 Indoor Gigabit Router, con Easy MESH, VPN, IPTV, OpenWrt precargado",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Seguridad" />
      </Col>
      <ul>
        {VPN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {UPnP.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DDNS.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ParentalControl.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {MESH.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SNMP.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DataStatistics.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {MultipleLanguages.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Case.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AccessoryPack.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
