import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SNMPGSH802M.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2SNMPGSH802M } from "./TitleSectionL2SNMPGSH802M";
import { CharacteristicsSNMPGSH802M } from "./CharacteristicsSNMPGSH802M";
import { SurveillanceVLANSupport } from "./SurveillanceVLANSupport";
import { RMONSupport } from "./RMONSupport";
import { FeatureProductSNMPGSH802M } from "./FeatureProductSNMPGSH802M";

export function SNMPGSH802M() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2SNMPGSH802M />
           <CharacteristicsSNMPGSH802M />
           <SurveillanceVLANSupport />
           <RMONSupport />
           <FeatureProductSNMPGSH802M />
        </Container>
    </div>
  );
}
