import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./AirLiveinWallU618AX.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleU618AXSection } from "./TitleU618AXSection";
import { SectionFunctionalitiesU618AX } from "./SectionFunctionalitiesU618AX";
import { AdvancedAdministrationU618AX } from "./AdvancedAdministrationU618AX";
import { SpecificationsU618AX } from "./SpecificationsU618AX";

export function AirLiveinWallU618AX() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleU618AXSection />
           <SectionFunctionalitiesU618AX />
           <AdvancedAdministrationU618AX />
           <SpecificationsU618AX />
        </Container>
    </div>
  );
}
