import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./DataCollectedSection.module.scss";

export function DataCollectedSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Tipos de datos recopilados" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>Información personal</p>
          <p>
            Mientras usa nuestro Servicio, podemos pedirle que nos proporcione
            cierta información de identificación personal que se puede usar para
            contactarlo o identificarlo ("Datos personales"). La información de
            identificación personal puede incluir, entre otros:
          </p>
          <ul>
            <li>
              <p>Cookies y datos de uso</p>
            </li>
          </ul>
        </Col>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>Datos de uso</div>
          <p>
            También podemos recopilar información sobre cómo se accede y utiliza
            el Servicio ("Datos de uso"). Estos Datos de uso pueden incluir
            información como la dirección del Protocolo de Internet de su
            computadora (por ejemplo, la dirección IP), el tipo de navegador, la
            versión del navegador, las páginas de nuestro Servicio que visita,
            la hora y la fecha de su visita, el tiempo dedicado a esas páginas,
            único identificadores de dispositivos y otros datos de diagnóstico.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>Datos de seguimiento y cookies</div>
          <p>
            Usamos cookies y tecnologías de seguimiento similares para rastrear
            la actividad en nuestro Servicio y conservar cierta información.
          </p>
          <p>
            Las cookies son archivos con una pequeña cantidad de datos que
            pueden incluir un identificador único anónimo. Las cookies se envían
            a su navegador desde un sitio web y se almacenan en su dispositivo.
            Las tecnologías de seguimiento que también se utilizan son balizas,
            etiquetas y scripts para recopilar y rastrear información y para
            mejorar y analizar nuestro Servicio.
          </p>
          <p>
            Puede indicar a su navegador que rechace todas las cookies o que
            indique cuándo se envía una cookie. Sin embargo, si no acepta las
            cookies, es posible que no pueda utilizar algunas partes de nuestro
            Servicio.
          </p>
          <p><strong>Ejemplos de Cookies que utilizamos:</strong></p>
          <ul>
            <li>
              <p>
                <span className={styles.subTitle}>Cookies de sesión.</span>
                Usamos Cookies de Sesión para operar nuestro Servicio.
              </p>
            </li>
            <li>
              <p>
                <span className={styles.subTitle}>Cookies de preferencia.</span>
                Usamos cookies de preferencia para recordar sus preferencias y
                varias configuraciones.
              </p>
            </li>
            <li>
              <p>
                <span className={styles.subTitle}>Cookies de seguridad.</span>
                Utilizamos cookies de seguridad por motivos de seguridad.
              </p>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
