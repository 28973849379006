import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH1622200.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH1622200 } from "./TitleSectionL2POEGSH1622200";
import { CharacteristicsPOEGSH1622200 } from "./CharacteristicsPOEGSH1622200";
import { EfficientPoETechnology } from "../EfficientPoETechnology";
import { FeatureProductPOEGSH1622200 } from "./FeatureProductPOEGSH1622200";
import PortsPoePOEGSH1622200 from "../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1622200/PortsPoePOEGSH1622200.png";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.png";

export function POEGSH1622200() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH1622200 />
           <CharacteristicsPOEGSH1622200 />
           <EfficientPoETechnology content={"POE-GSH1622-200"} image={PortsPoePOEGSH1622200} image2={FiberOpticPOE} numberports={"1 - 16"} numberports2={"9 y 16"} TextPoeWatchdog={undefined} />
           <FeatureProductPOEGSH1622200 />
        </Container>
    </div>
  );
}
