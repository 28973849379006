import { Container, Row, Col } from "react-bootstrap";
import styles from "./IntegralSolutionPONTriplePlay.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import PONXGSPONetwork from "../../../../../../../../assets/imagesproduct/AirLiveGPON/PONXGSPONetwork.png";

export function IntegralSolutionPONTriplePlay() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Solución integral PON Triple play - Internet, voz y TV" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={PONXGSPONetwork}
            alt="PON/XGSPO Network"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            La implementación de una red PON/XGSPON requiere seguir una serie de
            pasos:
            <ul>
              <li>
                Realizar un estudio previo del área a cubrir, determinando la
                demanda potencial, la ubicación del OLT, los puntos de acceso a
                la fibra óptica, la longitud y el tipo de fibra óptica a
                utilizar, el número y el tipo de divisores ópticos, etc.
              </li>
              <li>
                Diseñar la red PON/XGSPON, definiendo la topología física y
                lógica, el dimensionamiento del ancho de banda, la asignación de
                VLANs y puertos GEM/XGEM, la configuración de la calidad de
                servicio (QoS), la gestión remota mediante el protocolo OMCI,
                etc.
              </li>
              <li>
                Instalar los equipos y elementos pasivos de la red PON/XGSPON,
                siguiendo las normas técnicas y las buenas prácticas para
                garantizar la calidad y seguridad de la instalación.
              </li>
              <li>
                Realizar pruebas y mediciones para verificar el correcto
                funcionamiento y rendimiento de la red PON/XGSPON, utilizando
                equipos como medidores de potencia óptica, reflectómetros
                ópticos (OTDR), analizadores de espectro óptico, etc.
              </li>
            </ul>
          </p>
          <p>
            Ventajas de implementar una solución FTTX con GPON/XGSPON:
            <ul>
              <li>Mayor capacidad y velocidad de transmisión.</li>
              <li>
                Solución eficiente asimétrica con GPON y simetría en el sentido
                descendente y ascendente con XGSPON.
              </li>
              <li>Compatibilidad de XGSPON con redes GPON existentes</li>
              <li>Amplio alcance y cobertura</li>
              <li>Crecimiento y escalabilidad</li>
              <li>Seguridad y robustéz</li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
