import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftPOEGSH822120() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "8 x 30W PoE 10/100/1000 RJ45 Auto MDI/MDI-X" 
        },
        {
            detail: "2 puertos Gigabit de enlace ascendente RJ45 Auto MDI/MDI-X" 
        },
        {
            detail: "2 x Enlace ascendente Gigabit SFP Auto MDI/MDI-X" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "IEEE802.3af/at PoE" 
        },
        {
            detail: "IEEE802,3 veces" 
        },
        {
            detail: "Ethernet de eficiencia energética IEEE802.3az" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-5 (Sobretensión)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (Encendido), ), 1-12 LED Verde (Enlace y Datos)" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Encapsulamiento de metal" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 8" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 30W (puerto 1 ~ 8)" 
        },
    ];
    const PoESwitchPower = [
        {
            title:"Potencia PoE / switch:",
            detail: "10 W, Potencia POE: 110 W",
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones y ESD:",
            detail: "Modo común 4KV, ESD: Aire 8KV, Contacto 6KV" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "Medio de fibra" 
        },
        {
            detail: "Fibra multimodo: 850 nm, 1310 nm" 
        },
        {
            detail: "Distancia de transmisión: 550 m / 2 km" 
        },
        {
            detail: "Fibra monomodo: 1310 nm, 1550 nm " 
        },
        {
            detail: "Distancia de transmisión 20/40/60/80/km" 
        },
        {
            detail: "Arquitectura de conmutación: Almacenar y reenviar" 
        },
        {
            detail: "Capacidad del conmutador: Plan de respaldo de hasta 24 Gbps" 
        },
        {
            detail: "Tasa de reenvío: 17.856 Mpps" 
        },
        {
            detail: "Dirección MAC: 2K" 
        },
        {
            detail: "Caché de paquetes: 448 Kbit" 
        },
        {
            detail: "Trama gigante: 9216bytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoESwitchPower.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
