import { Container, Row, Col } from "react-bootstrap";
import styles from "./AircloudAndroidApp.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function AircloudAndroidApp() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Aplicación Aircloud Android" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mb-3">
            A continuación una breve guía sobre los pasos para utilizar la
            aplicación en la nube para administrar y configurar sus dispositivos
            de red:
          </p>
          <ul className={styles.listItem}>
            <li>
              <p>
                Descargue la aplicación Android en su celular desde el siguiente
                enlace:
              </p>
            </li>
            <p className="mb-3">
              <a href="https://www.airlive.com/data/download/file/airlive-v1.1.3-en.rar">
                download link
              </a>
            </p>
            <li className="mb-3">
              <p>
                Iniciar sesión mediante su cuenta en la nube Para acceder a la
                aplicación en la nube,
              </p>
              <p>
                primero debe crear una cuenta en la nube AirCloud. Una vez que
                tenga una cuenta, puede iniciar sesión en la aplicación con sus
                credenciales. Puede crear el nombre de un proyecto asociado a su
                empresa u organización.
              </p>
            </li>
            <li className="mb-3">
              <p>Vincule el dispositivo inalámbrico en la aplicación</p>
              &nbsp;
              <p>
                Luego de iniciar sesión en la aplicación, debe vincular sus
                dispositivos de red a la aplicación. Esto generalmente se hace
                escaneando un código QR en el dispositivo o ingresando
                manualmente el identificador único del dispositivo.
              </p>
            </li>
            <li className="mb-3">
              <p>
                Visualice el dispositivo wifi actual a través de la aplicación
                en la nube
              </p>
              &nbsp;
              <p>
                Una vez que haya vinculado sus dispositivos a la aplicación,
                puede ver una lista de sus dispositivos de red actuales en la
                aplicación. Esto incluye información como el nombre del
                dispositivo, la dirección IP y el estado de la conexión.
              </p>
            </li>
            <li className="mb-3">
              <p>Configura los parámetros Wifi</p>
              &nbsp;
              <p>
                Con la aplicación, puede configurar sus ajustes de wifi, como
                configurar una nueva red wifi o cambiar el SSID y la contraseña
                de una red existente.
              </p>
            </li>
            <li className="mb-3">
              <p>Configurar la plantilla Wifi</p>
              &nbsp;
              <p>
                Además de configurar los ajustes de wifi para dispositivos
                individuales, también puede crear plantillas wifi que se pueden
                aplicar a varios dispositivos a la vez. Esto puede ahorrar
                tiempo al configurar redes grandes con muchos dispositivos.
              </p>
            </li>
            <li className="mb-3">
              <p>Implemente red a través de la aplicación</p>
              &nbsp;
              <p>
                Una vez que haya definido sus configuraciones y plantillas wifi,
                puede implementar su red aplicando la configuración a todos sus
                dispositivos a la vez. Esto se hace normalmente a través de la
                característica de implementación de la aplicación.
              </p>
            </li>
            <li className="mb-3">
              <p>Revisión de la topología y solución de problemas de red</p>
              &nbsp;
              <p>
                La aplicación proporciona una vista de topología de red que
                muestra cómo se conectan los dispositivos entre sí, lo cual es
                útil para solucionar problemas de red o identificar áreas en las
                que su red puede necesitar mejoras.
              </p>
            </li>
            <li className="mb-3">
              <p>Configuración de alarma de dispositivo</p>
              &nbsp;
              <p>
                Finalmente, la aplicación permite configurar alarmas de
                dispositivos que pueden alertarlo si alguno de ellos se
                desconecta o experimenta algún problema. Esto permite mantenerse
                al tanto de las condiciones de la red y actuar rápidamente antes
                de que ocurra un problema mayor.
              </p>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="mb-5">
          <p>
            A continuación podrá ver algunas pantallas típicas de la App
            AirCloud para Android.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
