import { Container, Row, Col } from "react-bootstrap";
import styles from "./AirLiveGPON.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GPONimg from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONimg.png";
import DecorativeImage from "../../../../../../../../assets/imagesproduct/AirLiveGPON/DecorativeImage.png";

export function AirLiveGPON() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Plataforma AirLive GPON" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirLive GPON" />
        </Col>
        <Col xs={12} className="mb-5">
          <img className="w-100" src={GPONimg} alt="AirLive-GPON" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El mercado de las telecomunicaciones está evolucionando de las
            plataformas de servicio ADSL/VDSL, lentas y de avanzado estado de
            obsolescencia, a nuevas y modernas plataformas de fibra óptica FTTx
            para servicio de conectividad de última milla al hogar y la oficina.
          </p>
          &nbsp;
          <p>
            Esta tendencia es mundial, tanto en Europa y Estados Unidos, quienes
            ya están muy adelantados, como también en otras regiones como Asia
            Pacífico, y Latinoamérica, especialmente en materia de servicio PON
            a 10G.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={DecorativeImage}
            alt="Decorative Image GPON"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <p>
            Como líderes en el campo de la tecnología de redes, AirLive decidió
            aprovechar la experiencia y conocimientos en la provisión de
            soluciones para ayudar a sus empresas aliadas en todos los
            territorios de presencia de la marca, para apalancarse en las
            ventajas de la conectividad de fibra óptica, y beneficiarse de la
            oportunidad de negocio que representa FTTX. Con este mercado en
            constante crecimiento, es el momento oportuno para ofrecer
            soluciones confiables, excelente costo-beneficio, y de alta calidad
            que satisfagan las necesidades de nuestros valiosos clientes.
          </p>
          &nbsp;
          <p>
            Las soluciones FTTX, Fiber to the Home (FTTH) y Fiber to The
            Building (FTTB) son aquellas que utilizan fibra óptica para conectar
            un punto central del proveedor de acceso a una variedad de usuarios,
            ya sean residenciales, comerciales o corporativos.
          </p>
          &nbsp;
          <p>
            Estas soluciones ofrecen una alta capacidad de transmisión de datos,
            una mayor calidad de señal y una menor interferencia que las redes
            de cobre o coaxial.
          </p>
          &nbsp;
          <p>
            Dentro de las soluciones FTTX, existen diferentes tecnologías de
            redes ópticas pasivas (PON), que se caracterizan por no utilizar
            elementos activos entre el punto central y los usuarios, sino
            divisores ópticos pasivos que distribuyen el ancho de banda.
          </p>
          &nbsp;
          <p>
            Entre estas tecnologías se encuentran XGSPON, XGPON, XPON(GPON/EPON)
            y GPON, siendo GPON y XGSPON, las más utilizadas actualmente.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
