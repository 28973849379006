import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalPOEGSH1602M200.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftPOEGSH1602M200 } from "./TechnicalLeftPOEGSH1602M200";
import { TechnicalRightPOEGSH1602M200 } from "./TechnicalRightPOEGSH1602M200";

export function TechnicalPOEGSH1602M200() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH1602M-200" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalLeftPOEGSH1602M200 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalRightPOEGSH1602M200 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
