import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import VLANPolicyPic from "../../../../../../../assets/imagesproduct/SwitchL2/VLANPolicyPic.png";
import DHCPsupport from "../../../../../../../assets/imagesproduct/SwitchL2/DHCPsupport.png";

export function VLANPolicy() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Política VLAN para videoconferencia/voz/datos" />
          <p>
            El usuario puede definir una política para la concesión de video /
            datos de voz en alta prioridad, para garantizar que su reunión de
            video no se retrase por ningún atasco de red.
          </p>
        </Col>
        <Col xs={6} className="mb-5 text-align-center">
          <img className="w-100" src={VLANPolicyPic} alt="VLAN Policy" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte de DHCP Snooping" />
          <p>Administración del servidor DHCP; prevenir extraño / Hacker.</p>
        </Col>
        <Col xs={6} className="mb-5">
          <img
            className="w-100"
            src={DHCPsupport}
            alt="DHCP support"
          />
        </Col>
      </Row>
    </Container>
  );
}
