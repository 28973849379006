import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftPOEGSH1622200() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "16 x 30W PoE 10/100/1000 RJ-45 Auto MDI/MDI-X" 
        },
        {
            detail: "2 puertos Gigabit de enlace ascendente RJ45 Auto MDI/MDI-X" 
        },
        {
            detail: "2 x Enlace ascendente Gigabit SFP Auto MDI/MDI-X" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "IEEE802.3af/at PoE" 
        },
        {
            detail: "IEEE802,3 veces" 
        },
        {
            detail: "Ethernet de eficiencia energética IEEE802.3az" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-5 (Sobretensión)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (Encendido), 1-12 LED Verde (Enlace y Datos), LED 1-20 Verde (Link & Data), 1-16 (LED amarillo), Modo Interruptor DIP" 
        },
        {
            detail: "PoE Max LED: Apagado: indica uso de  PoE power menor a 90%" 
        },
        {
            detail: "Titilando: indica uso de PoE de 90% ≤ P ≤ 95%" 
        },
        {
            detail: "Encedido: indica uso de PoE al 95% <P <100%" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Metal" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones y ESD:",
            detail: "Modo común 4KV, modo diferencial 2KV; ESD electrostática: aire 8KV, contacto 6K" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 16" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 30W (puerto 1 ~ 16)" 
        },
    ];
    const PoESwitchPower = [
        {
            title:"Potencia PoE / switch:",
            detail: "10 W, Potencia POE: 180 W",
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "Medio de fibra" 
        },
        {
            detail: "Fibra multimodo: 850 nm, 1310 nm" 
        },
        {
            detail: "Distancia de transmisión: 550 m / 2 km" 
        },
        {
            detail: "Fibra monomodo: 1310 nm, 1550 nm " 
        },
        {
            detail: "Distancia de transmisión 20/40/60/80/km" 
        },
        {
            detail: "Arquitectura de conmutación: Almacenar y reenviar" 
        },
        {
            detail: "Capacidad del conmutador: Plan de seguimiento de hasta 40 Gbps" 
        },
        {
            detail: "Tasa de reenvío: 29.76Mpps" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Búfer de paquetes: 3 Mbit" 
        },
        {
            detail: "Trama gigante: 9216bytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoESwitchPower.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
