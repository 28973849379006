import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live8GTII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L8GTII from "../../../../../../../../assets/imagesproduct/SwitchL2/Live8GTII/L8GTII.png";
import descriptionSwitchL2Live8GTII from "../../../../../../../../assets/imagesproduct/SwitchL2/Live8GTII/descriptionSwitchL2Live8GTII.png";

export function TitleSectionL2Live8GTII() {
  const Feature = [
    {
      detail: "8 puertos, cada puerto ofrece alta velocidad Gigabit ",
    },
    {
      detail: "Peso ligero",
    },
    {
      detail: "Plug & Play",
    },
    {
      detail: "Control de flujo",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch SOHO de 8 puertos Gigabit, Plug and Play" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-8GT-II" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L8GTII}
            alt="Switch SOHO de 8 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit de 8 puertos Plug-and-Play" />
          <p>
            El AirLive Live-8GT II es diseñado como un switch Gigabit no
            administrado plug-and-play con un precio muy asequible. Además, el
            conmutador Gigabit es fácil de administrar y mantener, con su diseño
            sin ventilador y tamaño pequeño, el producto se puede usar
            ampliamente en el hogar, la oficina SOHO o como conmutador de
            cliente para PYMES.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live8GTII}
            alt="Description Switch"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
