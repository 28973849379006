import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live8GT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live8GT } from "./TitleSectionL2Live8GT";
import { ModeVlanQoS } from "../ModeVlanQoS";
import { FeatureProductLive8GT } from "./FeatureProductLive8GT";
import VLANExtendMode from "../../../../../../../assets/imagesproduct/SwitchL2/VLANExtendMode.png";

export function Live8GT() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live8GT />
           <ModeVlanQoS image={VLANExtendMode} uplinkports={"7/8"} numberports={"1~2"} extendedmodenumberports={"Puerto 1~6"} numberports2={"7/8"} />
           <FeatureProductLive8GT />
        </Container>
    </div>
  );
}
