import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH402BT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH402BT } from "./TitleSectionL2POEGSH402BT";
import { CharacteristicsPOEGSH402BT } from "./CharacteristicsPOEGSH402BT";
import { FeatureProductPOEGSH402BT } from "./FeatureProductPOEGSH402BT";
import { FiberBackbonePoETechnology } from "../FiberBackbonePoETechnology";
import SurveillanceDevices from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceDevices.png";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.png";

export function POEGSH402BT() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH402BT />
           <CharacteristicsPOEGSH402BT />
           <FiberBackbonePoETechnology content={"POE-GSH402BT"} image={SurveillanceDevices} image2={FiberOpticPOE} />
           <FeatureProductPOEGSH402BT />
        </Container>
    </div>
  );
}
