import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live10TX800.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L10TX800 from "../../../../../../../../assets/imagesproduct/SwitchL2/Live10TX800/L10TX800.png";
import descriptionSwitchL2Live10TX800 from "../../../../../../../../assets/imagesproduct/SwitchL2/Live10TX800/descriptionSwitchL2Live10TX800.png";

export function TitleSectionL2Live10TX800() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Multi Gigabit de 8 puertos 10Gbps Base-T 100/1000M/2.5G/5G/10G RJ-45, Auto-Ajustables" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-10TX800" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L10TX800}
            alt="Switch Multi Gigabit 2.5Gbps Base-T, plug & play Incluye 9 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Actualiza tu Plataforma de red a 10Gbps" />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <p>
            El switch multi Gigabit AirLive Live-10TX800 está equipado con 8
            puertos RJ45, cada puerto admite una velocidad súper alta de hasta
            10 Gbps. Cada puerto puede ofrecer automáticamente una velocidad de
            100M / 1000M o 2.5G / 5G / 10G a dispositivos conectivos. Es
            perfecto para diversas aplicaciones. Por ejemplo, el puerto 1 y el
            puerto 2 se conectan a su servidor o almacenamiento NAS a 10 Gbps,
            el puerto 3 ~ el puerto 5 se conecta a su portátil o PC a 2,5 G o 1
            Gbps, el puerto 6 se conecta a la caja de juegos a 5G o 10 Gbps.
            Diseñado en una carcasa sólida, es una solución asequible que ahorra
            espacio, adecuada para aplicaciones de red domésticas y SMB.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live10TX800}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
