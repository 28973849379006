import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH41160BT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH41160BT } from "./TitleSectionL2POEGSH41160BT";
import { CharacteristicsPOEGSH41160BT } from "./CharacteristicsPOEGSH41160BT";
import { FeatureProductPOEGSH41160BT } from "./FeatureProductPOEGSH41160BT";
import { FiberBackbonePoETechnology } from "../FiberBackbonePoETechnology";
import SurveillanceDevices from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceDevices.png";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.png";

export function POEGSH41160BT() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH41160BT />
           <CharacteristicsPOEGSH41160BT />
           <FiberBackbonePoETechnology content={"POE-GSH411-BT"} image={SurveillanceDevices} image2={FiberOpticPOE} />
           <FeatureProductPOEGSH41160BT />
        </Container>
    </div>
  );
}
