import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleXponONU1GE.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import XponONU1GEDetails from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONU1GE/XponONU1GEDetails.png";
import XponONU1GETriplaySolution from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XPONONU1GE/XponONU1GETriplaySolution.png";

export function TitleXponONU1GE() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="ONU  de 1 Puerto XPON + 1*GE" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="XPON ONU-1GE" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={XponONU1GEDetails}
            alt="ONU  de 1 Puerto XPON + 1*GE"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El exitoso lanzamiento de AirLive en la Expo Computex 2023 de esta
            ONU Xpon con puerto único Gigabit constituye una solución
            empresarial de vanguardia. Diseñada para satisfacer las exigentes
            necesidades cambiantes de las empresas modernas, nuestra Unidad de
            Red Óptica (ONU) combina el poder de la tecnología Xpon con un solo
            puerto gigabit para una conectividad a Internet ultrarrápida. Con
            sus características avanzadas, nuestro XPON ONU-1GE garantiza una
            transmisión de datos fluida, estable y rápida, una latencia
            ultrabaja y un rendimiento de red mejorado, lo que permite a las
            empresas evolucionar en términos de prestación de servicio en esta
            exigente era digital actual. Ya sea que necesite acceso a Internet
            de alta velocidad, conectividad confiable o mayor productividad,
            esta ONU Xpon de puerto Gigabit ofrece un rendimiento y una
            eficiencia incomparables. Eleve la conectividad de su negocio con
            nuestra solución de vanguardia y desbloquee un mundo de
            posibilidades.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={XponONU1GETriplaySolution}
            alt="XPON ONU-1GE Tri-play Solution"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Cobertura de alta velocidad y larga distancia para servicios Triple Play" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El XPON ONU-1GE ofrece una económica solución para proporcionar
            cobertura de alta velocidad y larga distancia en aplicaciones de
            servicio triple play como HDTV, IPTV y multimedia, lo que permite
            una transmisión de datos rápida y fiable. Con un rango de cobertura
            de hasta 20 km entre los nodos de los equipos, el XPON ONU-1GE es
            una tecnología de acceso rentable que ofrece flexibilidad para el
            despliegue de la red. Su red confiable y escalable garantiza una
            experiencia perfecta y eficiente para los usuarios.
          </p>
        </Col>
        <Col>
          <p>
            <HomeSubtitle title="Funcionalidades resaltantes" />
            <ul>
              <li>Modo de Acceso Dual XPON Automático a EPON/GPON</li>
              <li>1 puerto RJ-45 de 1 Gbps</li>
              <li>Potente cortafuegos</li>
              <li>Atractivo encapsulamiento, tamaño pequeño y fácil de utilizar.</li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
