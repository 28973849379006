import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftISFPSR10G300M() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware:",
      detail: "Voltage de la Fuente de Alimentación: 3.15V ~ 3.45V",
    },
    {
      detail: "Voltage típico de la Fuente: 3.3V",
    },
  ];
  const Standards = [
    {
      title: "Estándares:",
      detail: "Compatible con IEEE 802.3ae",
    },
    {
      detail: "Cumple con el diagnóstico digital SFF-8472",
    },
    {
      detail: "Cumple con IEC-60825",
    },
    {
      detail: "Soporte DDM",
    },
    {
      detail: "Protección ESD",
    },
    {
      detail: "Corriente de suministro total: 300mA",
    },
  ];
  const DataSpeed = [
    {
      title: "Velocidad de datos:",
      detail: "10 Gbps",
    },
  ];
  const TotalSupplyCurrent  = [
    {
      title: "Disipación de potencia:",
      detail: "Máx. 1,0 W",
    },
    {
      title: "Transmisor (Eléctrico - Óptico) Longitud de onda central:",
      detail: "- Mín.: 840nm, Típico: 850nm, Máx.: 860nm",
    },
    {
      title: "Potencia de salida media:",
      detail: "-5, Max -0dBm",
    },
    {
      title: "Relación de extinción:",
      detail: "Min: 3.0dB",
    },
    {
      title: "Velocidad de datos operativos:",
      detail: " 10Gbps",
    },
    {
      title: "Diferencial de entrada Tx. Voltaje:",
      detail: "Mín. 180 Mv, Máx. 700 mV",
    },
    {
      title: "Voltaje de salida de falla Tx - Afirmar:",
      detail: "Mín. 2.0V, Vcc máx.",
    },
    {
      title: "Voltaje de salida de falla Tx - De-Assert:",
      detail: "Mín. 0V, Máx. 0.8V",
    },
    {
      title: "Receptor -óptico, eléctrico, Longitud de onda central:",
      detail: "Típico 850nm",
    },
    {
      title: "Sensibilidad de recepción en potencia media:",
      detail: "Máx. -11.5dBm, Los Assert: 12dBm, Los De-Assert: -25dBm, Los histéresis: Mín. 0.5",
    },
    {
      title: "Sobrecarga del receptor: ",
      detail: "-10.5dBm",
    },
    {
      title: "Voltaje diferencial de oscilación de entrada de datos: ",
      detail: "Mín. 180mV, Máx. 700mV",
    },
  ];
  
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DataSpeed.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {TotalSupplyCurrent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      
    </Container>
  );
}
