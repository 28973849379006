import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightAirMax5XII() {
  const networkFunction = [
    {
      title: "Función de red:",
      detail: "Configuración de VLAN, Servidor DHCP, Servidor en la nube",
    },
  ];
  const DeviceManagement = [
    {
      title: "Administración de dispositivos:",
      detail:
        "Copia de seguridad de la información de configuración, Restaurar la información de configuración, Restablecer la configuración predeterminada de fábrica, Salida de PoE LAN deshabilitar/habilitar",
    },
  ];
  const DeviceReboot = [
    {
      title: "Reinicio del dispositivo:",
      detail: "Actualización del firmware",
    },
  ];
  const TimeManagement = [
    {
      title: "Gestión del tiempo:",
      detail:
        "incluida la hora del sistema, la sincronización horaria, Registro del sistema, Soporte de gestión de GUI WEB, Soporte de administración remota en la nube, Soporte de gestión de CA suave",
    },
  ];
  const MultipleLanguages = [
    {
      title: "Múltiples idiomas",
      detail: "Inglés",
    },
  ];
  const ESDProtectionContact = [
    {
      title: "Protección ESD Contacto:",
      detail: "±6KV, Aire: ±8KV",
    },
  ];
  const Environment = [
    {
      title: "Medio ambiente:",
      detail:
        "Temperatura de funcionamiento: -30 °C ~ +70 °C , Temperatura de almacenamiento: -40 °C ~ +70 °C, Humedad de almacenamiento: 5% ~ 95% (sin condensación)",
    },
  ];
  const Case = [
    {
      title: "Carcasa:",
      detail: "IP65",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar Tamaño del producto:",
      detail: "26.19*8.82*5.82 cm (L*W*H)",
    },
  ];
  const PackageSize = [
    {
      title: "Tamaño del paquete:",
      detail: "33.2 * 10.6 * 9.5 cm (L * W * H)",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail:
        "TBD, 1 x AirMax5X ll, adaptador PoE, accesorio de instalación, cable LAN, manual del usuario",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "AirLive AirMax5X ll Wi-Fi 5Ghz N300 Gigabit CPE exterior",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Seguridad" />
      </Col>
      <ul>
        {networkFunction.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DeviceManagement.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DeviceReboot.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {TimeManagement.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {MultipleLanguages.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ESDProtectionContact.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Case.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageSize.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}
