import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopW6184QAXCE from "../../../../../../../../assets/imagesproduct/AircloudProducts/W6184QAX/TopW6184QAXCE.png";
import functionalityW6184QAXCE from "../../../../../../../../assets/imagesproduct/AircloudProducts/W6184QAX/functionalityW6184QAXCE.png";
import { CharacteristicsW6184QAXCE } from "./CharacteristicsW6184QAXCE";

export function TitleW6184QAXCESection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Enrutador Mesh Wi-Fi 6 AX 1800 Gigabit" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="W6-184QAX-CE" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={TopW6184QAXCE}
            alt="Enrutador Mesh Wi-Fi 6 AX 1800 Gigabit"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionlaidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CharacteristicsW6184QAXCE />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funciones Avanzadas AirLive W6 184QAX CE" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={functionalityW6184QAXCE}
            alt="Enrutador Mesh Wi-Fi 6 AX 1800 Gigabit"
          />
        </Col>
      </Row>
    </Container>
  );
}
