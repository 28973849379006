import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH822120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH822120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH822120/GSH822120.png";
import descriptionSwitchL2POEGSH822120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH822120/descriptionSwitchL2POEGSH822120.png";

export function TitleSectionL2POEGSH822120() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit POE de 12 puertos, 802.3af/at, 120W Incluye 8 puertos Gigabit PoE 30W + 2x1.25G SFP, 2x1000T" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH822-120" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH822120}
            alt="Switch Gigabit POE de 12 puertos, 802.3af/at, 120W"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE+ de 12 puertos con prioridad PoE, modo extendido y puerto SFP/RJ-45" />
          <p>
            El switch AirLive POE-GSH822-120 es compatible con el estándar
            802.3af (15 W) / 802.3at (30 W). Posee ocho (8) puertos PoE y 4
            puertos de enlace ascendente Gigabit, 2 RJ45 o 2 fibras SPF. La
            potencia total de PoE es de 120 W. Es un switch PoE no gestionado,
            plug and play con prioridad PoE y extensión de cableado de 250
            metros, que se puede configurar fácilmente a través de su
            interruptor DIP. Los cuatro puertos Gigabit Uplink (2 SFP y 2 RJ-45)
            permiten conectar a una red más grande, lo que lo convierte en un
            switch muy rentable para diversas aplicaciones en el hogar o la
            oficina. Conecte varios dispositivos PoE, tales como puntos de
            acceso o cámaras IP normales en cualquiera de los puertos PoE con
            alimentación hasta un máximo de 30 W (at).
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH822120}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
