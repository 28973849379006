import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./AirCloudAirMax5XII.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { GeneralcharacteristicsSection } from "./GeneralcharacteristicsSection";
import { SectionFunctionalitiesAirMax5XII } from "./SectionFunctionalitiesAirMax5XII";
import { AdvancedAdministrationAirMax5XII } from "./AdvancedAdministrationAirMax5XII";
import { TypesApplications } from "./TypesApplications";
import { SpecificationsAirMax5XII } from "./SpecificationsAirMax5XII";

export function AirCloudAirMax5XII() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <GeneralcharacteristicsSection />
           <SectionFunctionalitiesAirMax5XII />
           <AdvancedAdministrationAirMax5XII />
           <TypesApplications />
           <SpecificationsAirMax5XII />
        </Container>
    </div>
  );
}
