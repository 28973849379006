import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import styles from "./Products.module.scss";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export function Products() {
  return (
    <Container className={styles.Container}>
        <Row>
          <Col className="mb-3 mt-5" xs={12} md={6}>
            <HomeSubtitle title="Productos" />
          </Col>
          <Col className="mb-3 mt-5" xs={12} md={6}>
            <InputGroup className="mb-3 w-100">
              <Form.Control
                placeholder="Búsqueda por producto..."
                aria-label="Búsqueda"
              />
              <Button variant="success" size="lg">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className={styles.icon}
                />
              </Button>
            </InputGroup>
          </Col>
        </Row>
    </Container>
  );
}
