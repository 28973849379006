import { Container, Row, Col } from "react-bootstrap";
import styles from "./SpecificationsAirCloud.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";

export function SpecificationsAirCloud() {
  const LeftSpecifications = [
    {
      title: "Host - Host principal:",
      detail: "Awz EC2 2xlarge",
    },
    {
      title: "Conexión Internet:",
      detail: "5000mbps",
    },
    {
      title: "Sistema Operativo - OS",
      detail: "ubuntu-focal-20.04-amd64-server",
    },
    {
      title: "Mapa del SitioWeb - Login",
      detail:
        "Recordar cuenta, Olvidó la contraseña, Registro, Acuerdo de servicio",
    },
    {
      title: "Inicio",
      detail: "Dispositivo asociado, Personal, Información del Sistema, Salir",
    },
    {
      detail:
        "Estadística de dispositivos, Ingresos recientes, Traza de configuración, ",
    },
    {
      detail: "Total, Actividad de Usuario, En línea, Fuera de línea",
    },
    {
      detail: "Reporte de Inspección, Fallas, Actualizables",
    },
    {
      detail: "Respaldo, Inspección cronometrada",
    },
    {
      title: "Dispositivo",
      detail:
        "Lista de Projectos, Vista Topológica, Editar el Gráfico Topológico",
    },
    {
      detail: "Reiniciar el Gráfico Topológico, Escanear dispositivos",
    },
    {
      detail: "Pantalla completa, Vista de listas, Configuración",
    },
    {
      detail: "Información, WIFI 2G, WIFI 5G, Usuario WIFI, Red",
    },
    {
      detail: "Lista de Usuarios, Otra Información",
    },
  ];
  const RightSpecifications = [
    {
      title: "WIFI",
      detail: "WiFi 2G, WiFi 5G, Avanzado, Tiempo libre WiFi, Enable/Disable",
    },
    {
      title: "Configuración de Alarma",
      detail: "Para dispositivos que están en ínea o fuera de línea",
    },
    {
      detail: "Uso de CPU, Uso de Memoria, Conflictos DHCP",
    },
    {
      detail: "Encendido/Apagado, Subir Email, Notificar usuarios",
    },
    {
      detail: "Traza de Dispositivos, Autenticación",
    },
    {
      detail: "WiFi  2G, WiFi  5G, Ethernet, Portal, Estrategia",
    },
    {
      detail: "Método de Autenticación, Servicio de Roaming",
    },
    {
      title: "Otra configuración",
      detail: "Reinicio temporizado, Contraseña de dispositivo",
    },
    {
      detail: "Plantilla de Aplicación, Reinicio, Asignación de dispositivo",
    },
    {
      detail: "Exportación, Desasociar",
    },
    {
      title: "Proyecto",
      detail: "Lista de Proyectos",
    },
    {
      title: "Mi Proyecto",
      detail: "Agregar, Eliminar, Información de Proyecto, Actualización",
    },
    {
      title: "Mantenimiento",
      detail: "Subir Firmware, Actualización temporizada",
    },
    {
      title: "Registro",
      detail: "Traza de configuración, Traza de actualizaciones",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Controlador basado en la nube AirLive AirCloud" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftSpecifications.map((item) => (
              <li>
                <SubTitleProduct content={item.title} />
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col  xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightSpecifications.map((item) => (
              <li>
                <SubTitleProduct content={item.title} />
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
