import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightXGS4804850({content, nameProduct}: {content: string; nameProduct: string;}) {
  const IPv6 = [
    {
      title: "IPv6:",
      detail: "Soporte IPv6 Ping、IPv6 Traceroute、IPv6 Telnet",
    },
    {
      detail: "Soporte IPv6 SSH",
    },
    {
      detail: "Soporte IPv6 HTTP 、IPv6 HTTPS",
    },
  ];
  const ManagementMaintenance = [
    {
      title: "Gestión y Mantenimiento:",
      detail: "Soporte de gestión WEB (HTTP, HTTPS)",
    },
    {
      detail: "Soporte CLI (Telnet, SSH V1 / V2, puerto serie local)",
    },
    {
      detail: "Soporte SNMP V1/V2/V3",
    },
    {
      detail: "Soporte RMON V2",
    },
    {
      detail: "Soporte de detección de dispositivos LLDP",
    },
    {
      detail: "Soporte SNTP Sincronización de tiempo",
    },
    {
      detail: "Soporte de monitoreo de CPU, monitoreo de memoria",
    },
    {
      detail: "Registro del sistema de soporte, advertencia de clasificación",
    },
    {
      detail: "Soporte de ping, detección de Traceroute, detección de cables",
    },
    {
      detail: "Soporte de cliente DNS",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C a +50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C a +70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 90%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Dimensiones del switch : 44x36x4.45 cm ",
    },
    {
      detail: "Dimensiones del paquete: 58x44x12 cm ",
    },
  ];
  const PackageWeight = [
    {
      title: "Peso del paquete:",
      detail: "N.W. del producto: 5.360KG",
    },
    {
      detail: "Producto G.W: 6.8KG",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail:
        "Conmutador PoE x 1, QIG x 1, cable de alimentación x 1, cable serie x 1, oreja rack x 1",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Dimensiones del cartón: 60x46x26 cm ",
    },
    {
      detail: "Cantidad del embalaje: 2 PCS",
    },
    {
      detail: "Peso del embalaje: 14.7KGS",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "Modelo:"+" "+ content,
    },
    {
      detail:
        "Nombre:"+" "+ nameProduct,
    },
  ];
  return (
    <Container>
      <ul>
        {IPv6.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {ManagementMaintenance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageWeight.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
