import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive24GT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive24GT } from "./SpecificationsLeftLive24GT";
import { SpecificationsRightLive24GT } from "./SpecificationsRightLive24GT/SpecificationsRightLive24GT";

export function FeatureProductLive24GT() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-24GT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive24GT />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive24GT />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}
