import { Container, Row, Col } from "react-bootstrap";

export function Videos() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Videos</h2>
        </Col>
      </Row>
    </Container>
  );
}
