import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { AirLiveGPON } from "./AirLiveGPON";
import { GPON } from "./GPON";
import { TriplePlayServices } from "./TriplePlayServices";
import { AirLiveFTTXSolutions } from "./AirLiveFTTXSolutions";
import { CATVServiceImplementation } from "./CATVServiceImplementation";
import { ONUsGPON } from "./ONUsGPON";
import { IntegralSolutionPONTriplePlay } from "./IntegralSolutionPONTriplePlay";
import styles from "./PlataformAirLiveGPON.module.scss";

export function PlataformAirLiveGPON() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <AirLiveGPON />
           <GPON />
           <TriplePlayServices />
           <AirLiveFTTXSolutions />
           <CATVServiceImplementation />
           <ONUsGPON />
           <IntegralSolutionPONTriplePlay />
        </Container>
    </div>
  );
}