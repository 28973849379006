import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import styles from "./AWOD12ACi.module.scss";
import { TitleAWOD12ACiSection } from "./TitleAWOD12ACiSection";
import { SectionFunctionalitiesAWOD12ACi } from "./SectionFunctionalitiesAWOD12ACi";
import { AdvancedAdministrationAWOD12ACi } from "./AdvancedAdministrationAWOD12ACi";
import { CoverageAngle } from "./CoverageAngle";
import { SpecificationsAWOD12ACi } from "./SpecificationsAWOD12ACi";

export function AWOD12ACi() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleAWOD12ACiSection />
           <SectionFunctionalitiesAWOD12ACi />
           <AdvancedAdministrationAWOD12ACi />
           <CoverageAngle />
           <SpecificationsAWOD12ACi />
        </Container>
    </div>
  );
}
