import { Route, Routes } from 'react-router-dom';
import { AboutUs, TradeMark, WarrantyRma, PrivacyPolicy, History } from '../components/Pages';

export const AboutUsRoutes = () => (
  <Routes>
    <Route path="/" element={<AboutUs />} />
    <Route path="/History" element={<History />} />
    <Route path="/TradeMark" element={<TradeMark />} />
    <Route path="/WarrantyRma" element={<WarrantyRma />} />
    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
  </Routes>
);
