import { Button, Col, Container, Row } from "react-bootstrap";
import { useRef } from "react";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./AboutUsSection.module.scss";

export function AboutUsSection() {
  const videoRef = useRef(null);
  return (
    <Container>
      <Row>
        <Col className="mb-5">
          <HomeSubtitle title="Nosotros" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <div className={styles.subTitle}>
            La Tecnología AirLive ® brinda Innovación, Soluciones, Confiabilidad
            y Tecnología.
          </div>
          <p className="mb-3">
            Con más de dos décadas de acumulada experiencia en el desarrollo,
            fabricación y mercadeo de productos de tecnología de redes y
            comunicaciones, desde sus inicios como Ovislink Corp., AirLive
            Technology Corporation Corp. continúa pisando firme con su presencia
            en el mercado tecnológico.
          </p>
          <p className="mb-4">
            El amplio portafolio de soluciones de marca AirLive abarca una serie
            de productos orientados a satisfacer las necesidades de nuestros
            clientes con productos de calidad, operatividad de alto rendimiento
            y excelentes costos de venta al cliente final.
          </p>
          <Button href="/AboutUs" className={styles.button} size="lg">
            Ver más
          </Button>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <div>
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              className={styles.video}
              controls
            >
              <source
                src="https://www.hiottech.com/assets/computex2023.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
