import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive2TX801.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import EasilyBuild from "../../../../../../../../assets/imagesproduct/SwitchL2/EasilyBuild.png";
import { SpecificationsLeftLive2TX801 } from "./SpecificationsLeftLive2TX801";
import { SpecificationsRightLive2TX801 } from "./SpecificationsRightLive2TX801/SpecificationsRightLive2TX801";

export function FeatureProductLive2TX801() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-2TX801" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive2TX801 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive2TX801 />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={EasilyBuild}
            alt="construye en forma sencilla la mejor red de alta velocidad"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
        <Col xs={12} className={styles.minTitle}>
          Todas las marcas comerciales, logotipos y nombres de marcas son
          propiedad de sus respectivos dueños.
        </Col>
      </Row>
    </Container>
  );
}
