import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH1602M200.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH1602M200 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1602M200/GSH1602M200.png";
import descriptionSwitchL2POEGSH1602M200 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1602M200/descriptionSwitchL2POEGSH1602M200.png";

export function TitleSectionL2POEGSH1602M200() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Administrable Gigabit PoE+ 200W 20 puertos PoE+ Gigabit incl 2 SFP y 2 RJ-45 Uplink" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH1602M-200" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH1602M200}
            alt="Switch Administrable Gigabit PoE+ 200W 20 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Vistas generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch administrable SNMP y PoE con política avanzada de VLAN para videoconferencia | Vigilancia | Voz | Datos" />
          <p>
            El AirLive POE-GSH1602M-200 es un switch Gigabit PoE+ de 20 puertos
            gestionado L2+ que incluye 16 puertos PoE y 2 puertos Gigabit SFP y
            2 puertos RJ45 Uplink con una alta potencia PoE de 200 W. Cada
            puerto PoE es compatible con 802.3at/af de hasta 30 W, adaptable
            automáticamente. Los datos y la fuente de alimentación se
            transferirán a través del cable UTP al mismo tiempo. En el caso de
            los dispositivos que no son PoE detectados, solo se transfieren los
            datos.
          </p>
          &nbsp;
          <p>
            Ofrece funciones integrales de administración SNMP que incluyen
            IGMP, división de VLAN, agregación de puertos, duplicación de
            puertos QoS y control de ancho de banda. Su exclusiva "función de
            política de división de VLAN" permite establecer "Videoconferencia,
            vigilancia, voz o datos" un grupo de VLAN de alta importancia con
            una prioridad más alta y así evitar la pérdida o los retrasos de los
            paquetes.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH1602M200}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
