import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { SupportPrincipal, TableGuide, TableSection } from "./components";

export function Drivers() {
  return (
    <>
      <Container fluid className="px-0">
        <SupportPrincipal />
        <Container>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey="Characteristics"
                id="fill-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="Characteristics"
                  title="Guías de configuración"
                >
                  <TableGuide />
                </Tab>
                <Tab eventKey="Specifications" title="Controladores AirLive">
                  <TableSection />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
