import { Container, Row, Col } from "react-bootstrap";
import styles from "../MarketingHeader/MarketingHeader.module.scss";
import img_header from "../../../../../../../../assets/images/air_live_building.png";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function MarketingHeader() {
  return (
    <Container fluid className={`${styles.banner} px-0`}>
      <Container className="py-4">
        <Row className="align-items-center">
          <Col xs={12} className="order-1 mb-3 mb-lg-0">
            <HomeSubtitle title="Lista de productos AirLive para Latinoamérica" />
          </Col>
          <Col xs={12} md={6} className="order-3 order-md-2">
            <p className={styles.text}>
              <strong>AirLive Technology Corporation</strong> es fabricante de
              productos tecnológicos para el sector de la tecnología de la
              Información y Telecomunicaciones, con soluciones para centros de
              cómputos, la oficina, la industria y el hogar. Las listas de
              productos contenidas en esta sección son con el objetivo de
              proveer a nuestros estimados usuarios y clientes una visión de
              productos disponibles y/o en proceso de lanzamiento al mercado.
            </p>
            <p className={styles.text}>
              Estas listas se presentan exclusivamente con carácter informativo,
              para complementar la información contenida en los sitios web
              corporativos, y de ninguna manera constituye una obligación o
              compromiso con terceros. Agradecemos contactar los ejecutivos de
              cuenta AirLive o a nuestros distribuidores y/o canales autorizados
              para recibir cotizaciones de productos con precios y tiempos de
              entrega.
            </p>
          </Col>
          <Col xs={12} md={6} className="order-2 order-md-3 mb-3 mb-md-0">
            <img
              src={img_header}
              className={styles.img_header}
              alt="support_principal"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
