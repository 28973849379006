import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionXGS2404.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SwitchL3XGS2404 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS2404/SwitchL3XGS2404.png";
import descriptionSwitchL32404 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGS2404/descriptionSwitchL32404.png";

export function TitleSectionXGS2404() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch Gigabit L3 Administrable con 10G uplink Incluye 24 Puertos Gigabit + 4 Puertos 10G SFP+" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3-XGS2404" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchL3XGS2404}
            alt="Switch L3 Administrado de 24 puertos Gigabit+ con 4 puertos 10G SFP"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Capa 3" />
          <p>
            La serie L3-XGS2404 ofrece enrutamiento IP de hardware de alto
            rendimiento. La ruta estática, OSPF y RIP proporcionan enrutamiento
            dinámico con otros switches y enrutadores de capa 3. Con la serie
            L3-XGS2404, los clientes pueden lograr fácilmente una ruta basada en
            políticas (PBR), lo cual es importante cuando se necesita un switch
            para optimizar la aplicación y un tiempo de recuperación de red
            corto.
          </p>
          &nbsp;
          <SubTitleProduct content="Qué es un Switch Capa 3?" />
          <p>
            Es un dispositivo especializado para enrutamiento de red, similar a
            un enrutador. Ambos pueden admitir los mismos protocolos de
            enrutamiento, inspeccionar los paquetes entrantes y tomar decisiones
            de enrutamiento dinámico basadas en las direcciones de origen y
            destino internas. Una de las principales ventajas de un switch de
            capa 3 sobre un router es la forma en que se realizan las decisiones
            de enrutamiento. Los switches capa 3 tienen latencia de red mucho
            más baja, ya que los paquetes no tienen que realizar pasos
            adicionales a través de un enrutador.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL32404}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
