import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function ChildrenPrivacySection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Privacidad de los niños" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Nuestro Servicio no se dirige a ninguna persona menor de 18 años
            ("Niños").
          </p>
          <p>
            No recopilamos a sabiendas información de identificación personal de
            ninguna persona menor de 18 años. Si es padre o tutor y sabe que sus
            hijos nos han proporcionado datos personales, comuníquese con
            nosotros. Si nos damos cuenta de que hemos recopilado Datos
            personales de niños sin verificación del consentimiento de los
            padres, tomamos medidas para eliminar esa información de nuestros
            servidores.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
