import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SwitchIGM642POE125G.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionSwitchIGM642POE125G } from "./TitleSectionSwitchIGM642POE125G";
import { CharacteristicsSwitchIGM642POE125G } from "./CharacteristicsSwitchIGM642POE125G";
import { FeatureProductSwitchIGM642POE125G } from "./FeatureProductSwitchIGM642POE125G";

export function SwitchIGM642POE125G() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionSwitchIGM642POE125G />
        <CharacteristicsSwitchIGM642POE125G />
        <FeatureProductSwitchIGM642POE125G />
      </Container>
    </div>
  );
}
