import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsAWOD30AXi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftAWOD30AXi } from "./SpecificationsLeftAWOD30AXi";
import { SpecificationsRightAWOD30AXi } from "./SpecificationsRightAWOD30AXi";

export function SpecificationsAWOD30AXi() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AWOD-12ACi" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftAWOD30AXi />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightAWOD30AXi />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
