import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsSwitchIGM642POE125G() {
    const Feature = [
        {
          detail: "Solución POE Inteligente L2+ Industrial Administrable avanzada para ambientes difíciles",
        },
        {
          detail: "4 puertos PoE @30W/cada uno, 802.3at, 10/100/1000Mbps",
        },
        {
          detail: "Puerto 2-SFP para conectividad de larga distancia de fibra, 100/1000/1250Mbps",
        },
        {
          detail: "Gestión L2+, compatible con la tecnología ERPS Ring, que proporciona recuperación ultrarrápida -0.05/s para redes críticas, anillo redundante",
        },
        {
          detail: "Admite la administración básica de IPv4 e IPv6 VLAN del enrutador de nivel 3",
        },
        {
          detail: "Funciones PoE únicas integradas para dispositivos PoE (PD) como PoE Watchdog / Status / Schedule Support",
        },
        {
          detail: "Detecte y reinicie automáticamente la alimentación para PD conectados",
        },
        {
          detail: "Entradas de alimentación duales 48V ~ 55 Vdc, redundancia de alimentación",
        },
        {
          detail: "Protección contra sobretensiones de iluminación: modo general 6KV, modo diferencial: 2KV, ESD: 15KV / el interruptor",
        },
        {
          detail: "Diseño sin ventilador de enfriamiento",
        },
        {
          detail: "-40 °C ~ 85 °C temperatura de funcionamiento",
        },
        {
          detail: "Carcasa metálica resistente IP40",
        },
        {
          detail: "Carril DIN y montaje en pared",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
