import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalRightL32TX2406() {
  const IPv6 = [
    {
      title: "IPv6:",
      detail: "Soporte IPv6 Ping、IPv6 Traceroute、IPv6 Telnet",
    },
    {
      detail: "Soporte IPv6 SSH",
    },
    {
      detail: "Soporte IPv6 HTTP 、IPv6 HTTPS",
    },
  ];
  const ManagementMaintenance = [
    {
      title: "Gestión y Mantenimiento:",
      detail: "Soporte de gestión WEB (HTTP, HTTPS)",
    },
    {
      detail: "Soporte CLI (Telnet, SSH V1 / V2, puerto serie local)",
    },
    {
      detail: "Soporte SNMP V1/V2/V3, MIB",
    },
    {
      detail: "Soporte RMON V2",
    },
    {
      detail: "Soporte de detección de dispositivos LLDP",
    },
    {
      detail: "Soporte SNTP Sincronización de tiempo",
    },
    {
      detail: "Soporte de monitoreo de CPU, monitoreo de memoria",
    },
    {
      detail: "Registro del sistema de soporte, advertencia de clasificación",
    },
    {
      detail: "Soporte de ping, detección de Traceroute, detección de cables",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C a +50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C a +85 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Tamaño del producto: 44x36x4.45 cm",
    },
    {
      detail: "Dimensiones del paquete: 58x44x12 cm",
    },
    {
      detail: "Peso del paquete: N.W: 5.49KG/ G.W: 6.85KG",
    },
    {
      detail:
        "Contenido del paquete: Switch x 1, QIG x 1, Cable de alimentación x 1, Cable serie x 1, oreja de rack x 1",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Dimensiones del cartón: 60x46x27.8 cm ",
    },
    {
      detail: "Cantidad del embalaje: 2 PCS",
    },
    {
      detail: "Peso del embalaje: 14.7 KGS",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "L3-2TX2406",
    },
    {
      detail:
        "Nombre:  Switch de fibra Multi Gigabit gestionado L3 de 24 puertos, incluidos 6 puertos de fibra 10G SFP+.",
    },
  ];
  return (
    <Container>
      <ul>
        {IPv6.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {ManagementMaintenance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
