import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationAirDualAC12.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AdvancedModeOperation from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AdvancedModeOperation.png";
import APrepetidor from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/APrepetidor.png";

export function AdvancedAdministrationAirDualAC12() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Encapsulamiento ABS resistente a la intemperie" />
          <p>
            El AirDual-AC12 posee resistencia a la intemperie ABS, tanto al agua
            como al polvo y carcasa de protección solar, evita el daño por
            partículas nocivas y clima lluvioso, de tal manera que se adapta a
            varios entornos difíciles, soportando temperaturas de trabajo de -40
            °C a 55 °C. Adecuado para uso en todos los países.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className="w-40"
            src={APrepetidor}
            alt="AP / repetidor"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="AP / repetidor avanzado PTP, soporta modo PTMP o gestión en la nube" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo AP y repetidor:" />
          <p>
            En este modo, el AirLive AirDual AC12 funciona como un AP o
            repetidor, lo que permite al instalador configurar la aplicación
            inalámbrica punto a punto o punto a múltiples puntos. Construya una
            conexión de red a una distancia más larga entre dos edificios o
            cubra un área abierta más grande con varias unidades en función
            multipunto.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Control en la nube:" />
          <p>
            El AirLive AirDual AC12 se puede controlar a través de la nube
            (estando en modo AP), una vez que el AP se ha agregado a la nube. Se
            puede controlar desde cualquier lugar. Esto ofrece beneficios
            adicionales cuando se trata de una gran red de puntos de acceso, ya
            que no se necesita acceso directo y el soporte se puede dar desde
            una oficina remota. Además, se pueden otorgar múltiples niveles de
            control en la nube desde el administrador hasta solo los derechos de
            espectador. Los AP se pueden agregar y eliminar en cualquier
            momento.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AdvancedModeOperation}
            alt="Advanced Mode of Operation"
          />
        </Col>
      </Row>
    </Container>
  );
}
