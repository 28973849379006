import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL32TX2406.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SwitchL32TX2406 from "../../../../../../../../assets/imagesproduct/SwitchL3/L32TX2406/SwitchL32TX2406.png";
import descriptionSwitchL32TX2406 from "../../../../../../../../assets/imagesproduct/SwitchL3/L32TX2406/descriptionSwitchL32TX2406.png";

export function TitleSectionL32TX2406() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch Multi Giga L3 Adminsitrable con uplink 10G, 30 Puertos Multi Gigabit + 6 Puerto 10G SFP+" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3/2TX2406" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchL32TX2406}
            alt="Switch Multi Giga L3 Adminsitrable con uplink 10G"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Funciones capa 3 de Super alta velocidad" />
          <p>
            La serie L3-2TX2406 ofrece enrutamiento IP de hardware de 24 puertos
            2.5G RJ-45 y 6 puertos SFP+ 10G de alto rendimiento. La ruta
            estática, OSPF y RIP proporcionan enrutamiento dinámico mediante el
            intercambio de información de enrutamiento con otros conmutadores y
            enrutadores de capa 3. Con la serie L3-2TX2406, los clientes podrían
            lograr fácilmente una ruta basada en políticas (PBR), que es
            importante cuando necesitan cambiar de aplicación y un tiempo de
            recuperación de red corto.
          </p>
          &nbsp;
          <SubTitleProduct content="Ventajas de un enrutador capa 3" />
          <p>
            Un switch capa 3 es un dispositivo de hardware especializado
            utilizado en el enrutamiento de red. Los switches de capa 3
            técnicamente tienen mucho en común con los routers típicos, y no
            solo en apariencia física. Ambos pueden admitir los mismos
            protocolos de enrutamiento, inspeccionar los paquetes entrantes y
            tomar decisiones de enrutamiento dinámico basadas en las direcciones
            de origen y destino que contienen. Una de las principales ventajas
            de un switch de capa 3 sobre un router es la forma en que se
            realizan las decisiones de enrutamiento. Los switches de capa 3
            tienen una latencia de red mucho más baja, ya que los paquetes no
            tienen que realizar pasos adicionales a través de un enrutador.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL32TX2406}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
