import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH1622200.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH1622200 } from "./SpecificationsLeftPOEGSH1622200";
import { SpecificationsRightPOEGSH1622200 } from "./SpecificationsRightPOEGSH1622200/SpecificationsRightPOEGSH1622200";

export function FeatureProductPOEGSH1622200() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH1622-200" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH1622200 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH1622200 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
