import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionXGF28.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SwitchL3XGF28 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGF28/SwitchL3XGF28.png";
import descriptionSwitchL3XGF28 from "../../../../../../../../assets/imagesproduct/SwitchL3/SwitchL3XGF28/descriptionSwitchL3XGF28.png";

export function TitleSectionXGF28() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch L3 Administrado de 28 puertos Gigabit de fibra 24 Puertos Gigabit SFP, 4 puertos 10G SFP, 8* GE RJ45" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3-XGF28" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SwitchL3XGF28}
            alt="Switch L3 Administrado de 28 puertos Gigabit de fibra 24 Puertos Gigabit SFP, 4 puertos 10G SFP, 8* GE RJ45"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Funcionalidad capa 3" />
          <p>
            El L3-XGF28 es un switch de alto rendimiento con funcionalidades de
            enrutamiento, ruta estática IP, rutas dinámicas OSPF y RIP,
            proporcionando enrutamiento dinámico mediante el intercambio de
            información de paquetes con otros switches y enrutadores de capa 3.
            Con esta funcionalidad los clientes podrían lograr fácilmente una
            ruta basada en políticas (PBR), importante cuando necesitan un
            switch de fibra para aplicaciones exigentes en términos de
            velocidad, rendimiento y tiempo de recuperación de red.
          </p>
          &nbsp;
          <p>
            El switch capa 3 L3-XGF28 posee un robusto hardware y multi
            diversidad de conectividad mediante 24 puertos Gigabit SFP, ocho
            puertos 1000BaseT y cuatro puertos uplink de 10Gigabit. Los puertos
            SFP soportan transceptores de fibra SX/LX de 1 Gigabit para fibra
            multimodo o monomodo.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL3XGF28}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
