import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live2TX801.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live2TX801 } from "./TitleSectionL2Live2TX801";
import { CharacteristicsLive2TX801 } from "./CharacteristicsLive2TX801";
import { FeatureProductLive2TX801 } from "./FeatureProductLive2TX801";

export function Live2TX801() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live2TX801 />
           <CharacteristicsLive2TX801 />
           <FeatureProductLive2TX801 />
        </Container>
    </div>
  );
}
