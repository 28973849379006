import { Col, Container, Row } from "react-bootstrap";
import styles from "./Specifications.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftXGS2404 } from "./SpecificationsLeftXGS2404";
import { SpecificationsRightXGS2404 } from "./SpecificationsRightXGS2404";

export function Specifications() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3-XGS2404" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftXGS2404 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightXGS2404 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
