import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH2404M400.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH2404M400 } from "./TitleSectionL2POEGSH2404M400";
import { CharacteristicsPOEGSH2404M400 } from "./CharacteristicsPOEGSH2404M400";
import { TechnologyPoEManagement } from "../TechnologyPoEManagement";
import { VLANPolicy } from "../VLANPolicy";
import { RMONStatisticsSupport } from "../RMONStatisticsSupport";
import { FeatureProductPOEGSH2404M400 } from "./FeatureProductPOEGSH2404M400";
import Watchdog from "../../../../../../../assets/imagesproduct/SwitchL2/Watchdog.png";
import SurveillanceVLANsupport from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceVLANsupport.png";
import Longdistancefiber from "../../../../../../../assets/imagesproduct/SwitchL2/Longdistancefiber.png";

export function POEGSH2404M400() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH2404M400 />
           <CharacteristicsPOEGSH2404M400 />
           <TechnologyPoEManagement content={"POE-GSH2404M-400"} image={Watchdog} image2={SurveillanceVLANsupport} />
           <VLANPolicy />
           <RMONStatisticsSupport image={Longdistancefiber} />
           <FeatureProductPOEGSH2404M400 />
        </Container>
    </div>
  );
}
