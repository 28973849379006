import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightPOEGSH822120() {
  const DIPSwitchControl = [
    {
      title: "Control del interruptor DIP:",
      detail:
        "Predeterminado: modo normal, todos los puertos pueden comunicarse entre sí, la distancia de transmisión está dentro de los 100 m, la velocidad de transmisión es adaptativa de 10 M / 100 / 1000 M",
    },
    {
      detail:
        "Extender: el modo de extensión, la fuente de alimentación PoE y la distancia de transmisión de datos se pueden extender a 250 metros a una velocidad de transmisión de 10 Mbps Puerto 7 ~ 8 solamente. PoE Watchdog también se habilitará cuando el modo Extender esté en uso. El modo AI de puerto está habilitado.",
    },
  ];
  const PowerSupply = [
    {
      title: "Fuente de alimentación: ",
      detail: "Voltaje de entrada: CA 100-240 V 50-60Hz",
    },
    {
      detail: "Fuente de alimentación interna: 52 V / 2,3 A",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 22 x 161 x 4,45 cm (L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 31,5 x 21,5 x 8,5 cm  (L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: NO: 0.92kg; G.W: 1.22kg",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x cable de alimentación.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 10 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensione: 44,5 x 44,5 x 33,5 cm",
    },
    {
      detail: "Peso de la caja de  cartón: 13.2Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo:",
    },
    {
      detail: "POE-GSH822-120: 8 x PoE 10/100/1000, 2 x 1000T, 2 x 1G SFP ranura, 120W",
    },
  ];
  return (
    <Container>
      <ul>
        {DIPSwitchControl.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
