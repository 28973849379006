import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightISFPLXGMSMFBIDI() {
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -40 °C a +85 °C",
    },
    {
      detail: "Temperature de almacenamiento: -40 °C a +85°C",
    },
    {
      detail: "Humedad de trabajo: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Tamaño del Producto:",
      detail: "55,5 x 13,4 x 8,5 mm (L*W*H)",
    },
    {
      detail: "Tamaño del Paquete: TBD mm(L*W*H)",
    },
    {
      detail: "Peso del Paquete: N.W: TBD kgs; G.W:TBD KGS",
    },
    {
      detail: "Contenido del paquete: Un modulo 1 x SFP A o B",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 10 piezas / 1 ampolla",
    },
    {
      detail: "Dimensiones: TBD mm(L*W*H)",
    },
    {
      detail: "Peso: TBD kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo: ISFP-LX-1.25G-20KM-SMF A - ISFP-LX-1.25G-20KM-SMF B",
    },
    {
      detail: "Nombre: A: Fibra de nivel industrial 1.25G SFP BIDI, 1310/1550nm SMF, 20KM, LC, DDM, Industrial - B: Fibra de nivel industrial 1.25G SFP BIDI, 1550/1310nm SMF, 20KM, LC, DDM, Industrial",
    },
  ];
  return (
    <Container>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
