import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsPOEGSH1622200() {
    const Feature = [
        {
          detail: "Todos los 20 puertos ofrecen alta velocidad Gigabit ",
        },
        {
          detail: "16 puertos PoE de hasta 30 W, 4 puertos de enlace ascendente ",
        },
        {
          detail: "Prioridad de PoE",
        },
        {
          detail: "Extienda la red con 2 ranuras SFP que aceptan transceptores de fibra con mayor alcance  que Ethernet",
        },
        {
          detail: "Alimentación PoE, compatible con 802.3af/at PoE+",
        },
        {
          detail: "Potencia interna 200W",
        },
        {
          detail: "Modo extendido: transmisión de hasta 250 M en la configuración del modo extendido mediante un interruptor DIP de hardware",
        },
        {
          detail: "Protección contra sobretensiones; Protección ESD",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={7} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
