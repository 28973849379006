import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalLeftIGM642POE125G() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "4 x 90W PoE 10/100/1000 RJ45" 
        },
        {
            detail: "2 x puertos Gigabit SFP" 
        },
        {
            detail: "6 puertos totalmente" 
        },
        {
            detail: "1 x puerto de consola" 
        },
    ];
    const DeviceParameters = [
        {
            title:"Device parameters:",
            detail: "Ancho de banda: 12Gbps" 
        },
        {
            detail: "Velocidad de reenvío de paquetes: 8.928Mpps" 
        },
        {
            detail: "RAM: 128MB" 
        },
        {
            detail: "Flash: 16MB" 
        },
        {
            detail: "Memoria de búfer de paquetes: 4M" 
        },
        {
            detail: "Fotograma gigante: 9.6Kbytes" 
        },
        {
            detail: "VLAN:4096" 
        },
        {
            detail: "MAC: 8K" 
        },
        {
            detail: "Modo de reenvío: almacenar y reenviar" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "Control de flujo IEEE802.3x y contrapresión" 
        },
        {
            detail: "IEEE802.3at/af PoE" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), 1-4 Link (verde), PoE (amarillo)" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "Modo general 6KV, modo diferencial 2KV, ESD: 15KV, toque 8KV" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Encapsulamiento de metal sólido, sin ventilador" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Entrada de energía: Voltaje de entrada: DC48 ~ 55V" 
        },
        {
            detail: "Tipo de entrada: Terminal Industrial de 4 PIN (bloque V1+V1- V2+ V2-)" 
        },
        {
            detail: "Potencia de salida de un solo puerto PoE: Máx.: 15,4 W" 
        },
        {
            detail: "(IEEE 802.3af) Máx.: 30 W (IEEE 802.3at)" 
        },
        {
            detail: "Compatibilidad PoE: IEEE 802.3af / at adaptativo" 
        },
        {
            detail: "Potencia de salida POE: DC 48-55V" 
        },
        {
            detail: "PIN de alimentación POE: 1/2-;3/6+" 
        },
    ];
    const fiberMedia = [
        {
            title:"Medios de fibra:",
            detail: "Multimodo: 850nm, 1310nm" 
        },
        {
            detail: "Distancia de transmisión: 550m / 2km" 
        },
        {
            detail: "Modo único: 1310nm, 1550nm" 
        },
        {
            detail: "Distancia de transmisión 20/40/60/80/100/120Km" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DeviceParameters.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {fiberMedia.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
