import styles from "./HomeSubtitle.module.scss";

export interface HomeSubtitleProps {
  title: string;
}

export function HomeSubtitle({ title }: HomeSubtitleProps) {
  return (
    <>
      <span className={styles.text}>{title}</span>
      <div className={styles.bar} />
    </>
  );
}
