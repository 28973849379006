import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsGPONOLT121.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftGPONOLT121 } from "./SpecificationsLeftGPONOLT121";
import { SpecificationsRightGPONOLT121 } from "./SpecificationsRightGPONOLT121";

export function SpecificationsGPONOLT121() {
  return (
    <Container>
      <Row xs={12}>
        <Col className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col className="mb-3">
          <HomeSubtitle title="1 PON Ports GPON OLT  with 10G SFP+" />
        </Col>
      </Row>
      <Row xs={12}>
        <Col className={styles.LeftList}>
            <SpecificationsLeftGPONOLT121 />
        </Col>
        <Col className={styles.RightList}>
            <SpecificationsRightGPONOLT121 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
