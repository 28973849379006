import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function ContactUsSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Contáctenos" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Si tiene alguna pregunta sobre esta Política de privacidad,
            contáctenos: Por correo electrónico: <a href="mailto:info@airlive.com">info@airlive.com</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
