import { Container, Row, Col } from "react-bootstrap";
import styles from "./CATVServiceImplementation.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GPONOLT1644 from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT1644.png";
import XGSPONOLT8XG from "../../../../../../../../assets/imagesproduct/AirLiveGPON/XGSPONOLT8XG.png";
import CATVRFFiber from "../../../../../../../../assets/imagesproduct/AirLiveGPON/CATVRFFiber.png";
import AmplificadorXPONEDFA4 from "../../../../../../../../assets/imagesproduct/AirLiveGPON/AmplificadorXPONEDFA4.png";

export function CATVServiceImplementation() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="GPON OLT-1644, 16 PON ports, 4*SFP+ 10G, 4*RJ45 1G" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Esta OLT permite la máxima capacidad de 16 puertos en una unidad de
            rack, para un total de 2048 ONUs de usuario final. Soporta los
            estándares ITU-T G.984/G.988 y posee un backplane de 208 Gbps.
            fuentes de Aporta funcionalidades de capa 3 y alimentación AC or DC
            dual.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img className={styles.image} src={GPONOLT1644} alt="GPON OLT-1644" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="XGSPON OLT-8XG 8 puertos XG-PON/XGS-PON , 2 x QSFP28 100G , 4 x SFP28 25G, 2 x RJ45 1G, Dual Power" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Esta OLT excede ampliamente la robustés y capacidad de las OLTs
            anteriores, soportando los estándares ITU-T G.9807(XGS-PON) and
            ITU-T G.987(XG-PON), con velocidades de 10Gbps descendente y 2.5
            Gbps ascendente. Soporta dos puertos de 100 Giga SFP28 y cuatro de
            25Giga SFP28, un total de 2048 ONUs hasta 20 km, funcionalidades de
            enrutamiento L3 y doble Fuente de alimentación AC o DC. Todo en una
            sola unidad de rack.
          </p>
        </Col>
        <Col xs={12} md={8} className="mb-5">
          <img
            className={styles.image}
            src={XGSPONOLT8XG}
            alt="XGSPON OLT-8XG"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Implementación de servicio CATV" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Como se mostró anteriormente, las unidades OLTs permiten incorporar
            tráfico IP proveniente de servicios de Voz, internet y IPTV. Dichos
            servicio son extendidos hasta el usuario final vía red de fibra
            óptica donde son extraídos mediante las unidades ONU que soportan
            los estándares y funcionalidades adecuadas.
          </p>
          &nbsp;
          <p>
            Sin embargo, el servicio de CATV requiere el uso de un transmisor de
            señal RF para modularla y transmitirla vía red de fibra. Así mismo,
            suele ser necesario la utilización de un amplificador óptico para
            extender el servicio modulado hasta el usuario final, donde reside
            una unidad GPON ONU CATV.
          </p>
          &nbsp;
          <p>
            Para tal propósito AirLive pone a disposición de nuestros aliados
            y/o proveedores de servicio el Convertidor-transmisor de RF a fibra,
            así como el amplificador EDFA-4, los cuales permiten la integración
            de servicios en una sola plataforma de red.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={CATVRFFiber}
            alt="CATV RF-Fiber converter- TX 1550nm"
          />
          <SubTitleProduct content="CATV RF-Fiber converter- TX 1550nm" />
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={AmplificadorXPONEDFA4}
            alt="Amplificador XPON EDFA-4"
          />
          <SubTitleProduct content="Amplificador XPON EDFA-4" />
        </Col>
      </Row>
    </Container>
  );
}
