import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SwitchIGM642BT125G.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionSwitchIGM642BT125G } from "./TitleSectionSwitchIGM642BT125G";
import { CharacteristicsSwitchIGM642BT125G } from "./CharacteristicsSwitchIGM642BT125G";
import { EfficientTechnology } from "./EfficientTechnology";
import { ManageAdvancedFeatures } from "./ManageAdvancedFeatures";
import { WideTemperatureVoltageInputs } from "./WideTemperatureVoltageInputs";
import { FeatureProductSwitchIGM642BT125G } from "./FeatureProductSwitchIGM642BT125G";

export function SwitchIGM642BT125G() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionSwitchIGM642BT125G />
        <CharacteristicsSwitchIGM642BT125G />
        <EfficientTechnology />
        <ManageAdvancedFeatures />
        <WideTemperatureVoltageInputs />
        <FeatureProductSwitchIGM642BT125G />
      </Container>
    </div>
  );
}
