import { Container } from "react-bootstrap";
import { SidebarAboutUs } from "../../SidebarAboutUs";
import styles from "./PrivacyPolicy.module.scss";
import {
  PrivacyPolicySection,
  DataCollectedSection,
  DataUsageSection,
  DataTransferSection,
  DataDisclosureSection,
  LinkstoOtherSitesSection,
  ChildrenPrivacySection,
  ChangesPrivacyPolicySection,
  ContactUsSection
} from "./components";

export function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <PrivacyPolicySection />
        <DataCollectedSection />
        <DataUsageSection />
        <DataTransferSection />
        <DataDisclosureSection />
        <LinkstoOtherSitesSection />
        <ChildrenPrivacySection />
        <ChangesPrivacyPolicySection />
        <ContactUsSection />
      </Container>
    </div>
  );
}
