import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live2TX501.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L2TX501 from "../../../../../../../../assets/imagesproduct/SwitchL2/Live2TX501/L2TX501.png";
import descriptionSwitchL2Live2TX501 from "../../../../../../../../assets/imagesproduct/SwitchL2/Live2TX501/descriptionSwitchL2Live2TX501.png";

export function TitleSectionL2Live2TX501() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Multi Gigabit 2.5Gbps Base-T, plug & play Incluye 5 puertos RJ45, 1xSFP 10G No administrable" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-2TX501" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L2TX501}
            alt="Switch Multi Gigabit 2.5Gbps Base-T, plug & play Incluye 5 puertos RJ45"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Actualización de su conexión de red a 2.5Gbps Super alta velocidad" />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <p>
            El switch AirLive Multi-Giga, Live-2TX501, admite 5 puertos RJ45 y 1
            puerto SFP+ para ofrecer una conexión de alta velocidad adicional de
            hasta 2,5 Gbps en RJ-45 y 10G SFP+ en fibra óptica. Con el switch
            multigiga 2.5G, puede conectarse a una variedad de dispositivos
            habilitados para 2.5GbE, como NAS | NVR | PC | Servidor | Portátil,
            para manejar aplicaciones que consumen mucho ancho de banda.
            Diseñado con una carcasa sólida de tamaño delgado.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live2TX501}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}
