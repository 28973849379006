import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./TransceiverSFP10GT30m.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionTransceiverSFP10GT30m } from "./TitleSectionTransceiverSFP10GT30m";
import { FeatureProductTransceiverSFP10GT30m } from "./FeatureProductTransceiverSFP10GT30m";

export function TransceiverSFP10GT30m() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionTransceiverSFP10GT30m />
        <FeatureProductTransceiverSFP10GT30m />
      </Container>
    </div>
  );
}
