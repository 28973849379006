import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftXGS4804() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware Interfaz del dispositivo:",
      detail: "48 puertos PoE 10/100/1000M 30W (Puerto 1~8 PoE 90W)",
    },
    {
      detail: "4 puertos SFP+ 10G",
    },
    {
      detail: "1 x puerto de consola RJ45 ",
    },
    {
      detail: "1 x puerto USB",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.3:Protocolo MAC Ethernet ",
    },
    {
      detail: "IEEE 802.3i:10BASE-T Ethernet ",
    },
    {
      detail: "IEEE 802.3u:100BASE-TX Fast Ethernet ",
    },
    {
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3z:1000BASE-X Gigabit Ethernet (fibra óptica) ",
    },
    {
      detail: "IEEE 802.3ae: 10G Ethernet (fibra óptica)",
    },
    {
      detail: "IEEE 802.3az:Ethernet de eficiencia energética",
    },
    {
      detail:
        "IEEE 802.3ad:Método estándar para realizar la agregación de vínculos ",
    },
    {
      detail: "IEEE 802.3x:Control de flujo",
    },
    {
      detail: "IEEE 802.1ab: LLDP/LLDP-MED (Link Layer Discovery Protocol)",
    },
    {
      detail:
        "IEEE 802.1p:Priorización de tráfico del protocolo QoS/CoS de capa LAN (función de filtrado de multidifusión)",
    },
    {
      detail: "IEEE 802.1q:Operación del puente VLAN",
    },
    {
      detail:
        "IEEE 802.1x:Protocolo de autenticación y control de acceso cliente/servidor ",
    },
    {
      detail: "IEEE 802.1d:STP",
    },
    {
      detail: "IEEE 802.1s:MSTP",
    },
    {
      detail: "IEEE 802.3ah:OAM",
    },
    {
      detail: "IEEE 802.1w:RSTP",
    },
    {
      detail: "IEEE 802.3af(15.4W)",
    },
    {
      detail: "IEEE 802.3at (30W)",
    },
    {
      detail: "IEEE 802.3bt (90W)",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail:
        "PWR (indicador de encendido), SYS (luces del sistema), enlace (luz de enlace).",
    },
    {
      detail: "PoE(luz),Act(luz de datos).",
    },
  ];
  const SurgeProtection = [
    {
      title: "Protección contra sobretensiones de iluminación:",
      detail: "Modo común≥6KV, ESD: Contacto≥6KV.  Aire≥8KV.",
    },
  ];
  const Mechanic = [
    {
      title: "Mecánico:",
      detail: "Metal sólido 19 1U montable en rack, IP30",
    },
  ];
  const PowerPoEProtocol = [
    {
      title: "Alimentación y PoE Protocolo:",
      detail: "IEEE802.3bt (90W, IEEE802.3at (30W), IEEE802.3af (15.4W)",
    },
  ];
  const PoEPowerOutput8 = [
    {
      title: "Salida de potencia PoE / Puerto PoE 1 ~ 8:",
      detail: "máx. 90 vatios",
    },
  ];
  const PoEPowerOutput48 = [
    {
      title: "Salida de potencia PoE / Puerto PoE 9 ~ 48:",
      detail: "máx. 30 vatios",
    },
  ];
  const PoEPowerOutputSwitch = [
    {
      title: "Potencia de salida PoE / PoE del Switch:",
      detail: "máx. 600 vatios",
    },
  ];
  const PowerInput = [
    {
      title: "Entrada de energía:",
      detail: "AC100 ~ 240VAC",
    },
  ];
  const PoESoftware = [
    {
      title: "Función del software PoE:",
      detail: "Soporte de gestión de tiempo PoE",
    },
    {
      detail: "Soporte de monitoreo automático PoE y vigilancia",
    },
    {
      detail:
        "Soporte de fuente de alimentación de puerto PoE y configuración de energía",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerPoEProtocol.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <SubTitleProduct content="Puerto PoE: 48" />
      <ul>
        {PoEPowerOutput8.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoEPowerOutput48.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoEPowerOutputSwitch.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PoESoftware.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
