import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalPOEGSH2404M400.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftPOEGSH2404M400 } from "./TechnicalLeftPOEGSH2404M400";
import { TechnicalRightPOEGSH2404M400 } from "./TechnicalRightPOEGSH2404M400/TechnicalRightPOEGSH2404M400";

export function TechnicalspecificationsPOEGSH2404M400() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH2404M-400" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalLeftPOEGSH2404M400 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalRightPOEGSH2404M400 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}
