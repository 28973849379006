import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightLive16GTMini() {
  const DIPSwitchControl = [
    {
      title: "Control del interruptor DIP:",
      detail:
        "VLAN: Modo de aislamiento de puertos. En este modo, los puertos (1 ~ 14) no pueden comunicarse entre sí y solo pueden comunicarse con el puerto UP-Link 15/16. Predeterminado: modo normal, todos los puertos pueden comunicarse entre sí, la distancia de transmisión está dentro de los 100 m, la velocidad de transmisión es adaptable de 10 M / 100 / 1000 M, soporte de bucle.",
    },
    {
      detail:
        "Extender: modo de extensión, la distancia de transmisión de datos se puede extender a 250 metros a una velocidad de transmisión de 10 Mbps Puerto 1 ~ 14 solamente. 1-14 puertos admiten negociación de reducción de velocidad de 100M, 250 metros en modo 10Mbps/s, puerto 15 y puerto 16 como puerto de enlace ascendente a 1000Mbps. Nota: El modo VLAN y el modo Extender no se pueden utilizar al mismo tiempo.",
    },
  ];
  const PowerSupply = [
    {
      title: "Fuente de alimentación:",
      detail: "Fuente de alimentación: CA 100 ~ 240V",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 90%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 22.0 x 16.1 x 4.4 cm(L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 31,5 x 21,5 x 8,5 cm(L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: NO: 0,90 kg; G.W: 1.42kg",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x Adaptador de corriente, 1 x Soportes (par)",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 10 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 44,5 x 45,5 x 33,5 cm(L*W*H)",
    },
    {
      detail: "Peso de la caja: 15.2Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo: Live-16GT Mini: 16 puertos RJ-45 de 10/100/1000 Mbps",
    },
    {
      detail: "Nombre: Switch Gigabit de 16 puertos, VLAN, QoS, Plug and Play",
    },
  ];
  return (
    <Container>
      <ul>
        {DIPSwitchControl.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
